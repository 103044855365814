import i18n from '@mirage/translations';
import { sub } from 'date-fns';

export const enum LastUpdatedFilterKeys {
  Any_Date = 'any-date',
  Last_24_Hours = 'last-24-hours',
  Last_7_Days = 'last-7-days',
  Last_30_Days = 'last-30-days',
  Last_90_Days = 'last-90-days',
  Last_12_Months = 'last-12-months',
  Custom = 'custom',
}

export type LastUpdatedObject = {
  readonly key: LastUpdatedFilterKeys;
  readonly title: string;
  readonly start?: Date;
  readonly end?: Date;
};

export type LastUpdatedObjects = Readonly<LastUpdatedObject[]>;

export const getLastUpdatedObjects = () => {
  const now = new Date();

  return [
    {
      title: i18n.t('last_updated_filter_any_date'),
      key: LastUpdatedFilterKeys.Any_Date,
      start: undefined,
      end: undefined,
    },
    {
      title: i18n.t('last_updated_filter_last_24_hours'),
      key: LastUpdatedFilterKeys.Last_24_Hours,
      start: sub(now, { hours: 24 }),
      end: now,
    },
    {
      title: i18n.t('last_updated_filter_last_7_days'),
      key: LastUpdatedFilterKeys.Last_7_Days,
      start: sub(now, { days: 7 }),
      end: now,
    },
    {
      title: i18n.t('last_updated_filter_last_30_days'),
      key: LastUpdatedFilterKeys.Last_30_Days,
      start: sub(now, { days: 30 }),
      end: now,
    },
    {
      title: i18n.t('last_updated_filter_last_90_days'),
      key: LastUpdatedFilterKeys.Last_90_Days,
      start: sub(now, { days: 90 }),
      end: now,
    },
    {
      title: i18n.t('last_updated_filter_last_12_months'),
      key: LastUpdatedFilterKeys.Last_12_Months,
      start: sub(now, { months: 12 }),
      end: now,
    },
    {
      title: i18n.t('last_updated_filter_custom'),
      key: LastUpdatedFilterKeys.Custom,
    },
  ] as const;
};
