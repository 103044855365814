import { Link, ModuleCard } from './ModuleCard';

export type { ModuleCardProps as DashCardProps } from './ModuleCard';
export * from './utils';

/**
 * Generic wrapper for Dash Card component.
 *
 * If you need the card to be a link, you need to define `isLink` on the card.
 * Wrap the main card content to be linked within `DashCard.OverlayLink`, and supply
 * the `href` and `onClick` props.
 */
export const DashCard = Object.assign(ModuleCard, {
  OverlayLink: Link,
});
