import { useCallback } from "react";

import * as discovery from "@mirage/discovery";
import { browserExtensionPortAdapter } from "@mirage/discovery/adapters";
import { ServiceId } from "@mirage/discovery/id";
import { tagged } from "@mirage/service-logging";
import {
  ExtensionConnectedState,
  extensionConnectedStateAtom,
} from "@mirage/shared/atoms/extensionConnection";
import {
  CHROME_ALPHA_EXTENSION_INFO,
  CHROME_BETA_EXTENSION_INFO,
  CHROME_DEVPROD_EXTENSION_INFO,
  CHROME_PILOT_EXTENSION_INFO,
  EDGE_ALPHA_OR_DEVPROD_EXTENSION_INFO,
  EDGE_BETA_EXTENSION_INFO,
  EDGE_PILOT_EXTENSION_INFO,
} from "@mirage/shared/util/build-vars";
import { MIRAGE_PORT_NAME } from "@mirage/shared/util/constants";
import { sleepMs, USER_AGENT_INFO } from "@mirage/shared/util/tiny-utils";
import { expectLoginSyncV2 } from "@mirage/webapp/helpers/sentry";
import { useSetAtom } from "jotai";

const logger = tagged("extensionConnector");

interface ExtensionInfo {
  id: string;
  name: string;
  browser: string;
}
// Will attempt to connect to extensions in given order
const chromeExtensionIds: ExtensionInfo[] = [
  CHROME_BETA_EXTENSION_INFO,
  CHROME_PILOT_EXTENSION_INFO,
  CHROME_ALPHA_EXTENSION_INFO,
  CHROME_DEVPROD_EXTENSION_INFO,
];

const edgeExtensionIds: ExtensionInfo[] = [
  EDGE_BETA_EXTENSION_INFO,
  EDGE_PILOT_EXTENSION_INFO,
  EDGE_ALPHA_OR_DEVPROD_EXTENSION_INFO,
];

export const useConnectToExtension = () => {
  const setExtensionConnection = useSetAtom(extensionConnectedStateAtom);
  return useCallback(
    () => connectToExtension(setExtensionConnection),
    [setExtensionConnection],
  );
};

const connectToExtension = async (
  setExtensionConnection: (state: ExtensionConnectedState) => void,
) => {
  const browserName = USER_AGENT_INFO.browser.name?.toLocaleLowerCase();

  let port: chrome.runtime.Port | undefined;
  let connectedExtension: ExtensionInfo | undefined;

  switch (browserName) {
    case "chrome":
    case "edge": {
      const extensionIds =
        browserName === "chrome" ? chromeExtensionIds : edgeExtensionIds;
      for (const extension of extensionIds) {
        const { success, port: connectedPort } =
          await tryEstablishChromiumConnection(extension);
        if (success) {
          port = connectedPort;
          connectedExtension = extension;
          break;
        }
      }
      break;
    }
    default:
      logger.warn("Browser not supported when connecting to extension");
      return;
  }

  if (!port) {
    logger.debug("Could not connect to any extension");
    return;
  }

  discovery.listen(browserExtensionPortAdapter(port), [
    ServiceId.BROWSER_HISTORY_V1,
    ServiceId.BROWSER_HISTORY_V2,
    ...(expectLoginSyncV2() ? [ServiceId.LOGIN_SYNC_V2] : []),
  ]);

  port.onDisconnect.addListener(() => {
    // TODO: @mattanderson_dbx we need an `discovery.unlisten` call here
    setExtensionConnection({
      connected: false,
      extensionId: "",
      browser: "",
      name: "",
    });
    logger.info(
      `Disconnected from discovery on ${connectedExtension?.browser} extension ${connectedExtension?.name}. Attempting to reconnect...`,
    );

    connectToExtension(setExtensionConnection);
  });

  setExtensionConnection({
    connected: true,
    extensionId: connectedExtension?.id || "",
    browser: connectedExtension?.browser || "",
    name: connectedExtension?.name || "",
  });
  logger.info(
    `Connected to discovery on ${connectedExtension?.browser} extension ${connectedExtension?.name}`,
  );
};

const tryEstablishChromiumConnection = async (
  extension: ExtensionInfo,
): Promise<{ success: boolean; port: chrome.runtime.Port | undefined }> => {
  let port: chrome.runtime.Port | undefined;
  try {
    port = await establishChromiumPortConnection(extension);
    logger.info(
      `Connected to ${extension.browser} extension: ${extension.name}`,
    );
  } catch (e) {
    if (
      e instanceof Error &&
      (e.message.includes("Cannot connect") ||
        e.message.includes("Cannot message") ||
        e.message.includes(
          `Cannot read properties of undefined (reading 'sendMessage'`,
        ))
    ) {
      logger.debug(
        `Could not connect to ${extension.browser} extension ${extension.name}: ${e}`,
      );
    } else {
      logger.warn(
        `Unknown error while connecting to ${extension.browser} extension: ${extension.name}:`,
        e,
      );
    }
  }

  return { success: !!port, port: port };
};

const establishChromiumPortConnection = (
  extension: ExtensionInfo,
  timeout = 100,
): Promise<chrome.runtime.Port | undefined> => {
  return new Promise((resolve, reject) => {
    // Check if extension is available
    chrome.runtime.sendMessage(extension.id, { ping: true }, (response) => {
      if (chrome.runtime.lastError || !response) {
        logger.debug(
          `Cannot message ${extension.browser} Extension ${extension.name}`,
        );
        return reject(
          new Error(
            `Cannot message ${extension.browser} Extension ${extension.name}`,
          ),
        );
      }

      // If extension is available, try to connect
      try {
        const port = chrome.runtime.connect(extension.id, {
          name: MIRAGE_PORT_NAME,
        });
        port.onDisconnect.addListener(() => {
          reject(
            new Error(
              `Cannot connect to ${extension.browser} extension ${extension.name}`,
            ),
          );
        });

        // If no disconnect event is fired, we assume the connection was successful after a timeout
        sleepMs(timeout).then(() => resolve(port));
      } catch (error) {
        logger.debug(
          `Error while connecting to ${extension.browser} extension: ${extension.name}:`,
          error,
        );
        reject(error);
      }
    });
  });
};
