import { SearchFilterIcon } from '@mirage/shared/icons/SearchFilterIcon';
import {
  type SearchFilter,
  SearchFilterKeyword,
  SearchFilterType,
  stripStringOfPotentialFilterBindings,
} from '@mirage/shared/search/search-filters';
import { upperFirst } from 'lodash';
import React from 'react';

export function getTypeaheadFilterTitle(filter: SearchFilter): string {
  switch (filter.type) {
    case SearchFilterType.Connector:
      return filter.parameters.displayName;
    case SearchFilterType.ContentType:
      return filter.parameters.label;
    case SearchFilterType.Person:
      return filter.parameters.email;
    case SearchFilterType.LastUpdated:
      return filter.parameters.title;
    default:
      filter satisfies never;
      return '';
  }
}

function toPrettyFilterBindingByType(filter: SearchFilter): string {
  const toPrettyFilterBinding = (prefix: string, name: string): string => {
    return `${upperFirst(prefix)}: ${name}`;
  };

  switch (filter.type) {
    case SearchFilterType.Connector:
      return toPrettyFilterBinding(
        SearchFilterKeyword.Connector,
        filter.parameters.displayName,
      );
    case SearchFilterType.ContentType:
      return toPrettyFilterBinding(
        SearchFilterKeyword.ContentType,
        filter.parameters.label,
      );
    case SearchFilterType.Person:
      return toPrettyFilterBinding(
        SearchFilterKeyword.Person,
        filter.parameters.email,
      );
    case SearchFilterType.LastUpdated:
      return toPrettyFilterBinding(
        SearchFilterKeyword.LastUpdated,
        filter.parameters.title,
      );
    default:
      filter satisfies never;
      return '';
  }
}

export function getTypeaheadFilterDisplayProps(
  query: string,
  nextFilter: SearchFilter,
): {
  title: JSX.Element | string;
  subtitle?: JSX.Element | string;
  icon: JSX.Element;
} {
  switch (nextFilter.type) {
    case SearchFilterType.Connector:
    case SearchFilterType.ContentType:
    case SearchFilterType.LastUpdated:
    case SearchFilterType.Person:
      return {
        title: stripStringOfPotentialFilterBindings(query),
        subtitle: toPrettyFilterBindingByType(nextFilter),
        icon: <SearchFilterIcon filter={nextFilter} />,
      };
    default:
      nextFilter satisfies never;
      return {
        title: '',
        subtitle: <></>,
        icon: <></>,
      };
  }
}
