import {
  ContentType,
  FilterableContentTypes,
} from '@mirage/shared/content-type/content-types';
import {
  LastUpdatedObject,
  LastUpdatedObjects,
} from '@mirage/shared/last-updated/last-updated';
import { getLastWord } from '@mirage/shared/util/tiny-utils';

import type { Connector } from '@mirage/service-dbx-api/service';

export const enum CONNECTOR_TYPES {
  BROWSER_HISTORY = 'browser_history',
  CONNECTOR = 'connector',
}

export const enum SearchFilterType {
  Connector = 'connector',
  LastUpdated = 'lastUpdated',
  Person = 'person',
  ContentType = 'contentType',
}

export enum SearchFilterKeyword {
  Connector = 'in',
  LastUpdated = 'time',
  Person = 'from',
  ContentType = 'type',
  LastUpdatedAfter = 'after',
  LastUpdatedBefore = 'before',
}

export type SearchFilterBinding = [SearchFilterKeyword, string];

export type ConnectorFilter = {
  id: string;
  type: SearchFilterType.Connector;
  parameters: {
    connectorId: string;
    displayName: string;
    iconLightSrc?: string;
    iconDarkSrc?: string;
  };
};

export type LastUpdatedFilter = {
  id: string;
  type: SearchFilterType.LastUpdated;
  parameters: {
    title: string;
    start?: Date;
    end?: Date;
  };
};

export type PersonObject = {
  email: string;
  displayName?: string;
  profilePhotoUrl?: string;
  sortKey?: string;
  type?: 'anyone';
  score?: number;
};

export type PersonFilter = {
  id: string;
  type: SearchFilterType.Person;
  parameters: {
    email: string;
    displayName?: string;
    profilePhotoUrl?: string;
  };
};

export type ContentTypeFilter = {
  id: string;
  type: SearchFilterType.ContentType;
  parameters: {
    key: string;
    label: string;
  };
};

export type SearchFilter =
  | ConnectorFilter
  | LastUpdatedFilter
  | PersonFilter
  | ContentTypeFilter;

export const getSearchFilterNamesForLogging = (
  newFilters: SearchFilter[],
): string[] => {
  const filterNames: string[] = [];
  newFilters.forEach((filter) => {
    switch (filter.type) {
      case SearchFilterType.Connector:
        filterNames.push(filter.parameters.connectorId);
        break;
      case SearchFilterType.LastUpdated:
        {
          const dateFilter = `date_${filter.id.replace(/-/g, '_')}`;
          if (dateFilter) {
            filterNames.push(dateFilter);
          }
        }
        break;
      case SearchFilterType.ContentType:
        filterNames.push(filter.parameters.key);
        break;
      case SearchFilterType.Person:
        filterNames.push('person');
        break;
      default:
        filter satisfies never;
        return undefined as never;
    }
  });

  return filterNames;
};

export function isConnectorFilter(
  filter: SearchFilter,
): filter is ConnectorFilter {
  return filter.type === SearchFilterType.Connector;
}

export function isLastUpdatedFilter(
  filter: SearchFilter,
): filter is LastUpdatedFilter {
  return filter.type === SearchFilterType.LastUpdated;
}

export function isPersonFilter(filter: SearchFilter): filter is PersonFilter {
  return filter.type === SearchFilterType.Person;
}

export function isContentTypeFilter(
  filter: SearchFilter,
): filter is ContentTypeFilter {
  return filter.type === SearchFilterType.ContentType;
}

export const getConnectorFilters = (
  filters: SearchFilter[],
): ConnectorFilter[] => {
  return filters.filter(isConnectorFilter);
};

export const getLastUpdatedFilter = (
  filters: SearchFilter[],
): LastUpdatedFilter | undefined => {
  return filters.find(isLastUpdatedFilter);
};

export const getPersonFilter = (
  filters: SearchFilter[],
): PersonFilter | undefined => {
  return filters.find(isPersonFilter);
};

export const getContentTypeFilters = (
  filters: SearchFilter[],
): ContentTypeFilter[] => {
  return filters.filter(isContentTypeFilter);
};

export const merge = (
  connectorFilters: ConnectorFilter[],
  lastUpdatedFilter: LastUpdatedFilter | undefined,
  personFilter: PersonFilter | undefined,
  contentTypeFilter: ContentTypeFilter[],
): SearchFilter[] => {
  const result: SearchFilter[] = [...connectorFilters, ...contentTypeFilter];
  if (lastUpdatedFilter) {
    result.push(lastUpdatedFilter);
  }
  if (personFilter) {
    result.push(personFilter);
  }
  return result;
};

export function sortDisplayFilters<
  T extends { label: string; type?: CONNECTOR_TYPES },
>(filters: T[]) {
  return filters.sort((a, b) => {
    const isABrowserHistory = a.type === CONNECTOR_TYPES.BROWSER_HISTORY;
    const isBBrowserHistory = b.type === CONNECTOR_TYPES.BROWSER_HISTORY;

    // If both items are the same type, sort alphabetically
    if (isABrowserHistory === isBBrowserHistory) {
      const aSanitized = a.label.replace('*', '').trim().toLowerCase();
      const bSanitized = b.label.replace('*', '').trim().toLowerCase();
      return aSanitized.localeCompare(bSanitized);
    }

    // If only 'a' is browser_history, it should come after 'b'
    if (isABrowserHistory) return 1;

    // If only 'b' is browser_history, it should come after 'a'
    return -1;
  });
}

const SEARCH_FILTER_KEYWORDS = new Set([
  SearchFilterKeyword.Connector,
  SearchFilterKeyword.ContentType,
  SearchFilterKeyword.Person,
  SearchFilterKeyword.LastUpdated,
]);
const singleSelectFilterKeywords = new Set([
  SearchFilterKeyword.ContentType,
  SearchFilterKeyword.Person,
  SearchFilterKeyword.LastUpdated,
]);

export function keywordToSearchFilterType(kw: SearchFilterKeyword) {
  switch (kw) {
    case SearchFilterKeyword.Connector:
      return SearchFilterType.Connector;
    case SearchFilterKeyword.Person:
      return SearchFilterType.Person;
    case SearchFilterKeyword.ContentType:
      return SearchFilterType.ContentType;
    case SearchFilterKeyword.LastUpdated:
    case SearchFilterKeyword.LastUpdatedAfter:
    case SearchFilterKeyword.LastUpdatedBefore:
      return SearchFilterType.LastUpdated;
    default:
      kw satisfies never;
  }
}

export function typeToKeyword(
  searchFilterType: SearchFilterType,
): SearchFilterKeyword {
  switch (searchFilterType) {
    case SearchFilterType.Connector:
      return SearchFilterKeyword.Connector;
    case SearchFilterType.ContentType:
      return SearchFilterKeyword.ContentType;
    case SearchFilterType.Person:
      return SearchFilterKeyword.Person;
    case SearchFilterType.LastUpdated:
      return SearchFilterKeyword.LastUpdated;
    default:
      searchFilterType satisfies never;
      return SearchFilterKeyword.Connector;
  }
}

export function withDelimiter(keyword: SearchFilterKeyword) {
  return `${keyword}:` as const;
}

export function isKeyword(str?: string): str is SearchFilterKeyword {
  return (
    !!str &&
    Object.values(SearchFilterKeyword).includes(str as SearchFilterKeyword)
  );
}

export function extractTrailingSearchFilter(str: string): {
  filterType: SearchFilterType | null;
  query: string;
} {
  const trailingWord = getLastWord(str).toLocaleLowerCase();

  if (!trailingWord?.includes(':')) return { filterType: null, query: '' };

  const [keyword] = trailingWord.split(':');

  if (!isKeyword(keyword)) return { filterType: null, query: '' };

  return {
    filterType: keywordToSearchFilterType(keyword) ?? null,
    query: trailingWord.slice(keyword.length + 1),
  };
}

export function hasFilterBindingKeyword(str: string): boolean {
  let isIncluded = false;
  for (const keyword of SEARCH_FILTER_KEYWORDS) {
    isIncluded = (str || '').includes(withDelimiter(keyword));
    if (isIncluded) break;
  }
  return isIncluded;
}

// Dedupe connectors based on ID
export const dedupeConnectors = (connectors: Connector[]) => {
  return connectors.reduce<{ seen: Set<string>; filtered: Connector[] }>(
    (acc, result) => {
      const connectorId = result.id_attrs?.type;
      // Use a set to track seen IDs
      if (connectorId && !acc.seen.has(connectorId)) {
        acc.seen.add(connectorId);
        acc.filtered.push(result);
      }
      return acc;
    },
    { seen: new Set(), filtered: [] },
  ).filtered;
};

// HACK: Temporary solution to filter out legacy paper connector
// https://jira.dropboxer.net/browse/OSE-4283
const DROPBOX_PAPER_TYPE = 'dropbox_paper';
export const filterOutLegacyPaperConnector = (connector: Connector) => {
  return connector.id_attrs?.type !== DROPBOX_PAPER_TYPE;
};

export function extractFilterBindingsFromString(
  query: string,
): SearchFilterBinding[] {
  const queryParts = query.split(' ');
  const filterBindings = queryParts.filter((queryPart) => {
    const split = queryPart.split(':');
    return split.length === 2 && isKeyword(split[0]);
  });
  return filterBindings.map((filterBinding) => {
    const [keyword, filterText] = filterBinding.split(':');
    return [keyword as SearchFilterKeyword, filterText];
  });
}

export function syncFiltersWithQuery(
  targetQuery: string,
  filters: SearchFilter[],
  allFilters: SearchFilter[],
): SearchFilter[] {
  const nextFilters: SearchFilter[] = [];

  const filterBindings = extractFilterBindingsFromString(targetQuery);

  const existingFilterTypes = new Set();

  for (const [keyword, filterText] of filterBindings) {
    if (!SEARCH_FILTER_KEYWORDS.has(keyword)) continue;

    if (
      singleSelectFilterKeywords.has(keyword) &&
      existingFilterTypes.has(keyword)
    ) {
      continue;
    }

    const existingFilter: SearchFilter | undefined = filters.find(
      (filter) =>
        filter.id === filterText &&
        filter.type === keywordToSearchFilterType(keyword),
    );

    if (existingFilter) {
      nextFilters.push(existingFilter);
    } else {
      const validFilter = allFilters.find(
        (aFilter) =>
          aFilter.id === filterText &&
          aFilter.type === keywordToSearchFilterType(keyword),
      );

      if (!validFilter) {
        if (
          keyword !== SearchFilterKeyword.Person ||
          !isValidEmail(filterText)
        ) {
          continue;
        }

        nextFilters.push({
          id: filterText,
          type: SearchFilterType.Person,
          parameters: {
            email: filterText,
          },
        });
      } else {
        nextFilters.push(validFilter);
      }
    }

    existingFilterTypes.add(keyword);
  }

  return nextFilters;
}

export function syncQueryWithFilters(
  prevQuery: string,
  filters: SearchFilter[],
): string {
  let updatedQuery: string = prevQuery;
  const queryFilterBindings = new Set<SearchFilterBinding>();

  const filterBindings = extractFilterBindingsFromString(prevQuery);

  for (const [keyword, filterText] of filterBindings) {
    if (!keyword || !filterText) continue;

    if (!SEARCH_FILTER_KEYWORDS.has(keyword)) continue;

    queryFilterBindings.add([keyword, filterText]);

    if (
      !filters.find(
        (filter) =>
          filter.id === filterText &&
          filter.type === keywordToSearchFilterType(keyword),
      )
    ) {
      updatedQuery = updatedQuery.replace(
        toFilterBinding(keyword, filterText),
        '',
      );
    }
  }

  for (const filter of filters) {
    if (!SEARCH_FILTER_KEYWORDS.has(typeToKeyword(filter.type))) continue;

    const filterBinding = toFilterBinding(
      typeToKeyword(filter.type),
      filter.id,
    );

    if (
      !queryFilterBindings.has([typeToKeyword(filter.type), filter.id]) &&
      !updatedQuery.includes(filterBinding)
    ) {
      updatedQuery += ` ${filterBinding}`;
    }
  }

  updatedQuery = updatedQuery.replace(/\s+/g, ' ').trim();

  return updatedQuery;
}

export function syncQueryFromURL(urlSearch: {
  query: string;
  filters: SearchFilter[];
}): string {
  let newActiveQuery = dedupeFilterBindings(urlSearch.query);

  for (const urlFilter of urlSearch.filters || []) {
    if (
      !canFilterBindingBeAddedToString(urlSearch.query, urlFilter) ||
      !SEARCH_FILTER_KEYWORDS.has(typeToKeyword(urlFilter.type))
    ) {
      continue;
    }

    newActiveQuery +=
      ' ' + toFilterBinding(typeToKeyword(urlFilter.type), urlFilter.id);
  }

  return newActiveQuery;
}

function canFilterBindingBeAddedToString(
  queryString: string,
  filter: SearchFilter,
): boolean {
  if (singleSelectFilterKeywords.has(typeToKeyword(filter.type))) {
    return !queryString.includes(withDelimiter(typeToKeyword(filter.type)));
  }

  return !queryString.includes(
    toFilterBinding(typeToKeyword(filter.type), filter.id),
  );
}

function dedupeFilterBindings(query: string): string {
  const filterBindings: Set<SearchFilterBinding> = new Set(
    extractFilterBindingsFromString(query),
  );

  const existingKeywords = new Set();

  let rawQuery = stripStringOfPotentialFilterBindings(query);

  for (const [keyword, filterText] of filterBindings) {
    if (
      singleSelectFilterKeywords.has(keyword) &&
      existingKeywords.has(keyword)
    ) {
      continue;
    }

    const filterBinding = toFilterBinding(keyword, filterText);

    if (rawQuery.includes(filterBinding)) {
      continue;
    }

    existingKeywords.add(keyword);
    rawQuery += ' ' + filterBinding;
  }

  return rawQuery;
}

export function toFilterBinding(
  keyword: SearchFilterKeyword,
  filterText: string,
): string {
  return `${withDelimiter(keyword)}${filterText}`;
}

export function connectorToSearchFilter(
  connector: Connector,
): ConnectorFilter | undefined {
  const connectorName = connector.id_attrs?.type;
  const connectorLabel = connector.branding?.display_name;
  const connectorIcon = connector.branding?.icon_src;

  if (!connectorName || !connectorLabel || !connectorIcon) {
    return;
  }

  return {
    id: connectorName,
    type: SearchFilterType.Connector,
    parameters: {
      connectorId: connectorName,
      displayName: connectorLabel,
    },
  };
}

export function contentTypeToSearchFilter(
  contentType: ContentType,
): ContentTypeFilter | undefined {
  if (!contentType.key || !contentType.label) return;

  return {
    id: contentType.key,
    type: SearchFilterType.ContentType,
    parameters: {
      key: contentType.key,
      label: contentType.label(),
    },
  };
}

export function lastUpdatedToSearchFilter(
  lastUpdatedObject: LastUpdatedObject,
): LastUpdatedFilter | undefined {
  if (!lastUpdatedObject.key || !lastUpdatedObject.title) return;

  return {
    id: lastUpdatedObject.key,
    type: SearchFilterType.LastUpdated,
    parameters: {
      title: lastUpdatedObject.title,
      start: lastUpdatedObject.start,
      end: lastUpdatedObject.end,
    },
  };
}

export function personToSearchFilter(
  person: PersonObject,
): PersonFilter | undefined {
  if (!person.email) return;

  return {
    id: person.email,
    type: SearchFilterType.Person,
    parameters: {
      email: person.email,
      displayName: person.displayName,
      profilePhotoUrl: person.profilePhotoUrl,
    },
  };
}

/**
 * Merges the tail of our currentString with filterText so that it is always in a format of 'in:dropbox', for example
 * @param currentQuery - current query string that may contain clean 'in:' or 'in:drop' which includes a partial filter text
 * @param filterTextToAppend - the complete filter text to be appended to 'in:'
 */
export function mergePartialFilterText(
  currentQuery: string,
  filterTextToAppend: string,
): string {
  const words = currentQuery.trim().split(' ');
  const lastWord = words[words.length - 1];
  const [keyword] = lastWord.split(':');

  if (!isKeyword(keyword)) return currentQuery;

  words[words.length - 1] = toFilterBinding(keyword, filterTextToAppend);

  return words.join(' ');
}

export function stripStringOfPotentialFilterBindings(query: string): string {
  const formattedFilterBindings = extractFilterBindingsFromString(query);

  for (const [keyword, filterText] of formattedFilterBindings) {
    if (!SEARCH_FILTER_KEYWORDS.has(keyword)) continue;
    query = query.replace(toFilterBinding(keyword, filterText), '');
  }

  return query.replace(/\s+/g, ' ').trim();
}

export function mergeFilterTypes(
  connectors: Connector[],
  contentTypes: FilterableContentTypes,
  lastUpdatedObjects: LastUpdatedObjects,
): SearchFilter[] {
  return [
    ...connectors
      .map((connector) => connectorToSearchFilter(connector)!)
      .filter(Boolean),
    ...contentTypes
      .map((contentType) => contentTypeToSearchFilter(contentType)!)
      .filter(Boolean),
    ...lastUpdatedObjects
      .map((lastUpdatedObject) => lastUpdatedToSearchFilter(lastUpdatedObject)!)
      .filter(Boolean),
  ];
}

/**
 * Simple email check used in both PeopleFilterTypeahead and search-filters
 * Purposefully naive to avoid complexities of true email validation
 */
export function isValidEmail(maybeEmail: string): boolean {
  return maybeEmail.includes('@');
}
