import { asterixOut, transformJsonResponse } from "@alwaysmeticulous/redaction";
import { RecorderMiddleware } from "@alwaysmeticulous/sdk-bundles-api";
import { dash_feed } from "@dropbox/api-v2-client";
import { StoredActivityFeed } from "@mirage/service-feed/service";

import {
  DEFAULT_REDACTED_URL,
  DEFAULT_REDACTED_NAME,
  createRedactedApiV2Endpoint,
  redactArray,
} from "../utils";

export const redactActivity = (
  activity: dash_feed.ActivityItem,
): dash_feed.ActivityItem => {
  const redacted = { ...activity };

  if (redacted.actor) {
    redacted.actor = {
      ...activity.actor,
      name: DEFAULT_REDACTED_NAME,
      email: activity.actor?.email
        ? asterixOut(activity.actor.email)
        : undefined,
      avatar_img: activity.actor?.avatar_img ? DEFAULT_REDACTED_URL : undefined,
    };
  }

  if (redacted.object) {
    redacted.object = {
      ...activity.object,
      name: activity.object?.name
        ? asterixOut(activity.object.name)
        : undefined,
      url: activity.object?.url ? DEFAULT_REDACTED_URL : undefined,
      preview_img: activity.object?.preview_img
        ? DEFAULT_REDACTED_URL
        : undefined,
    };
  }
  return redacted;
};

export const dashFeedListActivityFeedRedacted =
  createRedactedApiV2Endpoint<StoredActivityFeed>(
    "dashFeedListActivityFeed",
    "dash_feed",
    "list_activity_feed",
    "activity-feed",
    (data: StoredActivityFeed): StoredActivityFeed => {
      return {
        ...data,
        activities: redactArray(data?.activities, redactActivity),
      };
    },
    (): RecorderMiddleware => {
      return transformJsonResponse<dash_feed.ListActivityFeedResponse>({
        urlRegExp: dashFeedListActivityFeedRedacted.urlRegExp,
        transform: (data) => ({
          ...data,
          items: redactArray(data?.items, redactActivity),
        }),
      });
    },
  );
