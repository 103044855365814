import { Button } from '@dropbox/dig-components/buttons';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text, Title } from '@dropbox/dig-components/typography';
import {
  AvailableThemeOverridesStrict,
  ThemeProvider,
} from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronUpLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { DashCardType } from '@mirage/analytics/events/enums/dash_card_type';
import { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { CollapsedCardKey } from '@mirage/service-settings/service/types';
import { useCollapsedCardSettings } from '@mirage/service-settings/useCollapsedCardSettings';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { ReactNode, useCallback } from 'react';
import { IconButtonWithTooltip } from '../icons/IconButtonWithTooltip';
import styles from './TwoColumnGridCard.module.css';

export enum CardHeaderType {
  INLINE_SUBTITLE,
  STACKED_SUBTITLE,
  CUSTOM,
}

// TODO: This isn't ideal, as the metrics aren't based on the header type, but rather
// whether the card is collapsible or not. With a custom header, these properties
// also aren't needed because the control is expected to be managed by the parent.
export type CardHeaderTypeProps =
  | {
      cardType: CardHeaderType.STACKED_SUBTITLE;
      title: string;
      dashCardType?: DashCardType;
      actionSurfaceComponent?: ActionSurfaceComponent;
      featureLine: FeatureLine;
    }
  | {
      cardType: CardHeaderType.INLINE_SUBTITLE;
      title: string;
      dashCardType?: DashCardType;
      actionSurfaceComponent?: ActionSurfaceComponent;
      featureLine: FeatureLine;
    }
  | {
      cardType: CardHeaderType.CUSTOM;
      customHeader: ReactNode;
    };

export type TwoColumnGridCardProps = {
  settingId: CollapsedCardKey;
  cardTypeProps: CardHeaderTypeProps;

  // If false, the card will be collapsed with no option to expand
  isAlwaysCollapsed?: boolean;
  showsChildrenWhileCollapsed?: boolean;
  collapseBtnSize?: 'large' | 'small';
  // If defined, the card state will be controlled by the parent
  // Use in conjunction with `customHeader`
  isCollapsedOverride?: boolean;
  isCollapsedInitially?: boolean;
  onBeforeCollapse?: (state: boolean) => void;
  headerActions?: ReactNode;

  subtitle?: string;
  titleSize?: 'large' | 'medium' | 'small' | 'xsmall';
  // If false, don't show the divider line in the header
  showDividerLine?: boolean;
  showHeader?: boolean;
  className?: string;
  showSubtitleWhenExpanded?: boolean;
  children?: ReactNode;
  theme?: AvailableThemeOverridesStrict;
};

export const TwoColumnGridCard: React.FC<TwoColumnGridCardProps> = ({
  settingId,
  cardTypeProps,
  isAlwaysCollapsed = true,
  showsChildrenWhileCollapsed = false,
  collapseBtnSize,
  isCollapsedOverride,
  isCollapsedInitially,
  onBeforeCollapse,
  headerActions,
  titleSize = 'xsmall',
  subtitle,
  showDividerLine = true,
  showHeader = true,
  className,
  showSubtitleWhenExpanded = true,
  children,
  theme,
}) => {
  const { isCollapsed, setCollapsed } = useCollapsedCardSettings(
    settingId,
    isCollapsedOverride !== undefined
      ? isCollapsedOverride
      : isCollapsedInitially,
  );

  // If we haven't yet determined if the user has set a collapsed state yet,
  // then return nothing until we do, so that we don't render a card that
  // flickers from expanded to collapsed
  if (isCollapsed === undefined) {
    return <></>;
  }

  // Allow external state to control the collapsed state
  const _isCollapsed = !isAlwaysCollapsed
    ? true
    : (isCollapsedOverride ?? isCollapsed);

  return (
    <ThemeProvider overrides={theme}>
      {({ getThemeProps }) => (
        <div
          {...getThemeProps({
            className: classnames(
              styles.cardContainer,
              theme !== undefined && styles.themedCardContainer,
              _isCollapsed && isAlwaysCollapsed && styles.cardCollapsed,
              !showHeader && styles.noHeader,
              className && className,
            ),
          })}
        >
          <CardHeader
            data-testid="two-column-grid-header"
            settingId={settingId}
            onBeforeCollapse={onBeforeCollapse}
            showHeader={showHeader}
            cardTypeProps={cardTypeProps}
            isAlwaysCollapsed={isAlwaysCollapsed}
            collapseBtnSize={collapseBtnSize}
            headerActions={headerActions}
            subtitle={subtitle}
            titleSize={titleSize}
            showSubtitleWhenExpanded={showSubtitleWhenExpanded}
            isCollapsed={isCollapsed}
            _isCollapsed={_isCollapsed}
            setCollapsed={setCollapsed}
          />
          {(!isAlwaysCollapsed ||
            showsChildrenWhileCollapsed ||
            (!_isCollapsed && isAlwaysCollapsed)) && (
            <>
              {showDividerLine && <div className={styles.divider} />}
              {children}
            </>
          )}
        </div>
      )}
    </ThemeProvider>
  );
};

type CardHeaderProps = TwoColumnGridCardProps & {
  isCollapsed: boolean;
  _isCollapsed: boolean;
  setCollapsed: (value: boolean) => void;
};

const CardHeader: React.FC<CardHeaderProps> = ({
  onBeforeCollapse,
  showHeader,
  cardTypeProps,
  isAlwaysCollapsed,
  collapseBtnSize,
  headerActions,
  subtitle,
  titleSize,
  showSubtitleWhenExpanded,
  isCollapsed,
  _isCollapsed,
  setCollapsed,
}) => {
  // This should only be called if the card is managing the collapsed
  // state itself, and not by override
  const _handleCollapse = useCallback(
    (state: boolean) => {
      onBeforeCollapse?.(state);
      setCollapsed(state);
    },
    [setCollapsed, onBeforeCollapse],
  );

  if (!showHeader) {
    return <></>;
  }

  switch (cardTypeProps.cardType) {
    case CardHeaderType.CUSTOM:
      return <>{cardTypeProps.customHeader}</>;

    case CardHeaderType.STACKED_SUBTITLE: {
      const { title, actionSurfaceComponent, featureLine, dashCardType } =
        cardTypeProps;

      return (
        <DefaultHeader
          isAlwaysCollapsed={isAlwaysCollapsed}
          collapseBtnSize={collapseBtnSize || 'large'}
          isCollapsed={_isCollapsed}
          setIsCollapsed={_handleCollapse}
          headerActions={headerActions}
          dashCardType={dashCardType}
          actionSurfaceComponent={actionSurfaceComponent}
          featureLine={featureLine}
        >
          <div>
            <div className={styles.titleContainer}>
              <Title
                className={classnames(
                  styles.primaryTitle,
                  titleSize === 'xsmall' && styles.titleSizeXSmall,
                )}
                weightVariant="emphasized"
                size={titleSize !== 'xsmall' ? titleSize : undefined}
              >
                {title}
              </Title>
            </div>
            {subtitle !== undefined && (
              <div>
                <Text color="faint" size="small">
                  {subtitle}
                </Text>
              </div>
            )}
          </div>
        </DefaultHeader>
      );
    }
    case CardHeaderType.INLINE_SUBTITLE:
    default: {
      const { title, actionSurfaceComponent, featureLine, dashCardType } =
        cardTypeProps;

      return (
        <DefaultHeader
          isAlwaysCollapsed={isAlwaysCollapsed}
          collapseBtnSize={collapseBtnSize}
          isCollapsed={_isCollapsed}
          setIsCollapsed={_handleCollapse}
          headerActions={headerActions}
          dashCardType={dashCardType}
          actionSurfaceComponent={actionSurfaceComponent}
          featureLine={featureLine}
        >
          <div
            className={styles.titleContainer}
            data-testid="two-column-grid-title"
          >
            <Title
              className={classnames(
                styles.primaryTitle,

                titleSize === 'xsmall' && styles.titleSizeXSmall,
              )}
              weightVariant="emphasized"
              size={titleSize !== 'xsmall' ? titleSize : undefined}
            >
              <Truncate>{title}</Truncate>
            </Title>
            {subtitle !== undefined &&
              (showSubtitleWhenExpanded || isCollapsed) && (
                <>
                  <Text color="faint">{'·'}</Text>
                  <Text color="faint">{subtitle}</Text>
                </>
              )}
          </div>
        </DefaultHeader>
      );
    }
  }
};

type TwoColumnGridCardHeaderProps = {
  // If false, the card will be collapsed with no option to expand
  isAlwaysCollapsed?: boolean;
  collapseBtnSize?: 'large' | 'small';
  headerActions?: ReactNode;
  isCollapsed: boolean;
  setIsCollapsed: (state: boolean) => void;
  dashCardType?: DashCardType;
  actionSurfaceComponent?: ActionSurfaceComponent;
  featureLine: FeatureLine;
};

const DefaultHeader: React.FC<TwoColumnGridCardHeaderProps> = ({
  isAlwaysCollapsed = true,
  collapseBtnSize = 'small',
  isCollapsed,
  setIsCollapsed,
  headerActions,
  children,
  dashCardType,
  actionSurfaceComponent,
  featureLine,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const onExpandCollapseClick = useCallback(() => {
    if (isCollapsed) {
      reportPapEvent(
        PAP_Expand_DashCard({
          actionSurfaceComponent,
          featureLine,
          dashCardType,
        }),
      );
    } else {
      reportPapEvent(
        PAP_Collapse_DashCard({
          actionSurfaceComponent,
          featureLine,
          dashCardType,
        }),
      );
    }
    setIsCollapsed(!isCollapsed);
  }, [
    actionSurfaceComponent,
    dashCardType,
    featureLine,
    isCollapsed,
    reportPapEvent,
    setIsCollapsed,
  ]);

  return (
    <div className={styles.headerContainer}>
      {children}
      <div className={styles.actionsContainer}>
        {isAlwaysCollapsed &&
          (collapseBtnSize === 'small' ? (
            <Button
              variant="borderless"
              style={{ color: 'var(--dig-color__text__subtle)' }}
              onClick={onExpandCollapseClick}
              className={isCollapsed ? styles.collapseBtn : styles.expandBtn}
              withDropdownIcon
            >
              <Text color="faint" size="small">
                {isCollapsed ? i18n.t('expand') : i18n.t('collapse')}
              </Text>
            </Button>
          ) : (
            <IconButtonWithTooltip
              tooltipProps={{
                title: isCollapsed ? i18n.t('expand') : i18n.t('collapse'),
              }}
              variant="borderless"
              onClick={onExpandCollapseClick}
            >
              <UIIcon
                src={isCollapsed ? ChevronDownLine : ChevronUpLine}
                color="var(--dig-color__text__subtle)"
              />
            </IconButtonWithTooltip>
          ))}
        {headerActions}
      </div>
    </div>
  );
};
