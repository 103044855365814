import { tagged } from '@mirage/service-logging';
import * as rx from 'rxjs';
import {
  createDocAnswerObservable,
  createDocSummarizationObservable,
} from './context_observables';
import { DocQnaRequest, DocQnaResponse } from './gen/doc_summarization_pb';
import { DocumentId } from './gen/doc_summarization_pb';

import type {
  DocContent,
  DocSummaryQnaError,
  DocSummaryQnaResponse,
  DocSummaryQnaResult,
  DocSummaryQnaSuccess,
  QnaAnswer,
} from './types';

// import type { SearchResult } from '@mirage/service-dbx-api/service/search';
export {
  DocContent,
  DocSummaryQnaSuccess,
  DocSummaryQnaError,
  DocQnaRequest,
  DocQnaResponse,
  DocSummaryQnaResult,
  QnaAnswer,
};

const logger = tagged('doc_summarization');

// TODO: (OSE-3825) Implement the following functions
// getPersistedDocSummary(id: string): Promise<DocSummarizationresponses | undefined> {
// fetchDocSummaryIfNeeded(id: string, regenerate: boolean): rx.Observable<DocSummarizationresponses> {
// getAnswerForDoc..

/**
 * Using this at the parent level for overall state and in each answer
 */
export const enum LoadingStatus {
  /**
   * The request is started but nothing has returned yet
   * Set in the hook, not the server api
   */
  pending = 'pending',
  /**
   * The request is returning successfully, but may not be complete
   * Set from the server api
   */
  success = 'success',
  /**
   * The request has failed
   * Set from the server api
   */
  error = 'error',
  /**
   * No request has been made or the request has finished
   * Set in the hook, not the server api
   */
  idle = 'idle',
}

export const enum Role {
  User = 'user',
  System = 'system',
  Error = 'error',
  Empty = 'empty',
}

export function fetchDocSummaryWithId(
  resultId: DocumentId,
  answerId: string,
  initialQuery: string,
  connectorName: string,
  recordType: string,
  docSummarySetting: string,
  fileType: string = '',
): rx.Observable<DocSummaryQnaResult> {
  const summary$ = createDocSummarizationObservable(
    resultId,
    fileType,
    connectorName,
    docSummarySetting,
  );

  const accSummary: DocSummaryQnaSuccess = {
    id: resultId,
    initialQuery,
    connectorName,
    recordType,
    status: LoadingStatus.success,
    created: Date.now(),
    responses: [
      {
        status: LoadingStatus.success,
        id: answerId,
        created: Date.now(),
        queryString: 'Summary',
        role: Role.System,
        text: '',
      },
    ],
  };

  return summary$.pipe(
    rx.scan((acc: DocSummaryQnaSuccess, chunk: DocSummaryQnaResponse) => {
      return _processChunk(
        acc,
        chunk.answer,
        chunk.requestId,
        resultId,
        answerId,
      );
    }, accSummary),
    rx.catchError((error) => {
      logger.error('fetchDocSummaryWithId error', error);
      const errorSummary: DocSummaryQnaError = {
        status: LoadingStatus.error,
        description: 'Failed to fetch document summary.',
      };
      return rx.of(errorSummary);
    }),
  );
}

export function fetchDocQnaWithId(
  resultId: DocumentId,
  answerId: string,
  queryString: string,
  docQnaSetting: string,
  prevSummary?: DocSummaryQnaSuccess,
  fileType: string = '',
  connectorName: string = '',
): rx.Observable<DocSummaryQnaResult> {
  const summary$ = createDocAnswerObservable(
    resultId,
    queryString,
    fileType,
    connectorName,
    docQnaSetting,
  );
  return summary$.pipe(
    rx.scan(
      (acc: DocSummaryQnaSuccess, chunk: DocSummaryQnaResponse) => {
        return _processChunk(
          acc,
          chunk.answer,
          chunk.requestId,
          resultId,
          answerId,
          queryString,
        );
      },
      {
        status: 'success',
        id: resultId,
        ...prevSummary,
      } as DocSummaryQnaSuccess,
    ),
    rx.catchError((error) => {
      logger.error('fetchDocSummaryWithId error', error);
      const errorSummary: DocSummaryQnaError = {
        status: LoadingStatus.error,
        description: 'Failed to fetch document answer.',
      };
      return rx.of(errorSummary);
    }),
  );
}

export function getDocSummary(
  id: DocumentId,
  answerId: string,
  initialQuery: string,
  connectorName: string,
  recordType: string,
  docSummarySetting: string,
  fileType: string = '',
) {
  return fetchDocSummaryWithId(
    id,
    answerId,
    initialQuery,
    connectorName,
    recordType,
    docSummarySetting,
    fileType,
  );
}

export function getDocAnswer(
  id: DocumentId,
  answerId: string,
  queryString: string,
  docQnaSetting: string,
  previousSummary?: DocSummaryQnaSuccess,
  fileType: string = '',
  connectorName: string = '',
): rx.Observable<DocSummaryQnaResult> {
  return fetchDocQnaWithId(
    id,
    answerId,
    queryString,
    docQnaSetting,
    previousSummary,
    fileType,
    connectorName,
  );
}

export function isMultimodalContent(
  connectorName: string,
  fileTypeId: string,
  multimodalFileTypeFilter: { [key: string]: string[] },
): boolean {
  const multimodalFileTypes = multimodalFileTypeFilter['enabled_types'] || [
    'presentation',
    'pdf',
    'image',
    'google_slide',
  ];
  const multimodalConnectors = multimodalFileTypeFilter[
    'enabled_connectors'
  ] || ['googledrive'];
  return (
    multimodalFileTypes.includes(fileTypeId) &&
    multimodalConnectors.includes(connectorName)
  );
}

function createResponse(
  answerId: string,
  queryString: string,
  text: string = '',
  requestId: string,
  created?: number,
) {
  return {
    created: created || Date.now(),
    status: LoadingStatus.success,
    id: answerId,
    queryString,
    text,
    requestId,
  };
}

function _processChunk(
  acc: DocSummaryQnaSuccess,
  text: string = '',
  requestId: string,
  resultId: DocumentId,
  answerId: string,
  queryString: string = 'Summary',
): DocSummaryQnaSuccess {
  const answerIndex = acc.responses.findIndex((res) => res.id === answerId);
  if (answerIndex >= 0 && acc.responses[answerIndex].created) {
    // updating existing answer or summary
    acc.responses[answerIndex].text += text;
    acc.responses[answerIndex].requestId = requestId;
  } else if (answerIndex >= 0 && !acc.responses[answerIndex].created) {
    // creating an answer that may contain some information
    acc.responses[answerIndex] = {
      ...acc.responses[answerIndex],
      ...createResponse(answerId, queryString, text, requestId),
    };
  } else {
    // creating a new answer from scratch
    acc.responses.push(
      createResponse(answerId, queryString, text, requestId, Date.now()),
    );
  }

  acc.status = LoadingStatus.success;
  acc.responses = [...acc.responses];
  acc.created = acc.created || Date.now();
  acc.id = resultId;

  return acc;
}
