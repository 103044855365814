import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { EmptyBoxMini } from '@dropbox/dig-illustrations';
import { useIsDropboxer } from '@mirage/service-auth/useDropboxAccount';
import {
  getCachedOrListConnections,
  getCachedOrListConnectors,
} from '@mirage/service-connectors';
import { isConnectorVisible } from '@mirage/service-connectors/utils';
import { tagged } from '@mirage/service-logging';
import { Skeletons } from '@mirage/settings/components/connectors/Skeleton';
import {
  useBrowserExtensionData,
  useSetBrowserExtensionData,
} from '@mirage/shared/atoms/browserExtensionData';
import {
  connectionsByTypeAtom,
  useConnections,
  useSetConnections,
} from '@mirage/shared/atoms/connections';
import {
  connectorsAtom,
  connectorsByTypeAtom,
  useConnectors,
  useSetConnectors,
} from '@mirage/shared/atoms/connectors';
import { UIConnection, UIConnector } from '@mirage/shared/types';
import { DASH_WEBAPP } from '@mirage/shared/urls';
import { getRoutePathname } from '@mirage/shared/util/route-pathname';
import i18n from '@mirage/translations';
import { atom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { ConnectionsSection } from './components/connectors/ConnectionsSection';
import { ConnectorsSection } from './components/connectors/ConnectorsSection';
import sectionStyles from './components/connectors/Section.module.css';
import styles from './ConnectorsList.module.css';
import { filterBrowserExtensionConnections } from './hooks/connectors/useConnectConnector';
import {
  fetchBrowserExtensionData,
  isInsideExtension,
  redirectOnTopFrame,
} from './utils/webExtensionsHelpers';

// compareConnectors is a comparison function which
// can be used to sort connectors in alphabetical order.
const compareConnectors = (
  a: UIConnector | UIConnection,
  b: UIConnector | UIConnection,
): number => {
  const aDisplayName = a.branding?.display_name ?? '';
  const bDisplayName = b.branding?.display_name ?? '';
  if (aDisplayName < bDisplayName) {
    return -1;
  } else if (aDisplayName > bDisplayName) {
    return 1;
  }

  return 0;
};

const logger = tagged('ConnectorsList');

function ConnectorsEmptyState() {
  return (
    <Box
      data-testid="emptystate-section"
      className={styles.emptyState}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <div className={styles.iconContainer}>
        <EmptyBoxMini altText={i18n.t('company_apps_empty_state_title')} />
      </div>
      <Text className={styles.emptyStateTitle} size="medium" isBold>
        {i18n.t('company_apps_empty_state_title')}
      </Text>
      <Text className={styles.emptyStateMessage} size="medium">
        {i18n.t('company_apps_empty_state_message')}
      </Text>
    </Box>
  );
}

export const ConnectorsList: React.FC = () => {
  // Prevent page flicker.
  const [show, setShow] = useState(false);

  // The Connectors popups won't work inside an iframe, or inside a browser
  // extension url. So just pop out into the hosted webapp right away.
  useEffect(() => {
    if (isInsideExtension()) {
      const newUrl = DASH_WEBAPP + getRoutePathname() + location.search;
      redirectOnTopFrame(newUrl);
    } else {
      setShow(true);
    }
  }, []);

  return show ? <ConnectorsListActual /> : null;
};

function ConnectorsListActual() {
  const connections = useConnections();
  const connectors = useConnectors();
  const setConnectors = useSetConnectors();
  const setConnections = useSetConnections();
  const browserExtensionData = useBrowserExtensionData();
  const setBrowserExtensionData = useSetBrowserExtensionData();
  const allConnectors = useAtomValue(allConnectorsAtom);
  const popularConnectors = useAtomValue(popularConnectorsAtom);
  const isDropboxer = useIsDropboxer();

  useEffect(() => {
    async function doFetchConnectors() {
      try {
        setConnectors((atom) => ({ ...atom, loading: true }));
        const connectors = (await getCachedOrListConnectors()).filter(
          isConnectorVisible,
        );
        setConnectors({
          data: connectors.map((c) => ({ ...c, loading: false })),
          loading: false,
          loaded: true,
        });
      } catch (e) {
        logger.error('doFetchConnectors', e);
        setConnectors((atom) => ({ ...atom, loading: false }));
      }
    }
    doFetchConnectors();
  }, [setConnectors]);

  useEffect(() => {
    async function doFetchConnections() {
      try {
        setConnections((atom) => ({ ...atom, loading: true }));
        const connections = await getCachedOrListConnections();
        setConnections({
          data: connections
            .filter(filterBrowserExtensionConnections)
            .map((c) => ({
              ...c,
              loading: false,
              syncing: false,
            })),
          loaded: true,
          loading: false,
        });
      } catch (e) {
        logger.error('doFetchConnections', e);
        setConnections((atom) => ({ ...atom, loading: false }));
      }
    }
    doFetchConnections();
  }, [setConnections]);

  useEffect(() => {
    async function doFetchBrowserExtensionData() {
      try {
        setBrowserExtensionData((atom) => ({ ...atom, loading: true }));
        const data = await fetchBrowserExtensionData(isDropboxer);
        setBrowserExtensionData({
          data: data.map((extension) => ({ ...extension, loading: false })),
          loaded: true,
          loading: false,
        });
      } catch (e) {
        logger.error('doFetchBrowserExtensionData', e);
        setBrowserExtensionData((atom) => ({ ...atom, loading: false }));
      }
    }
    doFetchBrowserExtensionData();
  }, [setBrowserExtensionData]);

  if (!connections.loaded || !connectors.loaded) {
    return <Skeletons length={3} />;
  }

  return (
    <div>
      <ConnectionsSection data-testid="connections-section" />

      {popularConnectors.length > 0 && (
        <>
          <Box display="block" paddingTop="32">
            <Text size="medium" isBold>
              {i18n.t('connectors_section_popular')}
            </Text>
            <hr className={sectionStyles.horizontalSeparator} />
          </Box>
          <ConnectorsSection
            connectors={popularConnectors}
            data-testid="popular-connectors-section"
          />
        </>
      )}

      {(allConnectors.length > 0 || browserExtensionData?.data.length > 0) && (
        <>
          <Box display="block" paddingTop="32">
            <Text size="medium" isBold>
              {i18n.t('connectors_section_all')}
            </Text>
            <hr className={sectionStyles.horizontalSeparator} />
          </Box>
          <ConnectorsSection
            browserExtensionData={browserExtensionData}
            connectors={allConnectors}
            data-testid="all-connectors-section"
          />
        </>
      )}

      {!allConnectors.length &&
        !popularConnectors.length &&
        !connections.data.length && <ConnectorsEmptyState />}
    </div>
  );
}

export const orderedPopularConnectorTypes = [
  'dropbox',
  'notion',
  'asana',
  'hubspot',
  'salesforce',
];

const popularConnectorsAtom = atom((get) => {
  const connectionsByType = get(connectionsByTypeAtom);
  const connectorsByType = get(connectorsByTypeAtom);

  return getPopularConnectors(connectionsByType, connectorsByType);
});

export function getPopularConnectors(
  connectionsByType: Record<string, UIConnection>,
  connectorsByType: Record<string, UIConnector>,
): UIConnector[] {
  if (Object.keys(connectorsByType).length === 0) return [];

  return (
    orderedPopularConnectorTypes
      // Keep connector types that aren't connected.
      .filter((type) => !connectionsByType[type])
      .map((type) => connectorsByType[type])
      .filter((connector) => connector)
  );
}

const popularConnectorsByTypeAtom = atom<Record<string, UIConnector>>((get) => {
  const popularConnectors = get(popularConnectorsAtom);

  return popularConnectors.reduce((acc, c) => {
    if (!c.id_attrs?.type) return acc;
    return {
      ...acc,
      [c.id_attrs?.type]: c,
    };
  }, {});
});

const allConnectorsAtom = atom((get) => {
  const { data: connectors } = get(connectorsAtom);
  const popularConnectorsByType = get(popularConnectorsByTypeAtom);
  const connectionsByType = get(connectionsByTypeAtom);

  // Return unconnected connectors not in the popular connectors list.
  return connectors
    .filter((c) => {
      const type = c.id_attrs?.type ?? '';
      return !connectionsByType[type] && !popularConnectorsByType[type];
    })
    .sort(compareConnectors);
});
