import { Box, useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CommentLine, EditLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { ToggleExpandButton } from '@mirage/mosaics/ComposeAssistant/components/layouts/ToggleExpandButton';
import { useIsMobileSizeForSidebar } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { Resizable } from 're-resizable';
import { memo, useEffect, useState } from 'react';
import styles from './ComposeAssistantPageLayout.module.css';

interface ComposeAssistantPageWrapperProps {
  children: React.ReactNode;
}
export const ComposeAssistantPageWrapper = memo(
  ({ children }: ComposeAssistantPageWrapperProps) => {
    return <div className={styles.wrapper}>{children}</div>;
  },
);
ComposeAssistantPageWrapper.displayName = 'ComposeAssistantPageWrapper';

interface ComposeAssistantGridProps {
  sideBar: React.ReactNode;
  sidePane: React.ReactNode;
  rightPane: React.ReactNode;
  setExpandRightPane: (expanded: boolean) => void;
  disableExpandRightPane: boolean;
  didJustSendFirstWriteCommand: boolean;
  keepSideBarExpanded: boolean;
  setKeepSideBarExpanded?: (expanded: boolean) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const ComposeAssistantGrid = memo(
  ({
    sideBar,
    sidePane,
    rightPane,
    setExpandRightPane,
    disableExpandRightPane,
    didJustSendFirstWriteCommand,
    keepSideBarExpanded,
    setKeepSideBarExpanded,
    logComposeEvent,
  }: ComposeAssistantGridProps) => {
    const theme = useTheme();
    const isMobileSize = useIsMobileSizeForSidebar();
    const [showHandle, setShowHandle] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);
    const [isEditorView, setIsEditorView] = useState(false);

    // On mobile the sidebar gets absolute positioning and a clickable overlay is shown
    const [showDismissalOverlay, setShowDismissalOverlay] = useState(false);
    useEffect(() => {
      setShowDismissalOverlay(isMobileSize && keepSideBarExpanded);
    }, [isMobileSize, keepSideBarExpanded]);

    useEffect(() => {
      let fadeOutTimer: NodeJS.Timeout;
      let hideTimer: NodeJS.Timeout;
      const handleMouseMove = () => {
        clearTimeout(hideTimer);
        clearTimeout(fadeOutTimer);
        setShowHandle(true);
        setFadeOut(false);
        hideTimer = setTimeout(() => {
          setFadeOut(true);
          fadeOutTimer = setTimeout(() => {
            setShowHandle(false);
          }, 300);
        }, 1000);
      };
      window.addEventListener('mousemove', handleMouseMove);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        clearTimeout(hideTimer);
        clearTimeout(fadeOutTimer);
      };
    }, []);
    const toggleView = () => {
      setIsEditorView((prev) => !prev);
    };
    return (
      <div className={styles.ComposeAssistantGrid}>
        {showDismissalOverlay && setKeepSideBarExpanded && (
          <Box
            className={styles.ComposeAssistantOverlay}
            onClick={() => setKeepSideBarExpanded(false)}
          />
        )}
        <div className={styles.ComposeAssistantGridSideBar}>{sideBar}</div>
        {isMobileSize && rightPane ? (
          // Mobile layout ()
          <div className={styles.ComposeAssistantMobileContainer}>
            <div className={styles.ComposeAssistantMobilePane}>
              {isEditorView ? rightPane : sidePane}
            </div>
            <button
              className={styles.ComposeAssistantMobileToggleButton}
              onClick={toggleView}
              aria-label={
                isEditorView
                  ? i18n.t('assistant_chat_view_toggle')
                  : i18n.t('assistant_editor_view_toggle')
              }
            >
              <UIIcon src={isEditorView ? CommentLine : EditLine} />
            </button>
          </div>
        ) : (
          // Desktop layout
          <>
            {rightPane ? (
              <Resizable
                defaultSize={{
                  width: didJustSendFirstWriteCommand ? '45%' : '35%',
                }}
                minWidth={280}
                enable={{ right: true }}
                handleComponent={{
                  right: showHandle ? (
                    <div
                      className={styles.ComposeAssistantResizeHandleContainer}
                    >
                      <div
                        className={classNames(
                          styles.ComposeAssistantResizeHandle,
                          {
                            [styles.ComposeAssistantResizeHandleFadeOut]:
                              fadeOut,
                          },
                        )}
                      />
                    </div>
                  ) : undefined,
                }}
              >
                {sidePane}
              </Resizable>
            ) : (
              <div
                className={classNames(
                  styles.ComposeAssistantGridSidePane,
                  styles.ComposeAssistantGridSidePaneNoResize,
                )}
                style={{ width: '100%' }}
              >
                {sidePane}
              </div>
            )}
            <div
              className={classNames(styles.ComposeAssistantGridRightPane, {
                [styles.ComposeAssistantGridRightPaneExpanded]: !!rightPane,
                [styles.ComposeAssistantGridRightPaneDark]:
                  theme.mode === 'dark',
              })}
            >
              {rightPane || (
                <div className={styles.RightPaneToggleContainer}>
                  <ToggleExpandButton
                    setExpanded={setExpandRightPane}
                    variant="expand"
                    disabled={disableExpandRightPane}
                    logComposeEvent={logComposeEvent}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  },
);
ComposeAssistantGrid.displayName = 'ComposeAssistantGrid';
