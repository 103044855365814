import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Close_DashPrivacyModal } from '@mirage/analytics/events/types/close_dash_privacy_modal';
import { PAP_Open_DashConnectAppPageContinue } from '@mirage/analytics/events/types/open_dash_connect_app_page_continue';
import { PAP_Open_DashPrivacyModal } from '@mirage/analytics/events/types/open_dash_privacy_modal';
import { PAP_Shown_DashConnectAppPage } from '@mirage/analytics/events/types/shown_dash_connect_app_page';
import {
  createUxaElementId,
  dispatchElementClicked,
} from '@mirage/analytics/uxa';
import { TeamOnboardingLayout } from '@mirage/growth/components/TeamOnboardingLayout';
import { listConnections } from '@mirage/service-connectors';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { filterBrowserExtensionConnections } from '@mirage/settings/hooks/connectors/useConnectConnector';
import { fetchConnectorsWithVisibilityFilters } from '@mirage/settings/utils/connectorMetadataService';
import useAvailableExtensions from '@mirage/settings/utils/useAvailableExtensions';
import { isExtensionInstalled } from '@mirage/settings/utils/webExtensionsHelpers';
import {
  useConnections,
  useSetConnections,
} from '@mirage/shared/atoms/connections';
import { useSetConnectors } from '@mirage/shared/atoms/connectors';
import i18n, { Trans } from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useOnboardingStyles } from '../hooks/useOnboardingStyles';
import styles from './ConnectorsOnboarding.module.css';
import { PrivacyModal } from './PrivacyModal';
import { TeamOnboardingConnectorList } from './TeamOnboardingConnectorList';

type Props = {
  onStepFinish: (step: string) => void;
};

export const ConnectorsOnboarding = ({ onStepFinish }: Props) => {
  const connections = useConnections();
  const setConnections = useSetConnections();
  const setConnectors = useSetConnectors();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const extensionUpsellEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_11_14_extension_upsell_onboarding'),
  );
  const { extensionAvailableForBrowser } = useAvailableExtensions();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { marginBottom, isMobile } = useOnboardingStyles();
  const haveNoConnections = connections.data.length === 0;

  useEffect(() => {
    reportPapEvent(PAP_Shown_DashConnectAppPage({ inOnboardingFlow: true }));
  }, [reportPapEvent]);

  useEffect(() => {
    async function doFetchConnectors() {
      setConnectors((atom) => ({ ...atom, loading: true }));
      const connectors = await fetchConnectorsWithVisibilityFilters();
      setConnectors({
        data: connectors.map((c) => ({ ...c, loading: false })),
        loading: false,
        loaded: true,
      });
    }
    doFetchConnectors();

    async function doFetchConnections() {
      setConnections((atom) => ({ ...atom, loading: true }));
      const connections = await listConnections();
      setConnections({
        data: connections
          .map((c) => ({
            ...c,
            loading: false,
            syncing: false,
          }))
          .filter(filterBrowserExtensionConnections),
        loading: false,
        loaded: true,
      });
    }
    doFetchConnections();
  }, [setConnectors, setConnections]);

  const handleFinishOnboarding = useCallback(async () => {
    reportPapEvent(PAP_Open_DashConnectAppPageContinue());
    onStepFinish('complete');
  }, [onStepFinish, reportPapEvent]);

  const handleContinueButtonClick = useCallback(async () => {
    if (haveNoConnections) {
      // this case should not happen
      return;
    }

    /*
    Open the extension upsell page if
    - growthbook flag is enabled
    - This is a supported browser
    - The extension has already been installed
    */
    if (
      extensionUpsellEnabled &&
      extensionAvailableForBrowser &&
      !isExtensionInstalled()
    ) {
      onStepFinish('browser-extension');
      return;
    }

    handleFinishOnboarding();
  }, [
    extensionAvailableForBrowser,
    extensionUpsellEnabled,
    handleFinishOnboarding,
    haveNoConnections,
    onStepFinish,
  ]);

  const privacyLinkClicked = () => {
    setShowPrivacyModal(true);
    dispatchElementClicked(
      createUxaElementId('team_onboarding_privacy_link', {}),
    );
    reportPapEvent(PAP_Open_DashPrivacyModal());
  };

  const privacyModalClosed = () => {
    setShowPrivacyModal(false);
    reportPapEvent(PAP_Close_DashPrivacyModal());
  };

  const descriptionFooter = (
    <Text tagName="div" color="subtle" className={styles.textCentered}>
      <Trans
        i18nKey={'team_onboarding_privacy_prompt'}
        components={{
          pButton: (
            <Button
              className={classnames(
                styles.privacyLinkButton,
                styles.atlasGrotesk,
              )}
              variant="transparent"
              onClick={privacyLinkClicked}
            />
          ),
        }}
      />
    </Text>
  );

  const actionButtons = (
    <Button
      className={styles.continueButton}
      size="xlarge"
      variant="primary"
      onClick={handleContinueButtonClick}
      disabled={haveNoConnections}
    >
      {i18n.t('continue_button')}
    </Button>
  );

  return (
    <TeamOnboardingLayout
      title={i18n.t('team_onboarding_title')}
      description={i18n.t('team_onboarding_description')}
      descriptionFooter={descriptionFooter}
      actionButtons={actionButtons}
    >
      <TeamOnboardingConnectorList
        snackbarMarginBottom={marginBottom}
        isMobile={isMobile}
      />
      {showPrivacyModal && <PrivacyModal onClose={privacyModalClosed} />}
    </TeamOnboardingLayout>
  );
};
