import { Badge } from '@dropbox/dig-components/badge';
import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Checkbox } from '@dropbox/dig-components/controls';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import { AppsLine, ChevronDownLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import {
  focusResultAtIdx,
  focusSearchInput,
} from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { getConnectorNameForAttr } from '@mirage/shared/connectors';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { useMemo } from 'react';
import styles from './AppFilterDropdown.module.css';
import { FilterProps } from './types';

type Props = {
  connectorFilters?: FilterProps[];
  browserHistoryFilters?: FilterProps[];
  onDeselectAll: () => void;
  onOpenDropdown: () => void;
  onSelect: (connectorName: string) => void;
};

export const AppFilterDropdown = ({
  connectorFilters = [],
  browserHistoryFilters = [],
  onDeselectAll,
  onOpenDropdown,
  onSelect,
}: Props) => {
  const activeFilters = useMemo(() => {
    const filters = [...connectorFilters, ...browserHistoryFilters];
    return filters?.filter((f) => f.selected) ?? [];
  }, [connectorFilters, browserHistoryFilters]);

  const activeFilterCount = activeFilters.length;
  const activeFilterOverflowCount = activeFilters.length - 3;

  if (!connectorFilters.length && !browserHistoryFilters.length) {
    return null;
  }

  const buildFilterItem = (filter: FilterProps, index: number) => (
    <Menu.ActionItem
      className={styles.appFilterMenuItem}
      key={index}
      value={filter.selected}
      role="menuitemcheckbox"
      onClick={() => onSelect(filter.key)}
      onSubmit={() => onSelect(filter.key)}
      onKeyDown={(e: React.KeyboardEvent) => {
        switch (e.key) {
          case KeyCodes.arrowDown:
            focusResultAtIdx(0);
            break;
          case KeyCodes.arrowUp:
            focusSearchInput();
            break;
          case KeyCodes.enter:
          case KeyCodes.space:
            onSelect(filter.key);
            break;
        }
      }}
      aria-checked={filter.selected}
      data-testid={`FilterCheckbox-${getConnectorNameForAttr(
        (filter.label as string) || '',
      )}`}
      data-uxa-log={createUxaElementId(`filter_checkbox_${filter.key}`, {
        actionSurfaceComponent: 'filter_checkbox',
        featureLine: 'search',
      })}
      withLeftAccessory={
        <Checkbox checked={filter.selected} readOnly tabIndex={-1} />
      }
      withRightAccessory={<>{filter.icon}</>}
    >
      {filter.label}
    </Menu.ActionItem>
  );

  return (
    <Menu.Wrapper
      closeOnSelection={false}
      onToggle={({ isOpen }) => {
        if (isOpen) {
          onOpenDropdown();
        }
      }}
    >
      {({ getContentProps, getTriggerProps, closeMenu }) => (
        <>
          <Chip
            {...getTriggerProps()}
            isSelected={activeFilterCount > 0}
            selectedStyle="stroke"
            data-testid={'app-filter-chip'}
            aria-label={i18n.t('filter_bar_connector_dropdown_label')}
          >
            <Chip.Content>
              {activeFilterCount == 0 ? (
                <>
                  <Chip.IconAccessory>
                    <UIIcon src={AppsLine} />
                  </Chip.IconAccessory>
                  <Chip.Content>{i18n.t('filter_by_app')}</Chip.Content>
                </>
              ) : (
                <div className={styles.activeFilterIconContainer}>
                  {activeFilters.map((f) => f.icon).slice(0, 3)}
                  {activeFilterOverflowCount > 0 && (
                    <Badge>+ {activeFilterOverflowCount}</Badge>
                  )}
                </div>
              )}
            </Chip.Content>
            <Chip.IconAccessory>
              <UIIcon src={ChevronDownLine} />
            </Chip.IconAccessory>
          </Chip>

          <Menu.Content {...getContentProps()}>
            {connectorFilters.length > 0 && (
              <Menu.Segment
                withLabel={
                  <>
                    {i18n.t('filter_by')}
                    <Button
                      size="small"
                      variant="transparent"
                      onClick={() => {
                        closeMenu({});
                        onDeselectAll();
                      }}
                    >
                      {activeFilterCount > 0 && i18n.t('deselect_all')}
                    </Button>
                  </>
                }
              >
                {connectorFilters.map(buildFilterItem)}
              </Menu.Segment>
            )}
            {browserHistoryFilters.length > 0 && (
              <Menu.Segment withLabel={i18n.t('browser_history_filter_title')}>
                {browserHistoryFilters.map(buildFilterItem)}
              </Menu.Segment>
            )}
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
