import i18n from '@mirage/translations';
import * as React from 'react';
import WelcomeModalStepper, { WelcomeModalItem } from './WelcomeModalStepper';

const ANSWERS_IMAGE =
  'https://assets.dropbox.com/images/dashweb/welcome-modal-answers.png';
const HOME_PAGE_IMAGE =
  'https://assets.dropbox.com/images/dashweb/welcome-modal-home-page-desktop.png';
const STACKS_IMAGE =
  'https://assets.dropbox.com/images/dashweb/welcome-modal-stacks.png';
const WELCOME_IMAGE =
  'https://assets.dropbox.com/images/dashweb/welcome-modal-welcome.png';

const INFO_STEPS: WelcomeModalItem[] = [
  {
    image: WELCOME_IMAGE,
    title: i18n.t('welcome_modal_title_step_0'),
    message: i18n.t('welcome_modal_message_step_0'),
  },
  {
    image: HOME_PAGE_IMAGE,
    title: i18n.t('welcome_modal_title_step_1'),
    message: i18n.t('welcome_modal_message_step_1'),
  },
  {
    image: ANSWERS_IMAGE,
    title: i18n.t('welcome_modal_title_step_2'),
    message: i18n.t('welcome_modal_message_step_2'),
  },
  {
    image: STACKS_IMAGE,
    title: i18n.t('welcome_modal_title_step_3'),
    message: i18n.t('welcome_modal_message_step_3'),
  },
];

export default function DesktopWelcomeModalV2() {
  return <WelcomeModalStepper steps={INFO_STEPS} size="standard" />;
}
