import { IconButton } from '@dropbox/dig-components/buttons';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronLeftLine,
  CommentLine,
  EditLine,
} from '@dropbox/dig-icons/assets';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { RenderFooterSettings } from './SourcesFromSettingsModalStepper';
import styles from './SourcesFromSettingsTwo.module.css';

interface SourcesFromSettingsTwoProps {
  leftPaneContent: React.ReactNode;
  rightPaneContent: React.ReactNode;
  loading: boolean;
  onMount: () => void;
  handleBack?: () => void; // handleBack is derived from SourcesFromSettingsModalStepper
  renderFooter?: (footerSettings: RenderFooterSettings) => JSX.Element; // renderFooter is derived from SourcesFromSettingsModalStepper
}
export const SourcesFromSettingsTwo = memo(
  ({
    leftPaneContent,
    rightPaneContent,
    handleBack,
    renderFooter,
    onMount,
    loading,
  }: SourcesFromSettingsTwoProps) => {
    const isMobile = useIsMobileSize();
    const [isRightView, setIsRightView] = useState(false);
    // This needs to trigger only once when this step loads
    useEffect(() => {
      onMount();
    }, []); // eslint-disable-line
    return (
      <div
        className={classnames(
          styles.SourcesFromSettingsTwoContent,
          isMobile && styles.SourcesFromSettingsTwoMobile,
        )}
      >
        <div className={styles.SourcesFromSettingsTwoHeader}>
          <IconButton
            variant="borderless"
            shape="standard"
            size="medium"
            tone="neutral"
            onClick={handleBack}
            aria-label="Back"
          >
            <UIIcon src={ChevronLeftLine} />
          </IconButton>
          <Title size="medium" weightVariant="emphasized">
            {i18n.t('compose_settings_preview_label')}
          </Title>
        </div>
        <div className={styles.SourcesFromSettingsTwoSplitContent}>
          {isMobile ? (
            <>
              <div className={styles.SourcesFromSettingsTwoMobileContent}>
                {isRightView ? rightPaneContent : leftPaneContent}
                {isRightView && loading && (
                  <>
                    <div
                      className={styles.SourcesFromSettingsTwoLoadingSpinner}
                    >
                      <Spinner />
                    </div>
                    <div
                      className={styles.SourcesFromSettingsTwoLoadingOverlay}
                    />
                  </>
                )}
                {isRightView && renderFooter?.({ secondaryCta: 'skip' })}
              </div>
              <IconButton
                variant="borderless"
                shape="standard"
                size="medium"
                tone="neutral"
                onClick={() => setIsRightView(!isRightView)}
                aria-label="Back"
                className={styles.SourcesFromSettingsTwoMobileToggle}
              >
                <UIIcon src={isRightView ? CommentLine : EditLine} />
              </IconButton>
            </>
          ) : (
            <>
              {leftPaneContent}
              <div className={styles.SourcesFromSettingsRight}>
                {loading && (
                  <>
                    <div
                      className={styles.SourcesFromSettingsTwoLoadingSpinner}
                    >
                      <Spinner />
                    </div>
                    <div
                      className={styles.SourcesFromSettingsTwoLoadingOverlay}
                    />
                  </>
                )}
                {rightPaneContent}
                {renderFooter?.({
                  secondaryCta: 'skip',
                })}
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);
SourcesFromSettingsTwo.displayName = 'SourcesFromSettingsTwo';
