// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/doc_summarization.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Empty, Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from message context_engine.DocumentId
 */
export class DocumentId extends Message<DocumentId> {
  /**
   * @generated from oneof context_engine.DocumentId.id_type
   */
  idType:
    | {
        /**
         * @generated from field: string url = 1;
         */
        value: string;
        case: 'url';
      }
    | {
        /**
         * @generated from field: string uuid = 2;
         */
        value: string;
        case: 'uuid';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DocumentId>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DocumentId';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'url',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'id_type',
    },
    {
      no: 2,
      name: 'uuid',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'id_type',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DocumentId {
    return new DocumentId().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DocumentId {
    return new DocumentId().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DocumentId {
    return new DocumentId().fromJsonString(jsonString, options);
  }

  static equals(
    a: DocumentId | PlainMessage<DocumentId> | undefined,
    b: DocumentId | PlainMessage<DocumentId> | undefined,
  ): boolean {
    return proto3.util.equals(DocumentId, a, b);
  }
}

/**
 * @generated from message context_engine.DocSummarizationRequest
 */
export class DocSummarizationRequest extends Message<DocSummarizationRequest> {
  /**
   * @generated from field: context_engine.DocumentId id = 1;
   */
  id?: DocumentId;

  /**
   * @generated from field: string template = 2;
   */
  template = '';

  /**
   * @generated from field: string model_name = 3;
   */
  modelName = '';

  /**
   * 'True' indicates using map reduce
   *
   * @generated from field: string use_mapreduce = 4;
   */
  useMapreduce = '';

  /**
   * @generated from field: map<string, int32> mapreduce_configs = 5;
   */
  mapreduceConfigs: { [key: string]: number } = {};

  /**
   * @generated from field: string setting = 6;
   */
  setting = '';

  /**
   * @generated from field: string file_type = 7;
   */
  fileType = '';

  /**
   * @generated from field: string connector_name = 8;
   */
  connectorName = '';

  constructor(data?: PartialMessage<DocSummarizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DocSummarizationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'message', T: DocumentId },
    { no: 2, name: 'template', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'model_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 4,
      name: 'use_mapreduce',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 5,
      name: 'mapreduce_configs',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    },
    { no: 6, name: 'setting', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'file_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 8,
      name: 'connector_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DocSummarizationRequest {
    return new DocSummarizationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DocSummarizationRequest {
    return new DocSummarizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DocSummarizationRequest {
    return new DocSummarizationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DocSummarizationRequest
      | PlainMessage<DocSummarizationRequest>
      | undefined,
    b:
      | DocSummarizationRequest
      | PlainMessage<DocSummarizationRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(DocSummarizationRequest, a, b);
  }
}

/**
 * @generated from message context_engine.DocSummarizationResponse
 */
export class DocSummarizationResponse extends Message<DocSummarizationResponse> {
  /**
   * @generated from field: string summary = 1;
   */
  summary = '';

  /**
   * @generated from field: string request_id = 2;
   */
  requestId = '';

  constructor(data?: PartialMessage<DocSummarizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DocSummarizationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'summary', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'request_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DocSummarizationResponse {
    return new DocSummarizationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DocSummarizationResponse {
    return new DocSummarizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DocSummarizationResponse {
    return new DocSummarizationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DocSummarizationResponse
      | PlainMessage<DocSummarizationResponse>
      | undefined,
    b:
      | DocSummarizationResponse
      | PlainMessage<DocSummarizationResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(DocSummarizationResponse, a, b);
  }
}

/**
 * @generated from message context_engine.DocQnaRequest
 */
export class DocQnaRequest extends Message<DocQnaRequest> {
  /**
   * @generated from field: context_engine.DocumentId id = 1;
   */
  id?: DocumentId;

  /**
   * @generated from field: string question = 2;
   */
  question = '';

  /**
   * @generated from field: string template = 3;
   */
  template = '';

  /**
   * @generated from field: string model_name = 4;
   */
  modelName = '';

  /**
   * @generated from field: int32 topk_RAG = 5;
   */
  topkRAG = 0;

  /**
   * @generated from field: string setting = 6;
   */
  setting = '';

  /**
   * @generated from field: string file_type = 7;
   */
  fileType = '';

  /**
   * @generated from field: string connector_name = 8;
   */
  connectorName = '';

  constructor(data?: PartialMessage<DocQnaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DocQnaRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'message', T: DocumentId },
    { no: 2, name: 'question', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'template', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'model_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'topk_RAG', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'setting', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'file_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 8,
      name: 'connector_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DocQnaRequest {
    return new DocQnaRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DocQnaRequest {
    return new DocQnaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DocQnaRequest {
    return new DocQnaRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DocQnaRequest | PlainMessage<DocQnaRequest> | undefined,
    b: DocQnaRequest | PlainMessage<DocQnaRequest> | undefined,
  ): boolean {
    return proto3.util.equals(DocQnaRequest, a, b);
  }
}

/**
 * @generated from message context_engine.DocQnaResponse
 */
export class DocQnaResponse extends Message<DocQnaResponse> {
  /**
   * @generated from field: string answer = 1;
   */
  answer = '';

  /**
   * @generated from field: string request_id = 2;
   */
  requestId = '';

  constructor(data?: PartialMessage<DocQnaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DocQnaResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'answer', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'request_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DocQnaResponse {
    return new DocQnaResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DocQnaResponse {
    return new DocQnaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DocQnaResponse {
    return new DocQnaResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DocQnaResponse | PlainMessage<DocQnaResponse> | undefined,
    b: DocQnaResponse | PlainMessage<DocQnaResponse> | undefined,
  ): boolean {
    return proto3.util.equals(DocQnaResponse, a, b);
  }
}

/**
 * @generated from message context_engine.DocSummarizationError
 */
export class DocSummarizationError extends Message<DocSummarizationError> {
  /**
   * @generated from oneof context_engine.DocSummarizationError.err
   */
  err:
    | {
        /**
         * Indicates that a required argument was not provided.
         *
         * @generated from field: google.protobuf.Empty missing_required_args = 1;
         */
        value: Empty;
        case: 'missingRequiredArgs';
      }
    | {
        /**
         * Indicates that an argument was provided that is not allowed.
         *
         * @generated from field: google.protobuf.Empty invalid_args = 2;
         */
        value: Empty;
        case: 'invalidArgs';
      }
    | {
        /**
         * Indicates that the request was not processed due to an internal error.
         *
         * @generated from field: google.protobuf.Empty internal_failure = 3;
         */
        value: Empty;
        case: 'internalFailure';
      }
    | {
        /**
         * Indicates that there was an error getting the file metadata.
         *
         * @generated from field: google.protobuf.Empty failed_to_get_file_metadata = 4;
         */
        value: Empty;
        case: 'failedToGetFileMetadata';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DocSummarizationError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DocSummarizationError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'missing_required_args',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 2, name: 'invalid_args', kind: 'message', T: Empty, oneof: 'err' },
    {
      no: 3,
      name: 'internal_failure',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    {
      no: 4,
      name: 'failed_to_get_file_metadata',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DocSummarizationError {
    return new DocSummarizationError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DocSummarizationError {
    return new DocSummarizationError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DocSummarizationError {
    return new DocSummarizationError().fromJsonString(jsonString, options);
  }

  static equals(
    a: DocSummarizationError | PlainMessage<DocSummarizationError> | undefined,
    b: DocSummarizationError | PlainMessage<DocSummarizationError> | undefined,
  ): boolean {
    return proto3.util.equals(DocSummarizationError, a, b);
  }
}
