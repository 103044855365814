import { useEffect } from "react";

import { FullStory, init, isInitialized } from "@fullstory/browser";
import useDropboxAccount, {
  useIsDropboxer,
} from "@mirage/service-auth/useDropboxAccount";
import { useFeatureFlagValue } from "@mirage/service-experimentation/useFeatureFlagValue";
import { useIsDfbUser } from "@mirage/shared/hooks/useIsDfbUser";
import { privacyCategoryStateAtom } from "@mirage/shared/privacy";
import {
  isDevBuildChannel,
  isProdBuildChannel,
} from "@mirage/shared/util/tiny-utils";
import { useAtomValue } from "jotai";

import { Config } from "../shared/config";

export const ORG_ID = "o-1WQPBD-na1";

const enableOnDev = false;
const initializeFullStory = (
  publicUserAccountId: string | undefined,
  publicUserTeamId: string | undefined,
  isDfbUser: boolean,
  isDropboxer: boolean,
  userAcceptedAllCookies: boolean,
  userAcceptedAnalytics: boolean,
  userInteracted: boolean,
) => {
  const isNonProd = !isProdBuildChannel(Config.BUILD_CHANNEL);
  const loadAsDebugMode =
    isDevBuildChannel(Config.BUILD_CHANNEL) && enableOnDev;
  // The user must have intentionally accepted Analytics or All Cookies in order to initialize FullStory
  // Since consent form isn't available in dev, we rely on growthbook gating for dev environments
  // to selectively enable FullStory for developing & testing
  const hasUserConsent =
    (userInteracted && (userAcceptedAllCookies || userAcceptedAnalytics)) ||
    loadAsDebugMode;

  if (!hasUserConsent) {
    shutdownFullStory();
    return;
  }

  if (isInitialized()) {
    // If FullStory was previously initialized before being disabled for any reason, it must be restarted rather than
    // re-initialized
    FullStory("restart");
    return;
  }

  // Use the debug script when running in a non-production environment to avoid polluting sessions with dev data
  const host = window.location.host;
  // For testing in devProd, following needs to happen

  //  1. add your account / test account to growthbook gating (dash_fullstory https://app.dropboxexperiment.com/features/dash_fullstory) for "devbox" rules
  //  2. comment out `script` param in init below which loads external version of fs.js. As they are adding "https://" to the script url, it's not working in localhost
  //  3. comment out `devMode` param in init below
  //  4. make sure that your account will be gated on growthbook `dash_fullstory` feature's devbox setup
  //  5. make sure that fullstory recording is enabled for localhost domain
  //  6. toggle `enableOnDev` value to `true` on this file
  init({
    orgId: ORG_ID,
    script: host + (isNonProd ? "/fs-debug.js" : "/fs.js"),
    debug: loadAsDebugMode,
    devMode: loadAsDebugMode,
  });
  if (publicUserAccountId) {
    FullStory("setIdentity", {
      uid: publicUserAccountId,
      properties: {
        teamId: publicUserTeamId,
        isDfbUser,
        isDropboxer,
      },
    });
  }
};

const shutdownFullStory = () => {
  if (isInitialized()) {
    FullStory("shutdown");
  }

  return;
};

export const useFullstory = () => {
  const account = useDropboxAccount();
  const isDropboxer = useIsDropboxer();
  const isDfbUser = useIsDfbUser();
  const privacyCategoryState = useAtomValue(privacyCategoryStateAtom);
  const isFullstoryEnabled = useFeatureFlagValue("dash_fullstory");

  useEffect(() => {
    if (
      account &&
      privacyCategoryState &&
      isFullstoryEnabled &&
      isDropboxer !== undefined &&
      isDfbUser !== undefined
    ) {
      initializeFullStory(
        account?.public_account_id && atob(account.public_account_id),
        account?.team?.public_team_id && atob(account.team?.public_team_id),
        isDfbUser,
        isDropboxer,
        privacyCategoryState.all,
        privacyCategoryState.analytics,
        privacyCategoryState.userInteracted,
      );
    }
  }, [
    privacyCategoryState,
    account,
    isFullstoryEnabled,
    isDfbUser,
    isDropboxer,
  ]);
};
