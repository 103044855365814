import * as React from "react";
import { atoms } from "@dropbox/dig-foundations";
import classNames from "classnames";
import css from "./Icon.module.css";

import { MetadataContext } from "./context";
export interface ArbitraryIconProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * The icon to display, should be an SVG or img element
   */
  src: React.ReactNode;
  children?: never;
}
const atomStyles = atoms({ flexBasis: "auto", flexGrow: 0, flexShrink: 0 });

/**
 * This exists to attempt to apply sizing to a generic svg or img icon passed into the `src` prop.
 */
export const _ArbitraryIcon: React.FC<ArbitraryIconProps> = ({
  className,
  src,
  ...props
}) => {
  const { size } = React.useContext(MetadataContext);
  const cls = classNames(atomStyles, className, css.iconRoot, {
    [css.iconSmall]: size === "small",
    [css.iconMedium]: size === "medium",
  });
  return (
    <span {...props} className={cls}>
      {src}
    </span>
  );
};
