import { stacks } from '@dropbox/api-v2-client';
import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Select, TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { SearchLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_StacksAction } from '@mirage/analytics/events/types/select_stacks_action';
import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { listPublishedContent, publishStack } from '@mirage/service-stacks';
import { showSnackbar } from '@mirage/shared/snackbar';
import { convertStackToWorkingSetCardData } from '@mirage/stacks/AllStacksGrid/AllStacks';
import {
  isStackOwner,
  stackIsMatch,
  stackIsPublished,
} from '@mirage/stacks/Helpers/Utils';
import {
  publishedContentsAtom,
  useStacksByPredicate,
} from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import { WorkingSetCardData } from '@mirage/working-set';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { AddStackList, RemoveStackList } from './PublishedContentModalList';
import styles from './PublishedContentPage.module.css';

type PublishContentModalProps = {
  open: boolean;
  onClose: () => void;
};

export const PublishContentModal = ({
  open,
  onClose,
}: PublishContentModalProps) => {
  const [inputString, setInputString] = useState('');
  const augustRevision = useAllStacksAugustRevisionEnabled();
  const currentAccount = useDropboxAccount();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const [selectedWorkingSetStack, setSelectedWorkingSetStack] = useState<
    WorkingSetCardData | undefined
  >(undefined);

  const [publishedContents, setPublishedContents] = useAtom(
    publishedContentsAtom,
  );

  const teamName = currentAccount?.team?.name;

  // List only owner stacks
  const { stacks, stackItemsMap } = useStacksByPredicate((stack) => {
    const userCanPublishThisStack =
      isStackOwner(stack) && !stackIsPublished(stack, publishedContents);
    return inputString.trim()
      ? stackIsMatch(stack, inputString) && userCanPublishThisStack
      : userCanPublishThisStack;
  });

  const convertStacksToWorkingSetCardData = useCallback(
    (stacks: stacks.Stack[]) => {
      return stacks.map((stack: stacks.Stack) => {
        return convertStackToWorkingSetCardData(
          stack,
          stacks,
          () => {},
          stackItemsMap,
          undefined,
          currentAccount,
          augustRevision,
          undefined,
          undefined,
        );
      });
    },
    [augustRevision, currentAccount, stackItemsMap],
  );

  const workingSetCardData = useMemo((): WorkingSetCardData[] => {
    const stacksToList = stacks ?? [];
    return convertStacksToWorkingSetCardData(stacksToList);
  }, [convertStacksToWorkingSetCardData, stacks]);

  const onPublish = useCallback(async () => {
    if (!selectedWorkingSetStack) {
      return;
    }

    const stackToPublish = selectedWorkingSetStack;
    setSelectedWorkingSetStack(undefined);
    onClose();
    showSnackbar({
      title: i18n.t('publish_stack_inprogress', {
        stackName: stackToPublish.title,
      }),
      timeoutMs: 0,
    });

    try {
      await publishStack(stackToPublish.id);
      const listResponse = await listPublishedContent();
      setPublishedContents(listResponse || []);
      showSnackbar({
        title: i18n.t('publish_stack_success', {
          stackName: stackToPublish.title,
        }),
      });
      reportPapEvent(
        PAP_Select_StacksAction({
          ...stackToPublish.stackDerivedPAPProps,
          stacksAction: 'publish_dash_stack',
          dashActionSurface: 'published_content_page',
          featureLine: 'stacks',
          actionSurfaceComponent: 'published_content',
        }),
      );
    } catch {
      showSnackbar({
        title: i18n.t('publish_stack_failure', {
          stackName: selectedWorkingSetStack.title,
        }),
      });
    }
  }, [selectedWorkingSetStack]);

  return (
    <Modal open={open} onRequestClose={onClose} withCloseButton="Close">
      <Modal.Header>
        <Modal.Title>{t('publish_button_text')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.modalSubtitle}>
          <Text variant="paragraph">
            {i18n.t('publish_content_modal_subtitle')}
          </Text>
          <Text size="small" variant="label" isBold>
            {i18n.t('add_a_stack')}
          </Text>
        </div>

        <div className={styles.modalContentContainerDiv}>
          <div className={styles.modalContentContainer}>
            {selectedWorkingSetStack ? (
              <RemoveStackList
                selectedWorkingSetStack={selectedWorkingSetStack}
                onSelectStack={() => {
                  setInputString('');
                  setSelectedWorkingSetStack(undefined);
                }}
              />
            ) : (
              <>
                <div className={styles.searchInputContainer}>
                  <TextInput
                    placeholder={t('search_stacks')}
                    value={inputString}
                    withLeftAccessory={<UIIcon src={SearchLine} />}
                    onChange={(e) => {
                      setInputString(e.currentTarget.value);
                    }}
                  />
                </div>
                {workingSetCardData.length > 0 && (
                  <AddStackList
                    workingSetCardData={workingSetCardData}
                    setSelectedWorkingSetStack={setSelectedWorkingSetStack}
                  />
                )}
              </>
            )}
          </div>
          <div className={styles.modalPermissions}>
            <Text size="small" variant="label" isBold>
              {i18n.t('permissions')}
            </Text>
            <Select
              id="publish-stack-permission"
              aria-label={i18n.t('permissions')}
              value={'0'}
              disabled
              size="standard"
              default
            >
              <Select.Option value="0">
                {i18n.t('company_stack_team_permission_description', {
                  team: teamName,
                })}
              </Select.Option>
            </Select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onClose}>
          {i18n.t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={onPublish}
          disabled={!selectedWorkingSetStack}
        >
          {i18n.t('publish_button_text')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
