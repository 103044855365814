import { useWindowSize } from '@react-hookz/web';

const CONNECTORS_GAP = 24;
const BUTTON_HEIGHT = 90;

export const useOnboardingStyles = () => {
  // Use specific width for isMobile check compared to `useIsMobileSize` because
  // we need a width that toggles two vs one columns
  const isMobile = useWindowSize().width < 850;

  return {
    isMobile,
    marginBottom: BUTTON_HEIGHT,
    buttonHeight: CONNECTORS_GAP,
  };
};
