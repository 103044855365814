import { asterixOut, transformJsonResponse } from "@alwaysmeticulous/redaction";
import { RecorderMiddleware } from "@alwaysmeticulous/sdk-bundles-api";
import { stacks } from "@dropbox/api-v2-client";
import { StoredRecentContent } from "@mirage/service-recent-content/service";
import { RecentContent } from "@mirage/service-recent-content/types";

import {
  DEFAULT_REDACTED_URL,
  createRedactedApiV2Endpoint,
  redactArray,
} from "../utils";

export const redactRecentContent = <T extends RecentContent>(
  recentContent: T,
): T => {
  return {
    ...recentContent,
    url: recentContent.url ? DEFAULT_REDACTED_URL : undefined,
    title: recentContent.title ? asterixOut(recentContent.title) : undefined,
  };
};

export const redactRecentActivity = (
  recentActivity: stacks.RecentActivity,
): stacks.RecentActivity => {
  if (recentActivity[".tag"] === "webpage_visit") {
    return {
      ...recentActivity,
      page_title: asterixOut(recentActivity.page_title ?? ""),
      url: DEFAULT_REDACTED_URL,
    };
  }
  return recentActivity;
};

export const stacksGetRecentActivityRedacted =
  createRedactedApiV2Endpoint<StoredRecentContent>(
    "stacksGetRecentActivity",
    "stacks",
    "get_recent_activity",
    "recent-content",
    (data: StoredRecentContent): StoredRecentContent => {
      return {
        ...data,
        browserHistoryV3: data.browserHistoryV3.map((entry) =>
          redactRecentContent(entry),
        ),
        recentContentV3: data.recentContentV3.map((entry) =>
          redactRecentContent(entry),
        ),
        recentContentV4: data.recentContentV4.map((entry) =>
          redactRecentContent(entry),
        ),
      };
    },
    (): RecorderMiddleware => {
      return transformJsonResponse<stacks.GetRecentActivityResponse>({
        urlRegExp: stacksGetRecentActivityRedacted.urlRegExp,
        transform: (data) => ({
          ...data,
          recent_activities: redactArray(
            data?.recent_activities,
            redactRecentActivity,
          ),
        }),
      });
    },
  );
