import { RecorderMiddleware } from "@alwaysmeticulous/sdk-bundles-api";

import { IDBEntry, Redactor, RedactedEndpoint } from "./types";

export const DEFAULT_REDACTED_URL = "https://redacted.example";
export const DEFAULT_REDACTED_NAME = "John Doe";

export const isMeticulousEnabled = (): boolean => {
  return Boolean(window?.DASH_ENABLE_METICULOUS);
};

export const createRedactedApiV2Endpoint = <T>(
  name: string,
  api: string,
  endpoint: string,
  indexedDbKey: string,
  indexedDbRedactor: (data: T) => T,
  createMiddleware: () => RecorderMiddleware,
): RedactedEndpoint<T> => {
  const finalString = api + "/" + endpoint;
  const urlRegExp = new RegExp(finalString);

  return {
    name,
    api,
    endpoint,
    returnType: {} as T,
    indexedDbKey,
    indexedDbRedactor,
    createMiddleware,
    urlRegExp,
  };
};
export function redactArray<T>(
  array: T[] | undefined,
  redactFunction: (item: T) => T,
): T[] | undefined {
  return array?.map(redactFunction);
}

export const redactIfJson = <T>(
  json: string,
  transformation: (value: T) => T,
): string => {
  let parsed: T;
  try {
    parsed = JSON.parse(json ?? "");
  } catch {
    return json;
  }

  const transformed = transformation(parsed);
  return JSON.stringify(transformed);
};

export const redactIndexedDBEntry = <T>(
  entry: IDBEntry,
  targetKey: string,
  redactor: Redactor<T>,
): IDBEntry => {
  if (entry.key === targetKey) {
    if (typeof entry.value !== "string") {
      console.warn(`Unexpected non-string value for ${targetKey} in IndexedDB`);
      return entry;
    }

    return {
      ...entry,
      value: redactIfJson<T>(entry.value, redactor),
    };
  }
  return entry;
};
