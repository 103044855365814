import { useResizeObserver } from '@react-hookz/web';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export const useDynamicExpandableContent = (
  queryForCard: string,
  isActive: boolean = true,
) => {
  const [expanded, setExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [numVisible, setNumVisible] = useState(0);
  const expandedHeight = useRef<number>(0);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useResizeObserver(
    wrapperRef,
    useCallback(() => {
      if (!isActive) {
        return;
      }

      updateDynamicExpandableContent(
        wrapperRef,
        expandedHeight,
        setShowMore,
        expanded,
        setExpanded,
        queryForCard,
        setNumVisible,
      );
    }, [expanded, queryForCard, isActive]),
  );

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const mutationObserver = new MutationObserver(() => {
      if (!isActive) {
        return;
      }

      updateDynamicMutationContent(
        wrapperRef,
        setShowMore,
        expanded,
        queryForCard,
        setNumVisible,
        expandedHeight,
      );
    });

    mutationObserver.observe(wrapperRef.current, { childList: true });

    return () => {
      mutationObserver.disconnect();
    };
  }, [expanded, isActive, queryForCard, setShowMore, wrapperRef]);

  return {
    expanded,
    setExpanded,
    showMore,
    setShowMore,
    numVisible,
    wrapperRef,
  };
};

// Exported for testing only
export function updateDynamicMutationContent(
  wrapperRef: MutableRefObject<HTMLDivElement | null>,
  setShowMore: Dispatch<SetStateAction<boolean>>,
  expanded: boolean,
  queryForCard: string,
  setNumVisible: Dispatch<SetStateAction<number>>,
  expandedHeight: MutableRefObject<number>,
) {
  const scrollHeight = wrapperRef.current?.scrollHeight || 0;
  const height = wrapperRef.current?.clientHeight || 0;

  // Show more button if content height is greater than wrapper height
  if (scrollHeight > height) {
    setShowMore(true);
  } else if (scrollHeight <= expandedHeight.current) {
    // only hide show more if we no longer have more than 1 row of content
    // this will capture the collapse state, whereas the below block
    // captures the expansion state
    setShowMore(false);
  }

  const visible = computeVisibleCards(
    wrapperRef,
    queryForCard,
    expanded,
    height,
  );
  if (visible !== undefined) {
    setNumVisible(visible);
  }
}

// Exported for testing only
export function updateDynamicExpandableContent(
  wrapper: MutableRefObject<HTMLDivElement | null>,
  expandedHeight: MutableRefObject<number>,
  setShowMore: Dispatch<SetStateAction<boolean>>,
  expanded: boolean,
  setExpanded: Dispatch<SetStateAction<boolean>>,
  queryForCard: string,
  setNumVisible: Dispatch<SetStateAction<number>>,
) {
  if (!wrapper.current) {
    return;
  }

  const { scrollHeight, clientHeight: height } = wrapper.current;

  // Show more button if content height is greater than wrapper height
  if (scrollHeight > height) {
    setShowMore(true);
  } else if (scrollHeight <= expandedHeight.current) {
    // only hide show more if we no longer have more than 1 row of content
    // this will capture the collapse state, whereas the below block
    // captures the expansion state
    setShowMore(false);
  }

  if (!expanded) {
    // Store some height > 1 row of cards, but < 2 rows.
    // 2 * 1 row height gives us that, because it excludes the
    // row gap value
    expandedHeight.current = 2 * height;
  }

  // Check if we're already showing everything on one row.
  // If so, hide the more button and toggle expanded to false
  // to remove the empty 2nd row
  else if (expandedHeight.current > scrollHeight) {
    setShowMore(false);
    setExpanded(false);
  }

  // Calculate how many are now visible
  const visible = computeVisibleCards(wrapper, queryForCard, expanded, height);
  if (visible !== undefined) {
    setNumVisible(visible);
  }
}

export function computeVisibleCards(
  wrapper: MutableRefObject<HTMLDivElement | null>,
  queryForCard: string,
  expanded: boolean,
  height: number,
) {
  if (!wrapper.current) {
    return;
  }

  const cards = wrapper.current.querySelectorAll(
    queryForCard,
  ) as NodeListOf<HTMLDivElement>;

  if (cards) {
    let visible = 0;
    cards.forEach((card) => {
      // Conditionally add the height of the card only when we are entering a
      // collapse state. This allows the visibility to be adjusted as soon as
      // any animation starts, rather than waiting for the animation to finish.
      const offsetHeight = expanded ? 0 : card.offsetHeight;

      if (card.offsetTop + offsetHeight <= height) {
        visible++;
      }
    });
    return visible;
  }
}
