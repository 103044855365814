import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { DashThemeProvider } from '@mirage/service-settings/theming/DashThemeProvider';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import { cloneElement, ReactNode, useCallback, useState } from 'react';
import styles from './SourcesFromSettingsModalStepper.module.css';

interface SourcesFromSettingsModalStepperProps {
  steps: ReactNode[];
  widths: number[];
  isOpen: boolean;
  onRequestClose: () => void;
  onDone: () => void;
  onDelete: (e: React.MouseEvent) => void;
}
export interface RenderFooterSettings {
  sourceCount?: number;
  secondaryCta?: 'skip' | 'delete';
}
export const SourcesFromSettingsModalStepper = ({
  steps,
  widths,
  isOpen,
  onRequestClose,
  onDone,
  onDelete,
}: SourcesFromSettingsModalStepperProps) => {
  const isMobile = useIsMobileSize();
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = steps.length;
  const handleNext = useCallback(() => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else {
      onRequestClose();
    }
  }, [currentStep, totalSteps, onRequestClose]);
  const handleBack = useCallback(() => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep]);
  const renderFooter = useCallback(
    ({ sourceCount, secondaryCta }: RenderFooterSettings) => (
      <div className={styles.SourcesFromSettingsModalStepperFooter}>
        <Text
          size="medium"
          color="subtle"
          className={styles.SourcesFromSettingsModalStepperLabel}
        >
          {i18n.t('compose_tones_pane_steps', {
            current: currentStep,
            total: totalSteps,
          })}
        </Text>
        <div>
          {sourceCount !== undefined && ( // count 0 is valid
            <Text
              size="small"
              color="subtle"
              className={styles.SourcesFromSettingsModalStepperFooterCount}
            >
              {i18n.t('compose_tones_pane_sources_count', {
                count: sourceCount,
                plural: sourceCount === 1 ? '' : 's',
              })}
            </Text>
          )}
          {secondaryCta === 'skip' && (
            <Button
              variant="opacity"
              size="medium"
              tone="neutral"
              onClick={handleNext}
              className={styles.SourcesFromSettingsModalStepperFooterCta}
            >
              {i18n.t('compose_tones_pane_skip')}
            </Button>
          )}
          {secondaryCta === 'delete' && (
            <Button
              variant="opacity"
              size="medium"
              tone="destructive"
              onClick={onDelete}
              className={styles.SourcesFromSettingsModalStepperFooterCta}
            >
              {i18n.t('compose_tones_pane_delete')}
            </Button>
          )}
          <Button
            size="medium"
            variant="primary"
            disabled={sourceCount === 0}
            onClick={currentStep < totalSteps ? handleNext : onDone}
          >
            {currentStep < totalSteps
              ? i18n.t('compose_tones_pane_next')
              : i18n.t('compose_tones_pane_save')}
          </Button>
        </div>
      </div>
    ),
    [currentStep, totalSteps, handleNext, onDone, onDelete],
  );
  return (
    <Modal
      open={isOpen}
      width={widths[currentStep - 1]}
      isCentered
      withCloseButton={isMobile && currentStep === 2 ? undefined : 'close'}
      className={styles.SourcesFromSettingsModalStepperWindow}
      onRequestClose={onRequestClose}
    >
      <DashThemeProvider>
        <Modal.Body className={styles.SourcesFromSettingsModalStepperBody}>
          {steps[currentStep - 1] &&
            cloneElement(steps[currentStep - 1] as React.ReactElement, {
              renderFooter,
              handleBack,
              handleNext,
            })}
        </Modal.Body>
      </DashThemeProvider>
    </Modal>
  );
};
SourcesFromSettingsModalStepper.displayName = 'SourcesFromSettingsModalStepper';
