import { LabelGroup } from '@dropbox/dash-component-library';
import { Badge } from '@dropbox/dig-components/badge';
import { Box, Split } from '@dropbox/dig-foundations';
import { DashCard } from '@mirage/dash-component-library/components/DashCard';
import classNames from 'classnames';
import React from 'react';
import styles from './ContentRow.module.css';
import { ContentRowProps } from './types';
import {
  convertPaddingSizeToBreakoutSize,
  convertPaddingSizeToPaddingX,
} from './utils';

export const ContentRow: React.FC<ContentRowProps> = ({
  showBadge,
  withLabel,
  withMeta,
  withStartAccessory,
  withActions,
  labelGroupProps,
  paddingSize = 'large',
  onClick,
  visibleUrl,
  isMobile = false,
}) => {
  const breakoutSize = convertPaddingSizeToBreakoutSize(paddingSize);
  const paddingX = convertPaddingSizeToPaddingX(paddingSize);

  return (
    <DashCard breakout={breakoutSize} isLink={!!onClick}>
      <Split
        direction={isMobile ? 'vertical' : 'horizontal'}
        alignY="center"
        alignX="center"
      >
        <Split.Item
          className={styles.mainContent}
          width="fill"
          paddingX={paddingX}
        >
          {showBadge && (
            <Badge
              size="xsmall"
              variant="attention"
              className={styles.unreadBadge}
            />
          )}
          {onClick ? (
            <DashCard.OverlayLink onClick={onClick} href={visibleUrl ?? '#'}>
              <LabelGroup
                verticalAlignment="center"
                accessoryGap="Micro XLarge"
                className={styles.label}
                {...{
                  ...labelGroupProps,
                  withLabel,
                  withMeta,
                  withStartAccessory,
                }}
              />
            </DashCard.OverlayLink>
          ) : (
            <LabelGroup
              verticalAlignment="center"
              accessoryGap="Micro XLarge"
              className={styles.label}
              {...{
                ...labelGroupProps,
                withLabel,
                withMeta,
                withStartAccessory,
              }}
            />
          )}
        </Split.Item>
        <Split.Item>
          {withActions && (
            <Box
              className={classNames(styles.actions, {
                [styles.mobileActions]: isMobile,
              })}
            >
              {withActions}
            </Box>
          )}
        </Split.Item>
      </Split>
    </DashCard>
  );
};
