import { IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine, SendLine } from '@dropbox/dig-icons/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Send_ComposePrompt } from '@mirage/analytics/events/types/send_compose_prompt';
import {
  ComposeAssistantConversationMessage,
  ComposeSource,
  InputContext,
} from '@mirage/shared/compose/compose-session';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './ConversationInput.module.css';

interface ConversationInputProps {
  variant?: 'default' | 'condensed' | 'minimal';
  sources: ComposeSource[];
  inputContext?: InputContext;
  setInputContext?: (inputContext: InputContext | undefined) => void;
  onSubmit: (text: string) => void;
  onFocus?: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
  handleClickAdd?: () => void;
  messages: ComposeAssistantConversationMessage[];
  showAddSourcesButton?: boolean;
}
export const ConversationInput = memo(
  ({
    variant = 'default',
    sources,
    inputContext,
    setInputContext,
    onSubmit,
    onFocus,
    logComposeEvent,
    handleClickAdd,
    messages,
    showAddSourcesButton = true,
  }: ConversationInputProps) => {
    const textInput = useRef<HTMLTextAreaElement>(null);
    const shouldAutoFocusTextArea = useCallback(() => {
      const lastMessage = messages[messages.length - 1];
      const hasNoInstructionMessages =
        messages.filter((message) => message.type !== 'instruction').length ===
        0;
      const isTypeMessage = lastMessage && lastMessage.type === 'message';
      const isWritePrompt =
        isTypeMessage &&
        lastMessage.text.includes(
          i18n.t('compose_assistant_message_partial_match'),
        );
      const isGenericPrompt =
        isTypeMessage &&
        lastMessage.text.includes(i18n.t('compose_assistant_message_generic'));
      const isDoneMessage =
        isTypeMessage && lastMessage.actionContext?.type === 'done';
      const lastMessageIsGenericOrPartialMatch =
        isWritePrompt || isGenericPrompt || isDoneMessage;
      const shouldAutoFocus =
        hasNoInstructionMessages || lastMessageIsGenericOrPartialMatch;
      return textInput.current && shouldAutoFocus;
    }, [messages]);
    useEffect(() => {
      if (shouldAutoFocusTextArea()) {
        textInput?.current?.focus();
      }
    }, [shouldAutoFocusTextArea, sources]);
    const [promptText, setPromptText] = useState('');
    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> =
      useCallback(
        (event) => {
          if (event.key === KeyCodes.enter && promptText.trim().length > 0) {
            if (!event.shiftKey) {
              // shift-enter should create newline as normal
              event.preventDefault();
              onSubmit(promptText);
              setPromptText('');
              logComposeEvent(
                PAP_Send_ComposePrompt({
                  queryString: promptText,
                  itemCount: messages.length,
                  queryLength: promptText.length,
                }),
              );
            }
          }
          if (event.key === 'Backspace' || event.key === 'Delete') {
            if (promptText.length === 0) {
              event.preventDefault();
              setInputContext?.(undefined);
            }
          }
        },
        [
          onSubmit,
          promptText,
          setInputContext,
          logComposeEvent,
          messages.length,
        ],
      );
    const handleTextAreaChange: React.FormEventHandler<HTMLTextAreaElement> =
      useCallback((event) => {
        event.preventDefault();
        setPromptText(event.currentTarget.value);
      }, []);
    const handleSubmit: React.MouseEventHandler = useCallback(
      (event) => {
        event.preventDefault();
        if (promptText.length > 0) {
          onSubmit(promptText);
          setPromptText('');
        }
      },
      [onSubmit, promptText],
    );
    let placeholderText = i18n.t('compose_prompt_dash_placeholder');
    if (sources.length) {
      placeholderText = i18n.t(
        'compose_prompt_dash_placeholder_chat_with_sources',
        {
          count: sources.length,
          plural: sources.length > 1 ? 's' : '',
        },
      );
    } else if (messages.length !== 0) {
      placeholderText = i18n.t('compose_prompt_dash_placeholder_chat');
    }
    if (variant === 'minimal') {
      placeholderText = i18n.t('compose_prompt_dash_placeholder_minimal');
    }
    return (
      <>
        <div className={styles.ConversationInput}>
          <div className={styles.ConversationInputContainer}>
            <div className={styles.ConversationInputs}>
              {inputContext && (
                <Text className={styles.ConversationInputContext}>
                  <Truncate lines={2}>{inputContext.selectedText}</Truncate>
                </Text>
              )}
              <TextArea
                ref={textInput}
                className={classnames(styles.ConversationInputTextArea, {
                  [styles.ConversationInputTextAreaMinimal]:
                    variant === 'minimal',
                })}
                placeholder={placeholderText}
                rows={1}
                resizable="auto"
                isTransparent
                value={promptText}
                onChange={handleTextAreaChange}
                onKeyDown={handleKeyDown}
                onFocus={onFocus}
              />
              {showAddSourcesButton && handleClickAdd && (
                <div className={styles.ConversationInputSourcesActions}>
                  <Chip
                    className={styles.ConversationInputAddSourceChip}
                    variant="transparent"
                    size="medium"
                    onClick={handleClickAdd}
                  >
                    <Chip.IconAccessory>
                      <UIIcon src={AddCircleLine} />
                    </Chip.IconAccessory>
                    <Chip.Content>
                      {i18n.t('compose_add_source_action')}
                    </Chip.Content>
                  </Chip>
                </div>
              )}
            </div>
            <IconButton
              className={classnames(styles.ConversationInputSendButton, {
                [styles.ConversationInputSendButtonBottomRight]:
                  variant !== 'minimal',
              })}
              variant="opacity"
              shape="standard"
              size="medium"
              aria-label={i18n.t('send')}
              onClick={handleSubmit}
              disabled={promptText.trim().length === 0}
            >
              <UIIcon src={SendLine} size="standard" />
            </IconButton>
          </div>
        </div>
        <Text className={styles.ConversationInputDisclaimer}>
          {i18n.t(
            variant === 'default'
              ? 'conversation_input_disclaimer'
              : 'conversation_input_disclaimer_short',
          )}
        </Text>
      </>
    );
  },
);
ConversationInput.displayName = 'ConversationInput';
