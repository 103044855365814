/* eslint-disable camelcase */
import { DashSuggestionSource } from '@mirage/analytics/events/enums/dash_suggestion_source';
import { Click_SuggestedAutoStack } from '@mirage/analytics/events/types/click_suggested_auto_stack';
import { Open_CreateStackLink } from '@mirage/analytics/events/types/open_create_stack_link';
import { Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { Open_DashRecentLink } from '@mirage/analytics/events/types/open_dash_recent_link';
import { Open_DashSuggestedLink } from '@mirage/analytics/events/types/open_dash_suggested_link';
import { Shown_DashSuggestedLink } from '@mirage/analytics/events/types/shown_dash_suggested_link';
import { Shown_SuggestedAutoStack } from '@mirage/analytics/events/types/shown_suggested_auto_stack';

/**
 * An optional per-link accessory action. Examples would be removing items from autostacks
 * or closing tabs for tab management
 */
export interface AccessoryInfo {
  accessoryIcon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  onClickAccessory: () => void;
  accessoryTooltipTitle: string;
}

export type LinkExposurePapEvent =
  | Shown_DashSuggestedLink
  | Shown_SuggestedAutoStack;

export enum ListItemSize {
  XLarge = 'xlarge',
  Large = 'large',
  Small = 'small',
}

export type LinkOpenPAPEvent =
  | Open_DashLink
  | Open_DashSuggestedLink
  | Open_CreateStackLink
  | Open_DashRecentLink
  | Click_SuggestedAutoStack;

/**
 * A barebones set of metadata for a link. We have links coming in from different sources (calendar
 * events, activity tracking) that already have their own shapes with lots of other data not
 * pertinent to this component. We trim down to only what we need.
 */
export interface Link {
  url: string;
  title: string | undefined;
  subtitle?: string;
  sortKey?: string;
  id?: number | string;
  windowId?: number;
  tabId?: number;
  dashRequestId?: string;
  predictionId?: string;
  rank?: number;
  predictionIdHash?: number;
  itemIdHash?: number;
  dashSuggestionSource?: DashSuggestionSource;
  isNewlyShared?: boolean;
  id3p?: string; // id of the 3rd party service that the link came from
  brandedType?: string;
}

export interface LinkWithId extends Link {
  id: string;
}

export interface OrderedLink extends LinkWithId {
  sortKey: string;
}
