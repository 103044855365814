import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Tabs } from '@dropbox/dig-components/tabs';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Stack, useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ClockLine,
  CopyLine,
  DownloadLine,
  LegalLine,
  OpenLine,
  PersonLeaveLine,
  SignOutLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { LaptopMini } from '@dropbox/dig-illustrations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashSettingsSection } from '@mirage/analytics/events/enums/dash_settings_section';
import { PAP_Delete_DashLogin } from '@mirage/analytics/events/types/delete_dash_login';
import { PAP_Open_DashConnectAppPage } from '@mirage/analytics/events/types/open_dash_connect_app_page';
import { PAP_Open_DashConnectors } from '@mirage/analytics/events/types/open_dash_connectors';
import { PAP_Open_DashSettings } from '@mirage/analytics/events/types/open_dash_settings';
import { PAP_Update_DashSettings } from '@mirage/analytics/events/types/update_dash_settings';
import { PAP_View_DashSettings } from '@mirage/analytics/events/types/view_dash_settings';
import { setShouldTraceRequests } from '@mirage/service-auth';
import { useDevFeatures } from '@mirage/service-auth/useDevFeatures';
import {
  useIsDropboxer,
  useIsTestAccount,
} from '@mirage/service-auth/useDropboxAccount';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { copyToClipboard, openURL } from '@mirage/service-platform-actions';
import { exit, manualCheckForUpdates } from '@mirage/service-setting-actions';
import { ExitType } from '@mirage/service-setting-actions/service';
import * as searchSettings from '@mirage/service-settings';
import { SettingsPartial } from '@mirage/service-settings';
import { AvatarSettings } from '@mirage/service-settings/service/types';
import { createRadios } from '@mirage/settings/components/list-items/RadioListItem';
import { ConnectorsList } from '@mirage/settings/ConnectorsList';
import { DeveloperSettings } from '@mirage/settings/DeveloperSettings';
import FeaturesSettings from '@mirage/settings/features/FeaturesSettings';
import GeneralSettings, {
  GateOptions,
  GeneralSections,
  PreferenceOption,
  PreferenceSection,
} from '@mirage/settings/GeneralSettings';
import KeyboardSettings from '@mirage/settings/KeyboardSettings';
import { SettingsModal } from '@mirage/settings/types/modal';
import { useExtensionConnectionId } from '@mirage/settings/utils/webExtensionsHelpers';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import { getKeyMaps } from '@mirage/shared/hotkeys/hotkeysKeyMap';
import { showSnackbar } from '@mirage/shared/snackbar';
import { PRIVACY_AND_LEGAL } from '@mirage/shared/urls';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n, {
  i18nDebugLocale,
  isSupportedLocale,
  supportedLocaleConfig,
} from '@mirage/translations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearBrowsingHistory } from './ClearBrowsingHistory';
import { DisableNewTabTooltipIcon } from './DisableNewTabTooltipIcon';
import { SettingsBanner } from './SettingsBanner';
import styles from './SettingsPage.module.css';
import { useConnectorsUI } from './useConnectorsUI';
import { useLocalFileSettings } from './useLocalFileSettings';

export type ShowSettingsSections = {
  general?: { [keys in GeneralSections]?: boolean };
  apps?: { showConnectorsList?: boolean };
  localFiles?: boolean;
  keyboardShortcuts?: boolean;
  internalFeatures?: boolean;
  internalDeveloper?: boolean;
};

type Props = Readonly<{
  settings: SettingsPartial<keyof AvatarSettings>;
  defaultTab?: string;
  showSettingsSections: ShowSettingsSections;
}>;

const getMainPreferences = (platform: string): Array<PreferenceOption> => [
  {
    name: 'enableDarkModeSystem',
    label: i18n.t('settings_theme_system'),
    value: 2,
    ariaLabel: i18n.t('settings_theme_aria_label_system'),
    section: 'theme',
  },
  {
    name: 'enableDarkModeLight',
    label: i18n.t('settings_theme_light'),
    value: 0,
    ariaLabel: i18n.t('settings_theme_aria_label_light'),
    section: 'theme',
  },
  {
    name: 'enableDarkModeDark',
    label: i18n.t('settings_theme_dark'),
    value: 1,
    ariaLabel: i18n.t('settings_theme_aria_label_dark'),
    section: 'theme',
  },
  ...Object.entries(supportedLocaleConfig).map(
    ([locale, label]): PreferenceOption => ({
      name: locale,
      value: locale,
      label,
      ariaLabel: label,
      section: 'locale',
      gateSetting: locale === i18nDebugLocale ? 'isDropboxer' : undefined,
    }),
  ),
  {
    name: 'hideAppOnStartup',
    settingKey: 'hideAppOnStartup',
    label: i18n.t('settings_hide_app_on_start'),
    componentType: 'checkbox',
    invert: true,
    section: 'preferences',
  },
  {
    name: 'showDashInDock',
    settingKey: 'showDashInDock',
    label:
      platform === 'darwin'
        ? i18n.t('settings_show_dash_in_dock_darwin')
        : i18n.t('settings_show_dash_in_dock_win32'),
    componentType: 'checkbox',
    section: 'preferences',
  },
  {
    name: 'openFilesInDesktopApps',
    settingKey: 'openFilesInDesktopApps',
    label: i18n.t('settings_open_files_in_desktop_apps'),
    componentType: 'checkbox',
    gateSetting: 'enableOpenFilesInDesktopApps',
    section: 'preferences',
  },
  {
    name: 'disableDashAsNewTab',
    label: (
      <>
        {i18n.t('settings_disable_dash_as_new_tab')}
        <DisableNewTabTooltipIcon />
      </>
    ),
    section: 'webPreferences',
  },
  {
    name: 'appDebug',
    settingKey: 'appDebug',
    label: i18n.t('settings_enable_debug_mode'),
    componentType: 'checkbox',
    section: 'debug',
    onClick: (option?: number | string) => {
      setShouldTraceRequests(!!option);
    },
  },
  {
    name: 'isDevTools',
    settingKey: 'isDevTools',
    label: i18n.t('settings_enable_dev_tools'),
    componentType: 'checkbox',
    gateSetting: 'internalDesktop',
    section: 'debug',
  },
  {
    name: 'clearHistory',
    icon: <UIIcon src={ClockLine} role="presentation" />,
    label: i18n.t('settings_clear_browsing_history'),
    subtitle: i18n.t('settings_clear_browsing_history_subtext'),
    displayActions: <ClearBrowsingHistory />,
    gateSetting: 'extensionInstalled',
    section: 'security',
  },
];

export function SettingsPage({
  settings,
  defaultTab,
  showSettingsSections,
}: Props) {
  // This state is being managed by the URL routes, so just read it from the prop
  const selectedTab = defaultTab ?? 'general';
  const [modalData, setModalData] = useState<SettingsModal>();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const enableDevFeatures = useDevFeatures();
  const { appShortcut, disableAutoUpdate } = settings;
  const { openConnectors } = useConnectorsUI();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const enableOpenFilesInDesktopApps = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_10_24_open_files_in_desktop_apps'),
  );
  const isDropboxer = useIsDropboxer() || false;
  const isTestAccount = useIsTestAccount() || false;
  const { platform, surface } = EnvCtx;
  const isDesktop = surface === 'desktop';
  const mainPreferences: Array<PreferenceOption> = getMainPreferences(platform);
  const localFileSections = useLocalFileSettings(
    Boolean(showSettingsSections.localFiles),
  );
  const connectionId = useExtensionConnectionId();
  // To be used in next PR
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const extensionInstalled =
    convertFeatureValueToBool(
      useFeatureFlagValue(
        'dash_extension_2024_09_19_clear_browser_history',
        false,
      ),
    ) && connectionId !== undefined;
  const { mode } = useTheme();
  const isDarkMode = mode === 'dark';

  const filterSettings = useCallback(
    (s: PreferenceOption, section: string) => {
      // add feature flags here and match them to the setting feature you wish to enable
      const gates: { [key in GateOptions]: boolean } = {
        enableOpenFilesInDesktopApps,
        isDropboxer: isDropboxer || isTestAccount,
        internalDesktop: (isDropboxer || isTestAccount) && isDesktop,
        extensionInstalled,
      };

      if (s.section !== section) return false;
      if (!s.gateSetting) return true;
      return gates[s.gateSetting];
    },
    [
      enableOpenFilesInDesktopApps,
      extensionInstalled,
      isDropboxer,
      isTestAccount,
      isDesktop,
    ],
  );

  const handleCopyVersion = useCallback(() => {
    showSnackbar({
      title: i18n.t('settings_version_snackbar_copied_to_clipboard'),
    });
    copyToClipboard(
      i18n.t('settings_dash_version_copy_text', { appVersion: EnvCtx.version }),
    );
  }, []);

  const reportLogEvent = useCallback(
    (settingName: string, settingValue: number) => {
      reportPapEvent(
        PAP_Update_DashSettings({
          dashSettingName: getAmplitudeDashSettingName(settingName),
          dashSettingValueNum: settingValue || 0,
          actionSurface: 'settings_page',
        }),
      );
    },
    [reportPapEvent],
  );

  const setSetting = useCallback(
    async (
      settingName: keyof AvatarSettings,
      value?: number | string | undefined,
    ) => {
      if (typeof value === 'number') {
        reportLogEvent(settingName, value);
        await searchSettings.set(settingName, value);
      } else if (value) {
        // TODO @ryankara_dbx, how should I report this?
        await searchSettings.set(settingName, value);
      }
    },
    [reportLogEvent],
  );

  useDashTitle(i18n.t('settings_tab_title'));

  useEffect(() => {
    reportPapEvent(PAP_Open_DashSettings({}));
  }, [reportPapEvent]);

  const handleCheckForUpdates = () => {
    manualCheckForUpdates();
  };

  const handleLegalAndPrivacy = () => {
    openURL(PRIVACY_AND_LEGAL);
  };

  const handleQuit = (exitType: ExitType) => {
    exit(exitType);
  };

  const handleConnectors = () => {
    reportPapEvent(
      PAP_Open_DashConnectAppPage({
        actionSurfaceComponent: 'settings',
        featureLine: 'connector',
      }),
    );
    openConnectors();
  };

  const handleSelectedTab = (key: string) => {
    reportPapEvent(
      PAP_View_DashSettings({
        dashSettingsSection: key as DashSettingsSection,
      }),
    );
    navigate(`/settings/${key}`);
  };

  useEffect(() => {
    const desiredPathname = `/settings/${selectedTab}`;

    if (pathname !== desiredPathname) {
      // Update url pathname without navigation.
      navigate(desiredPathname, { replace: true });
    }
  }, [navigate, pathname, selectedTab]);

  const handleModal = (modal: SettingsModal) => {
    const modalData = {
      ...modal,
    };
    modalData.action = () => {
      if (modal.action) {
        modal.action();
      }
      setModalData(undefined);
    };
    modalData.cancel = () => {
      if (modal.cancel) {
        modal.cancel();
      }
      setModalData(undefined);
    };
    if (modal.secondaryAction) {
      modalData.secondaryAction = () => {
        if (modal.secondaryAction) {
          modal.secondaryAction();
        }
        setModalData(undefined);
      };
    }
    setModalData(modalData);
  };

  const generalMoreSection = useMemo(() => {
    const versionLabel =
      disableAutoUpdate === 'disable-production-updater'
        ? i18n.t('settings_version_label_for_organizations', {
            appVersion: EnvCtx.version,
          })
        : i18n.t('settings_version_label', { appVersion: EnvCtx.version });

    const moreItems: PreferenceOption[] = [
      {
        name: 'version',
        label: versionLabel,
        componentType: 'button',
        role: 'button',
        ariaLabel: versionLabel,
        onClick: handleCopyVersion,
        tooltipTitle: i18n.t('settings_version_tooltip_click_to_copy'),
        icon: <UIIcon src={CopyLine} role="presentation" />,
      },
    ];

    if (disableAutoUpdate !== 'disable-production-updater') {
      moreItems.push({
        name: 'updates',
        label: i18n.t('settings_check_for_updates'),
        componentType: 'button',
        role: 'button',
        ariaLabel: i18n.t('settings_check_for_updates'),
        onClick: handleCheckForUpdates,
        icon: <UIIcon src={DownloadLine} role="presentation" />,
      });
    }

    moreItems.push({
      name: 'legal',
      label: i18n.t('settings_legal_and_privacy'),
      componentType: 'button',
      onClick: handleLegalAndPrivacy,
      icon: <UIIcon src={LegalLine} role="presentation" />,
      ariaLabel: i18n.t('aria_settings_privacy_policy'),
      role: 'link',
    });

    if (enableDevFeatures) {
      return moreItems.concat([
        {
          name: 'logout',
          label: i18n.t('settings_reset_and_quit'),
          componentType: 'button',
          icon: <UIIcon src={PersonLeaveLine} role="presentation" />,
          modalData: {
            title: i18n.t('settings_reset_and_quit_confirm_title'),
            body: i18n.t('settings_reset_and_quit_confirm_text'),
            actionText: i18n.t('settings_reset_and_quit_confirm_button'),
            action: () => handleQuit('quit_and_reset'),
            secondaryActionText: i18n.t('settings_manage_apps'),
            secondaryAction: () => {
              reportPapEvent(
                PAP_Open_DashConnectors({
                  entryPoint: 'reconnect_apps_modal',
                  connectorPage: 'web',
                  actionSurface: 'settings_page',
                }),
              );
              openConnectors();
            },
            onAfterOpen: () => {
              reportPapEvent(
                PAP_Delete_DashLogin({
                  eventState: 'start',
                }),
              );
            },
            cancel: () => {
              reportPapEvent(
                PAP_Delete_DashLogin({
                  eventState: 'canceled',
                }),
              );
            },
          },
        },
        {
          name: 'quit',
          label: i18n.t('settings_quit'),
          componentType: 'button',
          role: 'button',
          ariaLabel: i18n.t('settings_quit'),
          icon: <UIIcon src={SignOutLine} role="presentation" />,
          modalData: {
            title: i18n.t('settings_quit_confirm_title'),
            actionText: i18n.t('settings_quit_confirm_text'),
            action: () => handleQuit('quit'),
          },
        },
      ]);
    }

    return moreItems;
  }, [
    disableAutoUpdate,
    enableDevFeatures,
    handleCopyVersion,
    openConnectors,
    reportPapEvent,
  ]);

  const generalSettingsOptions = useMemo(() => {
    const sections: PreferenceSection[] = [];

    // Theme section.
    if (showSettingsSections.general?.theme) {
      sections.push({
        name: i18n.t('settings_section_theme'),
        key: 'theme-list',
        preferences: createRadios({
          options: mainPreferences.filter((s) => filterSettings(s, 'theme')),
          componentType: 'radio',
          value: settings['enableDarkMode'],
          onClick: async (option?: number | string) =>
            await setSetting('enableDarkMode', option),
        }),
      });
    }

    // Locale section.
    if (showSettingsSections.general?.locale) {
      sections.push({
        name: i18n.t('settings_section_locale'),
        key: 'locale-list',
        preferences: createRadios({
          options: mainPreferences.filter((s) => filterSettings(s, 'locale')),
          componentType: 'radio',
          value: settings['locale'],
          onClick: async (locale) => {
            if (typeof locale === 'string' && isSupportedLocale(locale)) {
              await i18n.changeLanguage(locale);
              await setSetting('locale', locale);
              setModalData({
                title: i18n.t('changed_language_modal_title', {
                  language: supportedLocaleConfig[locale],
                }),
                cancelText: i18n.t('changed_language_modal_action_later'),
                cancel: () => setModalData(undefined),
                shouldCloseOnOverlayClick: true,
                ...(isDesktop
                  ? {
                      body: i18n.t(
                        'changed_language_modal_description_desktop',
                      ),
                      actionText: i18n.t(
                        'changed_language_modal_action_desktop',
                      ),
                      action: () => handleQuit('restart'),
                    }
                  : {
                      body: i18n.t('changed_language_modal_description_web'),
                      actionText: i18n.t('changed_language_modal_action_web'),
                      action: () => window.location.reload(),
                    }),
              });
            }
          },
        }),
      });
    }

    // Preferences section.
    if (showSettingsSections.general?.preferences) {
      sections.push({
        name: i18n.t('settings_section_preferences'),
        key: 'preferences-list',
        preferences: mainPreferences
          .filter((s) => filterSettings(s, 'preferences'))
          .map((s) => {
            const value = s.settingKey ? settings[s.settingKey] : undefined;
            const invertNumber = s.invert && typeof value === 'number';
            return {
              ...s,
              value: invertNumber ? (value ? 0 : 1) : value,
              onClick: (option?: number | string) => {
                s.settingKey &&
                  setSetting(
                    s.settingKey,
                    invertNumber ? (option ? 0 : 1) : option,
                  );
              },
            };
          }),
      });
    }

    // Web preferences section.
    if (showSettingsSections.general?.webPreferences) {
      sections.push({
        name: i18n.t('settings_section_preferences'),
        key: 'web-preferences-list',
        preferences: mainPreferences.filter((s) =>
          filterSettings(s, 'webPreferences'),
        ),
      });
    }

    // Data & Security section.
    if (showSettingsSections.general?.security) {
      sections.push({
        name: i18n.t('settings_section_security'),
        key: 'security-list',
        preferences: mainPreferences.filter((s) =>
          filterSettings(s, 'security'),
        ),
      });
    }

    if (showSettingsSections.general?.debug) {
      sections.push({
        name: i18n.t('settings_section_debug'),
        key: 'debug-list',
        preferences: mainPreferences
          .filter((s) => filterSettings(s, 'debug'))
          .map((s) => {
            const value = s.settingKey ? settings[s.settingKey] : undefined;
            const invertNumber = s.invert && typeof value === 'number';
            return {
              ...s,
              value: invertNumber ? (value ? 0 : 1) : value,
              onClick: (option?: number | string) => {
                s.settingKey &&
                  setSetting(
                    s.settingKey,
                    invertNumber ? (option ? 0 : 1) : option,
                  );
                s.onClick && s.onClick(option);
              },
            };
          }),
      });
    }

    // More section.
    if (showSettingsSections.general?.more) {
      sections.push({
        name: i18n.t('settings_section_more'),
        key: 'more-list',
        preferences: generalMoreSection,
      });
    }

    return sections;
  }, [
    showSettingsSections.general?.theme,
    showSettingsSections.general?.preferences,
    showSettingsSections.general?.webPreferences,
    showSettingsSections.general?.security,
    showSettingsSections.general?.debug,
    showSettingsSections.general?.more,
    mainPreferences,
    settings,
    filterSettings,
    setSetting,
    generalMoreSection,
  ]);

  const keyMaps = useMemo(() => {
    return !!t && getKeyMaps(platform, isDesktop);
  }, [platform, isDesktop, t]);

  const shortcuts = useMemo(() => {
    return {
      ...keyMaps.common,
      // TODO, make appShortcut work. Right now returns undefined.
      // see notes in this Jira https://jira.dropboxer.net/browse/OSE-2736
      // setting this to the prod value until this really works
      appShortcut: appShortcut ?? 'CommandOrControl+E',
    };
  }, [appShortcut, keyMaps.common]);

  let shortcutItems = keyMaps.labels.general;
  if (disableAutoUpdate === 'disable-production-updater') {
    shortcutItems = shortcutItems.filter(
      (item) => item[0] !== 'checkForUpdates',
    );
  }

  const handleSetSetting = (
    settingName: keyof AvatarSettings,
    value?: number | string | undefined,
  ) => setSetting(settingName, value);

  /**
   *
   * @param key Name of Setting
   * @returns DashSettingName enum value from PAP Admin
   */
  const getAmplitudeDashSettingName = (key: string) => {
    switch (key) {
      case 'appDebug':
        return 'app_debug';
      case 'appToNearestWindow':
        return 'app_to_nearest_window';
      case 'enableDarkMode':
        return 'enable_dark_mode';
      case 'enableLocalFileSearch':
        return 'enable_local_file_search';
      case 'enableTips':
        return 'enable_tips';
      case 'hideAppOnStartup':
        return 'hide_app_on_startup';
      case 'isDevTools':
        return 'is_dev_tools';
      case 'showDashInDock':
        return 'show_dash_in_dock';
      case 'showOnboardingTray':
        return 'show_onboarding_tray';
      case 'showTrayIcon':
        return 'show_tray_icon';
      case 'whatsNewUpdated':
        return 'whats_new_updated';
      case 'enableWhatsNewNotify':
        return 'enable_whats_new_notify';
      default:
        if (key.startsWith('disableApp') && key.endsWith('Path')) {
          return 'disable_app_path';
        }
        return 'unknown_setting';
    }
  };

  return (
    <div className={styles.pageContainer}>
      {modalData && (
        <Modal open={true} isCentered>
          <Modal.Header>
            <Modal.Title>{modalData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalData.body}</Modal.Body>
          <Modal.Footer>
            {modalData.secondaryAction && (
              <Button variant="transparent" onClick={modalData.secondaryAction}>
                {modalData.secondaryActionText}
              </Button>
            )}
            {modalData.cancel && (
              <Button variant="opacity" onClick={modalData.cancel}>
                {modalData.cancelText ?? 'Cancel'}
              </Button>
            )}
            <Button variant="primary" onClick={modalData.action}>
              {modalData.actionText}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <div className={styles.contentContainer}>
        <Title
          className={styles.settingsTitle}
          weightVariant="normal"
          size="medium"
          tagName="h1"
        >
          {i18n.t('settings_title')}
        </Title>
        <div className={styles.overflowContainer}>
          {!isDesktop && <SettingsBanner />}
          <Tabs
            className={styles.settingsTabs}
            selectedTab={selectedTab}
            onSelection={handleSelectedTab}
          >
            <Tabs.Group hasHorizontalPadding={false}>
              {showSettingsSections.general && (
                <Tabs.Tab id="general" className={styles.tabName}>
                  {i18n.t('settings_general')}
                </Tabs.Tab>
              )}
              {showSettingsSections.apps && (
                <Tabs.Tab id="apps" className={styles.tabName}>
                  {i18n.t('settings_apps')}
                </Tabs.Tab>
              )}
              {showSettingsSections.localFiles && (
                <Tabs.Tab id="local_files" className={styles.tabName}>
                  {i18n.t('settings_local_files')}
                </Tabs.Tab>
              )}
              {showSettingsSections.keyboardShortcuts && (
                <Tabs.Tab id="keyboard_shortcuts" className={styles.tabName}>
                  {i18n.t('settings_keyboard_shortcuts')}
                </Tabs.Tab>
              )}
              {showSettingsSections.internalFeatures && (
                <Tabs.Tab id="features" className={styles.tabName}>
                  <DigTooltip title="Internal to Dropboxers only">
                    <div className={styles.internalTabName}>Features</div>
                  </DigTooltip>
                </Tabs.Tab>
              )}
              {showSettingsSections.internalDeveloper && (
                <Tabs.Tab id="developer" className={styles.tabName}>
                  <DigTooltip title="Internal to Dropboxers only">
                    <div className={styles.internalTabName}>Developer</div>
                  </DigTooltip>
                </Tabs.Tab>
              )}
            </Tabs.Group>
            {showSettingsSections.general && (
              <Tabs.Panel tabId="general" tabIndex={0}>
                <div className={styles.settingsContainer}>
                  <GeneralSettings
                    preferenceSections={generalSettingsOptions}
                    onModal={handleModal}
                  />
                </div>
              </Tabs.Panel>
            )}
            {showSettingsSections.apps && (
              <Tabs.Panel tabId="apps" tabIndex={-1}>
                <div className={styles.settingsContainer}>
                  {showSettingsSections?.apps?.showConnectorsList ? (
                    <ConnectorsList />
                  ) : (
                    <Stack
                      gap="24"
                      display="flex"
                      justify="center"
                      align="center"
                    >
                      <div className={styles.iconContainer}>
                        <LaptopMini
                          color={isDarkMode ? 'white' : undefined}
                          altText={i18n.t('settings_manage_apps_icon_title')}
                        />
                      </div>

                      <Stack
                        gap="0"
                        display="flex"
                        justify="center"
                        align="center"
                      >
                        <Text
                          size="large"
                          tagName="div"
                          isBold
                          className={styles.manageAppsMessageTitle}
                        >
                          {i18n.t('settings_manage_apps_title')}
                        </Text>
                        <Text size="medium" tagName="div" color="subtle">
                          {i18n.t('settings_manage_apps_subtitle')}
                        </Text>
                      </Stack>
                      <Button
                        variant="primary"
                        onClick={handleConnectors}
                        role="link"
                        className={styles.manageAppsButton}
                      >
                        <UIIcon src={OpenLine} />
                        {i18n.t('settings_manage_apps')}
                      </Button>
                    </Stack>
                  )}
                </div>
              </Tabs.Panel>
            )}
            {showSettingsSections.localFiles && (
              <Tabs.Panel tabId="local_files" tabIndex={0}>
                <div className={styles.settingsContainer}>
                  <GeneralSettings preferenceSections={localFileSections} />
                </div>
              </Tabs.Panel>
            )}
            {showSettingsSections.keyboardShortcuts && (
              <Tabs.Panel tabId="keyboard_shortcuts" tabIndex={0}>
                <div className={styles.settingsContainer}>
                  <KeyboardSettings
                    platform={EnvCtx.platform}
                    setSetting={handleSetSetting}
                    settings={settings}
                    shortcutItems={shortcutItems}
                    shortcuts={shortcuts}
                  />
                </div>
              </Tabs.Panel>
            )}
            {showSettingsSections.internalFeatures && (
              <Tabs.Panel tabId="features" tabIndex={0}>
                <div className={styles.settingsContainer}>
                  <FeaturesSettings />
                </div>
              </Tabs.Panel>
            )}
            {showSettingsSections.internalDeveloper && (
              <Tabs.Panel tabId="developer" tabIndex={0}>
                <div className={styles.settingsContainer}>
                  <DeveloperSettings />
                </div>
              </Tabs.Panel>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
}
