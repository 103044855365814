import { WebWelcomeModalV2 } from "@mirage/growth/components/modals";
import useDropboxAccount from "@mirage/service-auth/useDropboxAccount";
import useShouldUseStageBackend from "@mirage/service-auth/useShouldUseStageBackend";
import { SurveyProvider } from "@mirage/service-feedback/hooks/SurveyProvider";
import { logout } from "@mirage/service-logout";
import { logPageLoadMilestoneOnce } from "@mirage/service-operational-metrics/page-load";
import { SearchContextProvider } from "@mirage/service-search/hooks/useSearchContext";
import { privacyCategoryStateAtom } from "@mirage/shared/privacy";
import { activeStackAtom } from "@mirage/stacks/ActiveStack/atoms";
import { getIsPublicStack } from "@mirage/stacks/utils";
import { StartPageFocusStateLoader } from "@mirage/webapp/analytics/sessionManagers/StartPageFocusStateLoader";
import { WebLayout } from "@mirage/webapp/components/WebLayout";
import { getEligibilityFromCache } from "@mirage/webapp/gating/Eligibility";
import { WebEligibilityCheck } from "@mirage/webapp/gating/WebEligibilityCheck";
import { useInitAuthedJobs } from "@mirage/webapp/hooks/useInitAuthedJobs";
import { isConsentAccepted } from "@mirage/webapp/hooks/usePrivacyModule";
import { PageType } from "@mirage/webapp/routeTypes";
import { useAtomValue } from "jotai";

import { useRoute } from "../Routes";
import { Config } from "../shared/config";
import { sharedWorkerDatabase } from "../shared-worker/sharedWorkerStorage";

function getEligibilityFromStore() {
  return getEligibilityFromCache(sharedWorkerDatabase);
}

export const AuthedContainer: React.FC = () => {
  logPageLoadMilestoneOnce("AuthedContainer start");

  const route = useRoute();
  const account = useDropboxAccount();
  const privacyCategoryState = useAtomValue(privacyCategoryStateAtom);
  const isAnalyticsEnabled = isConsentAccepted(privacyCategoryState);

  const activeStack = useAtomValue(activeStackAtom);
  const isPublicStack = getIsPublicStack(activeStack);

  useInitAuthedJobs(route.pattern, Config.DEVBOX);
  useShouldUseStageBackend();

  if (route.pageType === PageType.LOGOUT) {
    return <route.Component />;
  }

  const shouldCheckWebEligibility =
    route.pageType !== PageType.STACK || isPublicStack === false;

  return (
    <>
      <SurveyProvider
        surface="webapp"
        environmentId={Config.SPRIG_ENVIRONMENT_ID}
        disabled={!isAnalyticsEnabled}
        publicAccountId={
          account?.public_account_id && atob(account?.public_account_id)
        }
      />
      <SearchContextProvider isLocalFilesEnabled={false}>
        {shouldCheckWebEligibility && (
          <WebEligibilityCheck
            getEligibilityFromStore={getEligibilityFromStore}
            logout={logout}
          />
        )}

        {route.pageType !== PageType.SETUP && (
          // Don't show the welcome modal on the onboarding page
          // to ensure that the first time users will see it will be when they're ready
          // to interact with the app features
          <WebWelcomeModalV2 size="standard" />
        )}

        <StartPageFocusStateLoader />

        {route.wholePage ? (
          <route.Component />
        ) : (
          <WebLayout hideTopNav={route.hideTopNav}>
            <route.Component />
          </WebLayout>
        )}
      </SearchContextProvider>
    </>
  );
};
