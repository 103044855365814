import {
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import { memo } from 'react';
import { ConversationMessageRowMessageSourceRow } from './ConversationMessages';
import styles from './ConversationMessageSourceChips.module.css';

interface ConversationMessageSourceChipsProps {
  sources: ComposeSource[];
  removeSource: (source: ComposeSource) => void;
}
export const ConversationMessageSourceChips = memo(
  ({ sources, removeSource }: ConversationMessageSourceChipsProps) => {
    return (
      <div>
        {sources.map((source, i) => (
          <div
            className={styles.ContextInputSourcesChipRow}
            key={getSourceUUID(source) || i}
          >
            <ConversationMessageRowMessageSourceRow
              source={source}
              onRemoveSource={removeSource}
            />
          </div>
        ))}
      </div>
    );
  },
);
ConversationMessageSourceChips.displayName = 'ConversationMessageSourceChips';
