import { UIIcon } from '@dropbox/dig-icons';
import { RedoLine, UndoLine } from '@dropbox/dig-icons/assets';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_Redo } from '@mirage/analytics/events/types/click_redo';
import { PAP_Click_Undo } from '@mirage/analytics/events/types/click_undo';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import { memo, useEffect, useState } from 'react';
import styles from './StaticToolbarPlugin.module.css';

const DEFAULT_COMMAND_PRIORITY = 1;

interface StaticToolbarPluginProps {
  additionalButtons?: React.ReactNode;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const StaticToolbarPlugin = memo(
  ({ additionalButtons, logComposeEvent }: StaticToolbarPluginProps) => {
    const [editor] = useLexicalComposerContext();
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);

    useEffect(() => {
      return mergeRegister(
        editor.registerCommand(
          CAN_UNDO_COMMAND,
          (payload) => {
            setCanUndo(payload);
            return false;
          },
          DEFAULT_COMMAND_PRIORITY,
        ),
        editor.registerCommand(
          CAN_REDO_COMMAND,
          (payload) => {
            setCanRedo(payload);
            return false;
          },
          DEFAULT_COMMAND_PRIORITY,
        ),
      );
    }, [editor]);

    return (
      <div className={styles.StaticToolbar}>
        {/* a11y: inert property prevents tabbing when disabled */}
        <div {...{ inert: !canUndo ? '' : undefined }}>
          <IconButtonWithTooltip
            variant="borderless"
            disabled={!canUndo}
            tooltipProps={{
              title: i18n.t('compose_editor_toolbar_action_undo'),
            }}
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND, undefined);
              logComposeEvent(PAP_Click_Undo());
            }}
          >
            <UIIcon src={UndoLine} size="small" />
          </IconButtonWithTooltip>
        </div>
        {/* a11y: inert property prevents tabbing when disabled */}
        <div {...{ inert: !canUndo ? '' : undefined }}>
          <IconButtonWithTooltip
            variant="borderless"
            disabled={!canRedo}
            tooltipProps={{
              title: i18n.t('compose_editor_toolbar_action_redo'),
            }}
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND, undefined);
              logComposeEvent(PAP_Click_Redo());
            }}
          >
            <UIIcon src={RedoLine} size="small" />
          </IconButtonWithTooltip>
        </div>
        <div className={styles.Divider} />
        {additionalButtons}
      </div>
    );
  },
);
StaticToolbarPlugin.displayName = 'StaticToolbarPlugin';
