import {
  mapContentTypeFilterToQueryFilter,
  mapLastUpdatedFilterToQueryFilter,
  transformDashResultToMirage,
} from '@mirage/service-dbx-api/service/utils';
import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { CALENDAR_CONNECTORS } from '@mirage/shared/connectors/entityTypes';
import {
  ContentTypeFilter,
  getContentTypeFilters,
  getPersonFilter,
  isConnectorFilter,
  isContentTypeFilter,
  isLastUpdatedFilter,
  LastUpdatedFilter,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

import type { APIv2CallableWithHeaders, Connection } from '..';
import type { dcs } from '@dropbox/api-v2-client';
import type { SearchResult } from '@mirage/service-dbx-api/service/search';
export async function upstreamForConnection(
  api: APIv2CallableWithHeaders,
  query: string,
  connection: Connection,
  filters: SearchFilter[] = [],
): Promise<SearchResult[]> {
  const isUpstreamFilterByTypeEnabled = convertFeatureValueToBool(
    await getCachedOrFetchFeatureValue(
      'dash_2024_11_22_upstream_filter_by_file_type',
    ),
  );
  if (!connection?.id_attributes?.id) {
    return [];
  }

  const hasContentTypeFilters = getContentTypeFilters(filters).length > 0;
  const hasPersonFilter = getPersonFilter(filters) !== undefined;

  // TEMPORARY:
  // Until intel supports upstream search for content type / people filter
  if (
    (hasContentTypeFilters && !isUpstreamFilterByTypeEnabled) ||
    hasPersonFilter
  ) {
    return [];
  }

  const lastUpdatedFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is LastUpdatedFilter =>
      isLastUpdatedFilter(filter),
    )
    .map(mapLastUpdatedFilterToQueryFilter);

  const contentTypeFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is ContentTypeFilter =>
      isContentTypeFilter(filter),
    )
    .map(mapContentTypeFilterToQueryFilter);

  const response = await api('dcsUpstream', {
    query,
    connection_id: connection?.id_attributes?.id,
    filters: [...lastUpdatedFilters, ...contentTypeFilters],
  });

  const { headers, result } = response;

  if (result.results === undefined) {
    return [];
  }
  const analyticsTraceId = headers.get('x-dropbox-request-id') || '';

  const mirageSearchResults = (result.results || [])
    .map((dsr) => transformDashResultToMirage(dsr, undefined, analyticsTraceId))
    .filter((r): r is SearchResult => Boolean(r));

  // HACK: If user doesnt have calendar app filter enabled, we should filter out events.
  // This is a temporary client-side solution to meet August 2024 deadline.
  // For october 2024 and beyond, search relevance will instead downrank events
  // https://jira.dropboxer.net/browse/OTCKA-461
  const hasCalendarAppFilter = filters.some(
    (filter) =>
      isConnectorFilter(filter) && CALENDAR_CONNECTORS.includes(filter.id),
  );
  const hasEventFileTypeFilter = filters.some(
    (filter) => filter.id === 'event',
  );
  if (!hasCalendarAppFilter && !hasEventFileTypeFilter) {
    return mirageSearchResults.filter(
      (result) => result.recordType['.tag'] !== 'event',
    );
  }

  return mirageSearchResults;
}
