import { IconButton } from '@dropbox/dig-components/buttons';
import { List } from '@dropbox/dig-components/list';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, CloseLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { StacksMetadata } from '@mirage/stacks/AllStacksList/AllStacksListItem';
import i18n from '@mirage/translations';
import { WorkingSetCardData } from '@mirage/working-set';
import { ReactNode } from 'react';
import styles from './PublishedContentPage.module.css';

interface PublishedContentListItemProps {
  workingSetCardData: WorkingSetCardData;
  onClick: () => void;
  icon: ReactNode;
  ariaLabel: string;
}

const PublishedContentListItem: React.FC<PublishedContentListItemProps> = ({
  workingSetCardData,
  onClick,
  icon,
  ariaLabel,
}) => {
  return (
    <List.Item
      paddingTop={6}
      paddingBottom={6}
      paddingRight={6}
      paddingLeft={12}
      key={`item-${workingSetCardData.id}`}
      isSelectable
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDownCommitFn(onClick)}
    >
      <List.Content>
        <StacksMetadata
          workingSetCardData={workingSetCardData}
          stackIconAccessorySize="small"
          gap="Micro Large"
          isTitleBold
        />
      </List.Content>
      <List.Accessory>
        <IconButton variant="borderless" aria-label={ariaLabel}>
          {icon}
        </IconButton>
      </List.Accessory>
    </List.Item>
  );
};

export const RemoveStackList = ({
  selectedWorkingSetStack,
  onSelectStack,
}: {
  selectedWorkingSetStack: WorkingSetCardData;
  onSelectStack: () => void;
}) => {
  return (
    <List className={styles.selectedStackItemContainer}>
      <PublishedContentListItem
        workingSetCardData={selectedWorkingSetStack}
        onClick={onSelectStack}
        icon={<UIIcon src={CloseLine} />}
        ariaLabel={i18n.t('remove')}
      />
    </List>
  );
};

export const AddStackList = ({
  workingSetCardData,
  setSelectedWorkingSetStack,
}: {
  workingSetCardData: WorkingSetCardData[];
  setSelectedWorkingSetStack: (value: WorkingSetCardData) => void;
}) => {
  return (
    <List className={styles.stacksListContainer}>
      {workingSetCardData.map((data, index) => (
        <PublishedContentListItem
          key={index}
          workingSetCardData={data}
          onClick={() => setSelectedWorkingSetStack(data)}
          icon={<UIIcon src={AddLine} />}
          ariaLabel={i18n.t('add')}
        />
      ))}
    </List>
  );
};
