import { tryLoadAndStartRecorder } from "@alwaysmeticulous/recorder-loader";
import { dropRequestHeader } from "@alwaysmeticulous/redaction";
import { RecorderMiddleware } from "@alwaysmeticulous/sdk-bundles-api";

import { dashFeedListActivityFeedRedacted } from "./redactions/dashFeedListActivityFeed";
import { dcsGetCalendarEventsRedacted } from "./redactions/dcsGetCalendarEvents";
import { stacksGetRecentActivityRedacted } from "./redactions/stacksGetRecentActivity";
import { IDBEntry, RedactionEntry } from "./types";
import { isMeticulousEnabled, redactIndexedDBEntry } from "./utils";

// Add more indexedDB redaction functions types here
type RedactorEntries = [
  RedactionEntry<typeof stacksGetRecentActivityRedacted.returnType>,
  RedactionEntry<typeof dcsGetCalendarEventsRedacted.returnType>,
  RedactionEntry<typeof dashFeedListActivityFeedRedacted.returnType>,
];

// Add more indexedDB redaction functions here
const INDEXED_DB_REDACTIONS: RedactorEntries = [
  {
    key: stacksGetRecentActivityRedacted.indexedDbKey,
    redactor: stacksGetRecentActivityRedacted.indexedDbRedactor,
  },
  {
    key: dcsGetCalendarEventsRedacted.indexedDbKey,
    redactor: dcsGetCalendarEventsRedacted.indexedDbRedactor,
  },
  {
    key: dashFeedListActivityFeedRedacted.indexedDbKey,
    redactor: dashFeedListActivityFeedRedacted.indexedDbRedactor,
  },
];

// Add more network response redaction middleware here
const METICULOUS_NETWORK_REDACTION_MIDDLEWARE: RecorderMiddleware[] = [
  stacksGetRecentActivityRedacted.createMiddleware(),
  dcsGetCalendarEventsRedacted.createMiddleware(),
  dashFeedListActivityFeedRedacted.createMiddleware(),
];

const INDEXED_DB_REDACTOR: RecorderMiddleware = {
  transformIndexedDBEntries: (data) => {
    if (
      data.databaseName !== "DropboxKeyValueStore" ||
      data.objectStoreName !== "KeyValue"
    ) {
      return data;
    }
    return {
      ...data,
      entries: data.entries.map((entry) =>
        INDEXED_DB_REDACTIONS.reduce<IDBEntry>(
          (e, { key, redactor }) =>
            redactIndexedDBEntry(
              e,
              key,
              // This cast is safe because we know each entry in INDEXED_DB_REDACTIONS
              // has a redactor that takes an type T and returns that same type T.
              redactor as (parsed: unknown) => unknown,
            ),
          entry,
        ),
      ),
    };
  },
};

const METICULOUS_TOKEN_REDACTION_MIDDLEWARE: RecorderMiddleware[] = [
  dropRequestHeader("Authorization"),
  {
    transformLocalStorageEntry: (entry) => {
      if (entry.key === "authentication") {
        // Note: dropping the authentication entry altogether
        // will cause replay to fail because the frontend JS
        // redirects to login if the entry doesn't exist.
        // So we redact instead.
        return {
          ...entry,
          value: JSON.stringify({
            ...JSON.parse(entry.value),
            accessToken: "REDACTED",
            codeVerifier: "REDACTED",
            refreshToken: "REDACTED",
          }),
        };
      }
      return entry;
    },
  },
];

const METICULOUS_REDACTION_MIDDLEWARE: RecorderMiddleware[] = [
  INDEXED_DB_REDACTOR,
  ...METICULOUS_TOKEN_REDACTION_MIDDLEWARE,
  ...METICULOUS_NETWORK_REDACTION_MIDDLEWARE,
];

export const initMeticulous = async () => {
  // Initialize Meticulous
  try {
    if (isMeticulousEnabled()) {
      await tryLoadAndStartRecorder({
        recordingToken: "NDR3OSPR97prOHZRNkDsNHFRwo2btEjshI2kzreG",
        isProduction: false,
        middleware: METICULOUS_REDACTION_MIDDLEWARE,
      });
    }
  } catch (err) {
    console.error(`Meticulous failed to initialize ${err}`);
  }
};
