import { FolderLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import {
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import {
  isDesktopLocalFile,
  isRecordTypeTagEvent,
} from '@mirage/shared/search/search-result';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { useResizeObserver } from '@react-hookz/web';
import classNames from 'classnames';
import { PropsWithChildren, useRef, useState } from 'react';
import { getCalendarSubtitle } from '../util/resultUtil';
import { DefaultMetadata } from './DefaultMetadata';
import { LocalFileMetadata } from './LocalFileMetadata';
import { MetadataItem } from './MetadataItem';
import styles from './ResultSubtitle.module.css';
import { TruncatedFilePath } from './TruncatedFilePath';

import type { SearchResult } from '@mirage/service-dbx-api';

type ResultSubtitleProps = {
  result: SearchResult;
  onClickPerson?: (person: PersonObject) => void;
  activeFilters?: SearchFilter[];
};

const MetadataContainer = ({
  isMobileSize,
  children,
}: PropsWithChildren<{ isMobileSize: boolean }>) => {
  return (
    <div
      className={classNames(styles.metadataContainer, {
        [styles.isMobile]: isMobileSize,
      })}
    >
      {children}
    </div>
  );
};

export const ResultSubtitle = ({
  result,
  onClickPerson,
  activeFilters,
}: ResultSubtitleProps) => {
  const isMobileSize = useIsMobileSize();

  const subtitleRef = useRef<HTMLDivElement>(null);

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [tooltipHover, setTooltipHover] = useState(false);
  const [metadataHover, setMetadataHover] = useState(false);

  const isVirtualPathEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_11_13_search_results_virtual_path'),
  );
  const handleResize = () => {
    if (!subtitleRef.current) return;
    const { scrollWidth, clientWidth } = subtitleRef.current;
    setIsOverflowing(scrollWidth > clientWidth);
  };
  useResizeObserver(subtitleRef, handleResize);

  const renderContent = (isTooltipContent?: boolean) => {
    if (isRecordTypeTagEvent(result)) {
      return <MetadataItem title={getCalendarSubtitle(result)} />;
    }

    if (isDesktopLocalFile(result)) {
      return (
        <LocalFileMetadata
          result={result}
          isOverflowing={isOverflowing}
          isTooltipContent={isTooltipContent}
        />
      );
    }
    if (
      isVirtualPathEnabled &&
      result.virtualPath &&
      result.virtualPath.length > 0
    ) {
      const metadataTitle = isTooltipContent ? (
        result.virtualPath.join(' > ')
      ) : (
        <TruncatedFilePath
          isOverflowing={isOverflowing}
          pathSegments={result.virtualPath}
        />
      );
      return <MetadataItem title={metadataTitle} iconSrc={FolderLine} />;
    }

    return (
      <DefaultMetadata
        result={result}
        onClickPerson={onClickPerson}
        activeFilters={activeFilters}
      />
    );
  };

  return (
    <>
      {isOverflowing && (
        <DigTooltip.Control
          open={tooltipHover || metadataHover}
          triggerRef={subtitleRef}
          placement="top"
          openDelay={300}
          onMouseEnter={() => setTooltipHover(true)}
          onMouseLeave={() => setTooltipHover(false)}
          // Allows user to mvoe their mouse onto the tooltip without it disappearing
          style={{ background: 'transparent' }}
        >
          <MetadataContainer isMobileSize={isMobileSize}>
            {renderContent(true)}
          </MetadataContainer>
        </DigTooltip.Control>
      )}
      <div
        ref={subtitleRef}
        onMouseEnter={() => setMetadataHover(true)}
        onMouseLeave={() => setMetadataHover(false)}
        className={classNames(styles.container, {
          [styles.fadeout]: isOverflowing,
        })}
      >
        <MetadataContainer isMobileSize={isMobileSize}>
          {renderContent()}
        </MetadataContainer>
      </div>
    </>
  );
};
