import { asterixOut, transformJsonResponse } from "@alwaysmeticulous/redaction";
import { RecorderMiddleware } from "@alwaysmeticulous/sdk-bundles-api";
import { dash, dcs } from "@dropbox/api-v2-client";
import { StoredCalendarEvents } from "@mirage/service-calendar-events/service";

import {
  DEFAULT_REDACTED_URL,
  createRedactedApiV2Endpoint,
  redactArray,
} from "../utils";

export const redactEvent = (event: dash.SearchResult) => {
  return {
    ...event,
    title: event.title ? asterixOut(event.title) : undefined,
    url: event.url ? DEFAULT_REDACTED_URL : undefined,
    description: event.description ? asterixOut(event.description) : undefined,
  };
};

export const dcsGetCalendarEventsRedacted =
  createRedactedApiV2Endpoint<StoredCalendarEvents>(
    "dcsGetCalendarEvents",
    "dcs",
    "get_calendar_events",
    "calendar-events",
    (data: StoredCalendarEvents): StoredCalendarEvents => {
      return {
        ...data,
        events: redactArray(data?.events, redactEvent),
      };
    },
    (): RecorderMiddleware => {
      return transformJsonResponse<dcs.GetCalendarEventsResponse>({
        urlRegExp: dcsGetCalendarEventsRedacted.urlRegExp,
        transform: (data) => ({
          ...data,
          events: redactArray(data?.events, redactEvent),
        }),
      });
    },
  );
