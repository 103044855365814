import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { useTheme } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashConnectAppPage } from '@mirage/analytics/events/types/open_dash_connect_app_page';
import { PAP_Shown_DashEmptyStateComponent } from '@mirage/analytics/events/types/shown_dash_empty_state_component';
import { useConnectorsUI } from '@mirage/mosaics/SettingsPage/useConnectorsUI';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useEffect, useRef } from 'react';
import styles from './ActivityListEmptyState.module.css';

const DARK_BACKGROUND_IMAGE =
  'https://assets.dropbox.com/images/dashweb/emptystates/activity_feed_empty_state_dark.png';

const BACKGROUND_IMAGE =
  'https://assets.dropbox.com/images/dashweb/emptystates/activity_feed_empty_state.png';

type Props = {
  className?: string;
};

export const ActivityListEmptyStateAddApps = ({ className }: Props) => {
  const { mode } = useTheme();
  const isDarkMode = mode === 'dark';
  const { openConnectors } = useConnectorsUI();
  const loggedExposure = useRef(false);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const panelSrc = isDarkMode ? DARK_BACKGROUND_IMAGE : BACKGROUND_IMAGE;

  const handleAddApps = () => {
    reportPapEvent(
      PAP_Open_DashConnectAppPage({
        actionSurfaceComponent: 'activity_feed',
        connectAppType: 'all_apps',
        featureLine: 'activity_feed',
      }),
    );
    openConnectors();
  };

  useEffect(() => {
    if (!loggedExposure.current) {
      loggedExposure.current = true;
      reportPapEvent(
        PAP_Shown_DashEmptyStateComponent({
          dashActionSurface: 'start_page',
          actionSurfaceComponent: 'activity_feed',
        }),
      );
    }
  }, [reportPapEvent]);

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.assetContainer}>
        <img className={styles.asset} alt="" src={panelSrc} />
      </div>
      <div className={styles.textContentContainer}>
        <div>
          <Text className={styles.message} size="medium" tagName="p">
            {i18n.t('activity_feed_empty_state_add_apps_text')}
          </Text>
          <Button variant="opacity" onClick={handleAddApps}>
            {i18n.t('add_apps')}
          </Button>
        </div>
      </div>
    </div>
  );
};
