import { StackDerivedPAPProps } from '@mirage/analytics/AnalyticsProvider';
import { StackAccessLevel } from '@mirage/analytics/events/enums/stack_access_level';
import { getMemCacheFeatureValue } from '@mirage/service-experimentation/service/memCache';
import {
  StackFilterOption,
  StackSortDirection,
  StackSortOption,
  StackSortPreference,
} from '@mirage/service-settings/service/types';
import { ONE_SECOND_IN_MILLIS } from '@mirage/shared/util/constants';
import { isDefined, nonEmpty, nonNil } from '@mirage/shared/util/tiny-utils';
import { urlToDomainName } from '@mirage/shared/util/urlRulesUtil';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { nanoid } from 'nanoid';
import { getTitleFromDomain, parseDropboxMetadataTitle } from './url-title';

import type { stacks } from '@dropbox/api-v2-client';
import type { LastViewedStackInfo } from '@mirage/service-recent-content/types';

export function stackGetShareId(
  stackOrUrl: stacks.Stack | string | undefined,
): string | undefined {
  if (
    stackOrUrl &&
    typeof stackOrUrl !== 'string' &&
    stackOrUrl?.publish_data?.is_published
  ) {
    // use sharing_id for company pinned stacks; likely that we can do this beyond just company pinned stacks; but opt
    // for a more targeted approach
    return stackOrUrl.sharing_id;
  }

  const url =
    typeof stackOrUrl === 'string'
      ? stackOrUrl
      : stackOrUrl?.sharing_data?.shared_link?.url;

  if (url?.length) {
    // Strip everything after '?' and '#'.
    // Return the string that comes after the last '/' (if any).
    return url.split('?')[0].split('#')[0].split('/').pop();
  }

  return undefined;
}

export function stackHasShareId(stack: stacks.Stack, shareId: string): boolean {
  const url = stack?.sharing_data?.shared_link?.url;
  return Boolean(
    url &&
      url.endsWith(shareId) &&
      url.charAt(url.length - shareId.length - 1) === '/',
  );
}

/**
 * Temporary function to check if the link enrichment feature flag is enabled.
 * Stores the result in local storage, since getMemCacheFeatureValue is delayed
 * on page load. TODO Delete this function once dash_2024_07_29_link_enrichment is removed.
 * window.localStorage does not work in Hornet, so this will always check the
 * memCache in Hornet.
 * @param timeLastUpdated
 * @param setTimeLastUpdate
 * @returns
 */
export const isLinkEnrichmentEnabled = (
  timeLastUpdated: number,
  setTimeLastUpdate: (newTime: number) => void,
) => {
  const isWindowDefined = typeof window !== 'undefined';
  let value: string | null = null;
  if (isWindowDefined) {
    value = window.localStorage.getItem(
      'dash_2024_07_29_link_enrichment_value',
    );
  }
  // If local storage was last updated over 30 seconds ago, or its
  // undefined, update it to the latest value of the flag.
  if (!value || Date.now() > timeLastUpdated + ONE_SECOND_IN_MILLIS * 30) {
    const memCacheValue =
      getMemCacheFeatureValue('dash_2024_07_29_link_enrichment') === 'ON'
        ? 'ON'
        : 'OFF';

    if (isWindowDefined) {
      window.localStorage.setItem(
        'dash_2024_07_29_link_enrichment_value',
        memCacheValue,
      );
    }
    setTimeLastUpdate(Date.now());
    value = memCacheValue;
  }

  return value === 'ON';
};

// This is defined in memory, so this is the the timestamp when the
// feature flag was last updated for this context, usually per tab.
// On load, set to Date.now() so that we do not try to update local storage
// until 30 seconds have passed, giving the feature flag mem cache time to update.
let linkEnrichmentFeatureFlagLastUpdatedLocalStorage: number = Date.now();

export function stackItemComputeNameFromFields(item: {
  url?: string;
  name?: string;
  custom_name?: string;
  metadata_title?: string;
}): string {
  if (item.custom_name && item.custom_name !== '') {
    return item.custom_name;
  }
  const linkEnrichmentEnabled = isLinkEnrichmentEnabled(
    linkEnrichmentFeatureFlagLastUpdatedLocalStorage,
    (newTime) => {
      linkEnrichmentFeatureFlagLastUpdatedLocalStorage = newTime;
    },
  );

  if (!linkEnrichmentEnabled) {
    return stripStackItemFileExtension(item.name ?? '');
  }
  return stripStackItemFileExtension(computeMetadataTitle(item));
}

export const STACK_ITEM_FILE_EXTENSION = '.stackitem';

export function stackItemGetName(
  item: stacks.StackItem | string,
  // TODO (yong): This shouldn't be passed in because it may be dependent on filter/searches
  // when this NEEDS to be the full spectrum of available stacks, because we're using it to
  // resolve stack names from stack links.
  allStacks?: stacks.Stack[],
): string {
  let name;
  if (typeof item === 'string') {
    name = item;
  } else {
    const stackItemShortcut = asShortcut(item);
    name = stackItemComputeNameFromFields(stackItemShortcut);

    // Unfurl Stack links to get the name of the stack
    if (
      !stackItemShortcut.custom_name &&
      (stackItemShortcut.url?.startsWith('https://www.dash.ai/stacks/') ||
        stackItemShortcut.url?.startsWith('https://dash.ai/stacks/')) &&
      allStacks
    ) {
      const shareId = stackGetShareId(stackItemShortcut.url);
      // If the link is a stack link, try to find the name of the stack
      const stackName = allStacks.find(
        (stack) => stackGetShareId(stack) === shareId,
      )?.stack_data?.name;
      name = stackName ?? name;
    }
  }

  return stripStackItemFileExtension(name);
}

const stripStackItemExtensionRegex = new RegExp(
  `(${STACK_ITEM_FILE_EXTENSION})+$`,
);

/**
 * Strips all .stackitem extensions from the end of a string
 * @param name
 * @returns
 */
export function stripStackItemFileExtension(name: string): string {
  return name.replace(stripStackItemExtensionRegex, '');
}

export function stackItemSetName(
  item: stacks.StackItem,
  customName: string,
): stacks.StackItem {
  const shortcut = asShortcut(item);
  shortcut.custom_name = customName;
  return shortcut;
}

export function computeMetadataTitle(item: {
  url?: string;
  name?: string;
  metadata_title?: string;
}): string {
  const effectiveTitle = item.metadata_title || item.name || '';

  if (!item.url) {
    // If url doesn't exist (shouldn't happen!) fall back on other fields
    return effectiveTitle;
  }
  const url = item.url.trim();

  // Domain specific parsing
  const domainName = urlToDomainName(url);
  switch (domainName) {
    case 'www.dropbox.com':
      // Dropbox specific parsing
      return parseDropboxMetadataTitle(url, effectiveTitle);
  }

  // If effective title is empty string, fall back to link parsing
  return effectiveTitle || getTitleFromDomain(url) || url;
}

/** Reference `dropbox/dbpath.py` function `is_valid_path_char()` in python.  */
export function isValidPathChar(char: string): boolean {
  if (char === '\\') return false;

  const c = char.codePointAt(0) ?? 0;
  return (
    c !== 0 && !(c >= 0xd800 && c <= 0xdfff) && !(c >= 0xfffe && c <= 0x10ffff)
  );
}

export function isValidFileNameChar(char: string): boolean {
  return isValidPathChar(char) && char !== '/';
}

export function toValidFileName(s: string, replacement = ' '): string {
  let t = '';
  for (const c of s) {
    t += isValidFileNameChar(c) ? c : replacement;
  }
  return t.trim();
}

export function asShortcut(item: stacks.StackItem): stacks.StackItemShortcut {
  if (item['.tag'] !== 'shortcut') {
    throw new Error('Item is not a shortcut');
  }
  return item;
}

export function asShortcuts(
  items: stacks.StackItem[],
): stacks.StackItemShortcut[] {
  return items.map(asShortcut);
}

const sortKeyCompare = (a: string, b: string) => {
  return a < b ? -1 : a > b ? 1 : 0;
};

export function maxSortKey(pinnedStacks: stacks.Stack[]) {
  const sortKeys = pinnedStacks
    .map((stack) => stack.user_data?.pinned_sort_key)
    .filter(isDefined);
  return sortKeys.length > 0
    ? sortKeys.reduce((a, b) => (sortKeyCompare(a, b) > 0 ? a : b))
    : null;
}

export function sortStacksByPinStatusAndHlc(
  stacks: stacks.Stack[],
  sortPreference?: StackSortPreference,
  lastViewedInfo?: LastViewedStackInfo,
): stacks.Stack[] {
  return orderBy(
    stacks,
    [
      (stack) => {
        if (lastViewedInfo && (stack.namespace_id ?? '') in lastViewedInfo) {
          return lastViewedInfo[stack.namespace_id ?? ''];
        }
        return 0;
      },
      (stack) => stack.user_data?.is_pinned,
      (stack) => {
        if (stack.user_data?.is_pinned) {
          return stack.user_data?.pinned_sort_key;
        }
      },
      (stack) => {
        if (stack.user_data?.is_pinned) {
          return stack.user_data?.pinned_ms;
        }
      },
      (stack) => stack.max_hlc_micros ?? 0,
    ],
    [
      sortPreference?.direction !== StackSortDirection.ASC ? 'desc' : 'asc',
      'desc',
      'asc',
      'asc',
      'desc',
    ],
  );
}

export function sortStacksBySortOption(
  stacks: stacks.Stack[],
  meaningfulLastUpdateFeatureEnabled: boolean,
  sortPreference?: StackSortPreference,
  lastViewedStackInfo?: LastViewedStackInfo,
) {
  const { option, direction } = sortPreference ?? {};
  switch (option) {
    case StackSortOption.ALPHA:
      return orderBy(
        stacks,
        [
          (stack) => stack.stack_data?.name?.toLowerCase() ?? '',
          (stack) => stack.max_hlc_micros ?? 0,
        ],
        [direction === StackSortDirection.ASC ? 'asc' : 'desc', 'desc'],
      );
    case StackSortOption.RECENT: {
      return orderBy(
        stacks,
        [
          (stack) =>
            meaningfulLastUpdateFeatureEnabled
              ? stack.last_modified_time_utc_sec || stack.max_hlc_micros || 0
              : stack.max_hlc_micros || stack.last_modified_time_utc_sec || 0,
          (stack) => stack.stack_data?.name?.toLowerCase() ?? '',
        ],
        [direction === StackSortDirection.DESC ? 'desc' : 'asc', 'asc'],
      );
    }
    case StackSortOption.COMPANY_AND_VIEWED: {
      if (!lastViewedStackInfo) {
        // eslint-disable-next-line no-console
        console.error('Missing last viewed data for Last Viewed sort option');
      }

      return orderBy(
        stacks,
        [
          (stack) => !!stack?.publish_data?.is_published,
          (stack) => {
            // We will sort unread/not-viewed info first for published stacks, then by recency viewed
            // But unpublished stacks will be sorted by recency viewed, with not-viewed last
            const isPublished = !!stack?.publish_data?.is_published;
            if (
              lastViewedStackInfo &&
              (stack.namespace_id ?? '') in lastViewedStackInfo
            ) {
              return lastViewedStackInfo[stack.namespace_id ?? ''];
            }
            // If it's published we set time viewed to be infinity so it appears before all the other ones
            // we've already group / sorted by published vs non-published in prior predicate
            return isPublished ? Infinity : 0;
          },
          (stack) => stack.max_hlc_micros ?? 0,
        ],
        ['desc', 'desc', 'desc', 'desc'],
      );
    }
    case StackSortOption.VIEWED: {
      if (!lastViewedStackInfo) {
        // TODO: Using tagged `logger` here breaks potentially outdated tests in Scout.
        // Revert this once fixed, or code/tests removed
        // eslint-disable-next-line no-console
        console.error('Missing last viewed data for Last Viewed sort option');
      }
      return sortStacksByPinStatusAndHlc(
        stacks,
        sortPreference,
        lastViewedStackInfo,
      );
    }
    default:
      return sortStacksByPinStatusAndHlc(stacks);
  }
}

function getStackItemSortKey(item: stacks.StackItem): string {
  const shortcut = asShortcut(item);

  // Fallback to sort by name.
  return shortcut.sort_key ?? stackItemComputeNameFromFields(shortcut) ?? '';
}

export function sortStackItemsBySortKey(
  items: stacks.StackItem[],
): stacks.StackItem[] {
  items.sort((a, b) => {
    const key1 = getStackItemSortKey(a);
    const key2 = getStackItemSortKey(b);

    if (key1 > key2) {
      return 1;
    }
    if (key1 < key2) {
      return -1;
    }
    return 0;
  });

  return items;
}

export const ALWAYS_TRUE = () => true;

export const isOwnedStack = (stack: stacks.Stack) =>
  stack.permission?.['.tag'] === 'owner';

export const isSharedStack = (stack: stacks.Stack) =>
  stack.permission?.['.tag'] !== 'owner' && !isCompanyPublished(stack);

export const isArchivedStack = (stack: stacks.Stack) =>
  stack.stack_data?.archive_data?.is_archived ?? false;

// equivalent to "company pinned" stack
export const isCompanyPublished = (stack: stacks.Stack) =>
  stack?.publish_data?.is_published === true;

export function getPredicateFromFilterPreference(
  filterPreference?: StackFilterOption,
): (stack: stacks.Stack) => boolean {
  switch (filterPreference) {
    case StackFilterOption.MINE:
      return (stack: stacks.Stack) =>
        isOwnedStack(stack) && !isArchivedStack(stack);
    case StackFilterOption.SHARED:
      return (stack: stacks.Stack) =>
        isSharedStack(stack) && !isArchivedStack(stack);
    case StackFilterOption.ARCHIVED:
      return isArchivedStack;
    case StackFilterOption.COMPANY:
      return isCompanyPublished;
    default:
      return (stack: stacks.Stack) => !isArchivedStack(stack);
  }
}

const convertStackAccessLevelToPapAccessLevel = (
  accessLevel: stacks.StackSharedLinkAccessLevel | undefined,
): StackAccessLevel => {
  switch (accessLevel?.['.tag']) {
    case 'public':
      return 'public';
    case 'team':
      return 'team';
    default:
      return 'invited';
  }
};

export const stackDerivePAPProps = (
  stack: stacks.Stack,
): StackDerivedPAPProps => {
  const members = stack?.sharing_data?.members || [];
  const numSharedUsers = members.length < 2 ? 0 : members.length - 1;
  return {
    // backend will replace local stack ID with namespace ID if necessary.
    stackId: nonNil(stack.namespace_id, 'namespace_id'),
    isOwner: stack.permission?.['.tag'] === 'owner',
    isShared:
      members?.length > 1 || // the owner is always in the members list
      stack.permission?.['.tag'] !== 'owner',
    isPinned: stack.user_data?.is_pinned ?? false,
    createdTs: stack.created_time_utc_ms,
    lastModifiedTs: stack.last_modified_time_utc_sec,
    isAutoGenerated:
      stack.stack_data?.creation_type?.['.tag'] === 'suggested_stack',
    numSharedUsers,
    numSections: stack.stack_data?.section_data?.sections?.length ?? 1, // default to 1 if no sections
    stackAccessLevel: convertStackAccessLevelToPapAccessLevel(
      stack.sharing_data?.shared_link?.access_level,
    ),
    isWelcomeStack:
      stack.stack_data?.creation_type?.['.tag'] === 'welcome_stack',
    isPublished: stack.publish_data?.is_published,
    isArchived: stack.stack_data?.archive_data?.is_archived ?? false,
    isCloned: stack.stack_data?.creation_type?.['.tag'] === 'cloned_stack',
  };
};

export const DEFAULT_SECTION_ID = 'default_stack_section';

export const DEFAULT_SECTION: stacks.Section = {
  id: DEFAULT_SECTION_ID,
  title: '',
};

export const createNewSection = (
  title: string,
): stacks.Section & { id: string; title: string } => {
  return { id: nanoid(), title };
};

export const createDefaultSectionData = (): stacks.SectionData => {
  return { sections: [DEFAULT_SECTION] };
};

export function stackForDiffing(stack: stacks.Stack): stacks.Stack {
  if (!stack.bolt_data?.bolt_token) {
    return stack;
  }

  // The bolt_token changes on every fetch, so ignore it for diffing.
  const newStack = cloneDeep(stack);
  if (newStack.bolt_data?.bolt_token) {
    delete newStack.bolt_data.bolt_token;
  }
  return newStack;
}

/**
 * Delete keys that don't affect rendered data
 * This should only be used in UI components
 */
export function stackForDiffingForOnlyRenderedData(
  stack: stacks.Stack,
): stacks.Stack {
  const newStack = cloneDeep(stack);
  if (newStack.bolt_data) {
    delete newStack.bolt_data;
  }
  if (newStack.last_modified_time_utc_sec) {
    delete newStack.last_modified_time_utc_sec;
  }
  if (newStack.max_hlc_micros) {
    delete newStack.max_hlc_micros;
  }
  if (newStack.latest_stack_cache_id) {
    delete newStack.latest_stack_cache_id;
  }

  return newStack;
}

export function stacksForDiffing(stacks: stacks.Stack[] | undefined):
  | {
      [nsId: string]: stacks.Stack;
    }
  | undefined {
  if (stacks === undefined) {
    return undefined;
  }
  return keyBy(
    stacks.map((stack) => stackForDiffing(stack)),
    (s) => s.namespace_id ?? '',
  );
}

export const isStackItemShortcut = (
  item: stacks.StackItem,
): item is stacks.StackItemShortcut => {
  return item['.tag'] === 'shortcut';
};

// If stacks.StackItem has a union type added to it, make sure to update this!
// Whenever a new field is added to stacks.StackItem or one of its subtypes,
// this ensures the fields that we reduce to are exhaustive.
const sampleStackItem: Required<stacks.StackItem> = {
  '.tag': 'shortcut',
  name: '',
  url: '',
  last_modified_time_utc_sec: 0,
  latest_cache_id: '',
  api_file_id: '',
  hlc_micros: 0,
  creator: {},
  parent_section_id: '',
  sort_key: '',
  description: {},
  description_creator: {},
  custom_name: '',
  metadata_title: '',
  id_3p: '',
  branded_type: '',
};

// Sometimes a stacks.StackItem comes populated with metadata title fields.
// This strips the metadata and reduces a stackItem back to its fields.
export function reduceToStackItem<T extends stacks.StackItem>(
  obj: T,
): stacks.StackItem {
  const keysToKeep = Object.keys(obj).filter((key) => key in sampleStackItem);
  const x = pick(obj, keysToKeep) as stacks.StackItem;
  return x;
}

export const isStackItemCreator = (
  userEmail: string | undefined,
  item: stacks.StackItem,
): boolean => {
  if (!isStackItemShortcut(item)) {
    return false;
  }
  return Boolean(item.creator?.email && item.creator.email === userEmail);
};

export const getStackItemChangedFields = (
  newStackItem: stacks.StackItem,
  oldStackItem?: stacks.StackItem,
): Partial<stacks.StackItemShortcut> => {
  const newStackItemReduced = asShortcut(reduceToStackItem(newStackItem));
  const oldStackItemReduced = oldStackItem
    ? reduceToStackItem(oldStackItem)
    : { '.tag': 'shortcut' };
  return pickBy(newStackItemReduced, (value, key) => {
    return !isEqual(value, oldStackItemReduced[key as keyof stacks.StackItem]);
  });
};

export const getStackItemChangedFieldsFromOldItems = (
  newItem: stacks.StackItem,
  oldItems: stacks.StackItem[],
): Partial<stacks.StackItemShortcut> => {
  const fileId = nonEmpty(asShortcut(newItem).api_file_id, 'fileId');
  const oldItem = oldItems.find(
    (item) => asShortcut(item).api_file_id === fileId,
  );
  return {
    ...getStackItemChangedFields(newItem, oldItem),
    '.tag': 'shortcut',
    api_file_id: fileId,
  };
};
