/*
 * Note: This is non-end-user facing code so no need for i18n strings.
 */

import { Checkbox } from '@dropbox/dig-components/controls';
import { FormLabel } from '@dropbox/dig-components/form_row';
import { Text } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import {
  disableUxaDevtools,
  enableUxaDevtools,
  isUxaDevtoolsEnabled,
} from '@mirage/analytics/uxa/devtools';
import { useComponentConfig } from '@mirage/component-config';
import { getInstallId, getSessionId } from '@mirage/service-auth';
import useShouldUseStageBackend from '@mirage/service-auth/useShouldUseStageBackend';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import useSettings from '@mirage/service-settings/useSettings';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { additionalDeveloperSettingsAtom } from './atoms/additionalDeveloperSettings';
import { InternalTests } from './InternalTests';
import { IpcProfiling } from './IpcProfiling';
import { OperationalMetrics } from './OperationalMetrics';
import {
  isExtensionInstalled,
  useExtensionConnectionId,
} from './utils/webExtensionsHelpers';

const CheckBoxAndLabelRow: React.FC<{
  checked: boolean;
  onChange: () => void;
  labelText: string;
}> = ({ checked, onChange, labelText }) => {
  const [id, setId] = useState('');

  useEffect(() => {
    setId(uuidv4());
  }, []);

  return (
    <Box display="flex" paddingBottom="16">
      <Checkbox id={id} checked={checked} onChange={onChange} />

      <FormLabel htmlFor={id}>
        &nbsp;&nbsp;
        <Text>{labelText}</Text>
      </FormLabel>
    </Box>
  );
};

export const DeveloperSettings: React.FC = () => {
  const { analyticsLogsOpen, toggleAnalyticsLogs } = useComponentConfig();
  const [openUxaTools, setOpenUxaTools] = useState(isUxaDevtoolsEnabled());
  const [shouldUseStageBackend, setShouldUseStageBackend] =
    useShouldUseStageBackend();
  const { settings, set: setSettings } = useSettings(['annotationMode']);
  const annotationMode = !!settings?.annotationMode;
  const { headers } = useAtomValue(additionalDeveloperSettingsAtom);
  const [installId, setInstallId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const extensionInstalled = isExtensionInstalled();
  const connectionId = useExtensionConnectionId();

  useEffect(() => {
    getInstallId().then(setInstallId);
    getSessionId().then(setSessionId);
  }, []);

  const toggleUxaTools = useCallback(() => {
    const isEnabled = isUxaDevtoolsEnabled();
    if (isEnabled) {
      disableUxaDevtools();
      setOpenUxaTools(false);
    } else {
      enableUxaDevtools();
      setOpenUxaTools(true);
    }
  }, []);

  const toggleUseStageBackend = useCallback(() => {
    setShouldUseStageBackend(!shouldUseStageBackend);
  }, [setShouldUseStageBackend, shouldUseStageBackend]);

  const toggleAnnotationMode = () => {
    setSettings('annotationMode', annotationMode ? 0 : 1);
  };

  const isDesktop = EnvCtx.surface === 'desktop';

  return (
    <>
      <Box display="block" paddingBottom="60">
        <Stack gap="12" paddingBottom="12">
          <Box display="block">
            <Text isBold style={{ userSelect: 'text' }}>
              Version: {EnvCtx.version} · Surface: {EnvCtx.surface} · Platform:{' '}
              {EnvCtx.platform} · Build channel: {EnvCtx.buildChannel}
            </Text>
          </Box>
          <Box display="block" style={{ userSelect: 'text' }}>
            <Text isBold style={{ userSelect: 'text' }}>
              Install ID: {installId}
            </Text>
          </Box>
          <Box display="block">
            <Text isBold style={{ userSelect: 'text' }}>
              Session ID: {sessionId}
            </Text>
          </Box>
          {extensionInstalled && (
            <Box display="block">
              <Text isBold style={{ userSelect: 'text' }}>
                Connection ID: {connectionId}
              </Text>
            </Box>
          )}
        </Stack>
        {headers.map((Component, index) => (
          <Box
            key={index}
            display="block"
            paddingBottom="12"
            style={{ userSelect: 'text' }}
          >
            <Component />
          </Box>
        ))}
        <CheckBoxAndLabelRow
          checked={analyticsLogsOpen}
          onChange={toggleAnalyticsLogs}
          labelText="Show Analytics Viewer"
        />
        <CheckBoxAndLabelRow
          checked={openUxaTools}
          onChange={toggleUxaTools}
          labelText="Show UXA Devtool"
        />
        <CheckBoxAndLabelRow
          checked={shouldUseStageBackend}
          onChange={toggleUseStageBackend}
          labelText="Staging API endpoints"
        />
        <CheckBoxAndLabelRow
          checked={annotationMode}
          onChange={toggleAnnotationMode}
          labelText="Enable annotation mode"
        />
        <Box
          display="flex"
          paddingTop="16"
          paddingBottom="16"
          style={{ userSelect: 'text' }}
        >
          <OperationalMetrics />
        </Box>
        <Box display="flex" paddingTop="16" paddingBottom="16">
          <IpcProfiling />
        </Box>
        {isDesktop && (
          <Box display="flex" paddingTop="16" paddingBottom="16">
            <InternalTests />
          </Box>
        )}
      </Box>
    </>
  );
};
