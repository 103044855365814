import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import get from 'lodash/get';
import { PAPEvent } from 'pap-events/base/event';

const EVENTS_EXCLUDED_FROM_VALIDATION = [
  'exposure.stormcrow',
  'exposure.dash_growthbook',
  'finish.dash_login', // No need to verify global properties for this event as it's for login.
];

const UNIVERSAL_PROPERTIES = [
  'dashSurface',
  'dashActionSurface',
  'featureLine',
  'buildVersion',
  'environment',
  'deviceId',
  'operatingSystem',
  'startTimeMs',
];

export const enum EventCategory {
  STACKS = 'organize/stacks',
  ML = 'organize/ml',
}

const propertiesForCategory = (category: EventCategory): string[] => {
  switch (category) {
    case EventCategory.STACKS:
      return ['stackId', 'unhashedStackId'];
    case EventCategory.ML:
      return ['predictionIdHash'];
  }
};

const name = (event: PAPEvent) => `${event.action}.${event.object}`;

const WARNING_HAS_BEEN_SHOWN = new Set<string>();
const logger = tagged('pap');
const warn = (...args: [string, ...string[]]) => {
  Sentry.captureMessage(args.join(' '));
  logger.info(
    ...args,
    'If this event should be excluded from validation, update packages/service-product-logging/service/validation.ts',
  );
};

export const validateEvent = (event: PAPEvent) => {
  if (WARNING_HAS_BEEN_SHOWN.has(name(event))) {
    return;
  }

  if (EVENTS_EXCLUDED_FROM_VALIDATION.includes(name(event))) {
    return;
  }

  for (const path of UNIVERSAL_PROPERTIES) {
    if (event.properties && !(path in event.properties)) {
      warn(name(event), 'is missing universal property', path);
      WARNING_HAS_BEEN_SHOWN.add(name(event));
    }
  }

  const category = classifyEvent(event);
  if (category !== undefined) {
    for (const path of propertiesForCategory(category)) {
      const val = get(event.properties, path);
      if (!val) {
        warn(name(event), `is missing ${category} property`, path);
        WARNING_HAS_BEEN_SHOWN.add(name(event));
      }
    }
  }
};

export const classifyEvent = (event: PAPEvent): EventCategory | undefined => {
  if (
    event.object.includes('stack') &&
    // Events that include "stack in the name" but are not specific to a stack.
    ![
      'shown.stacks_page',
      'open.stacks_page',
      'click.dash_new_stack',
      'initiate.search_for_stack',
    ].includes(name(event)) &&
    event.properties?.actionSurface !== 'new_stack_page'
  ) {
    if (event.object === 'suggested_auto_stack') {
      return EventCategory.ML;
    }
    return EventCategory.STACKS;
  }

  return undefined;
};
