import { stacks } from '@dropbox/api-v2-client';

export const areStackItemsOrdered = (items: stacks.StackItemShortcut[]) => {
  if (!items.every((item) => !!item.sort_key)) {
    return false;
  }
  return new Set(items.map((item) => item.sort_key)).size === items.length;
};

export const canEditStack = (stack: stacks.Stack | null) => {
  // Can always edit a new stack, which is null
  if (stack === null) {
    return true;
  }

  const permission = stack?.permission?.['.tag'];

  return permission === 'write' || permission === 'owner';
};

// merges and aggregates user's stacks with company pinned stacks based on namespace id
// if the stack exists as both a user and company -> we merge the company's `publish_data` into the user stack
// else keep unique stack in both sets
export const mergeAndAggregateUniqueStacksByNamespaceId = (
  userStacks: stacks.Stack[],
  companyStacks: stacks.PublishedContentStack[],
) => {
  if (userStacks.length && !companyStacks.length) {
    return userStacks;
  }
  if (!userStacks.length && companyStacks.length) {
    return companyStacks;
  }

  const namespaceToUserStack = new Map(
    userStacks.map((stack) => [stack.namespace_id, stack]),
  );
  const namespaceToCompanyStack = new Map(
    companyStacks.map((stack) => [stack.namespace_id, stack]),
  );

  // We merge company stack's publish_data into user stacks since user stacks have more meta data (like sharing)
  const mergedStacksWithPublishData: stacks.Stack[] = userStacks.map(
    (userStack) => {
      const associatedStackData = namespaceToCompanyStack.get(
        userStack.namespace_id,
      );
      return associatedStackData?.publish_data
        ? {
            ...userStack,
            publish_data: associatedStackData?.publish_data,
            sharing_id: associatedStackData?.sharing_id,
          }
        : userStack;
    },
  );

  // Include any company stacks that aren't found in a user's stack
  // these stacks will not have sharing data
  const additionalPublishedStacksWithoutSharing = companyStacks.filter(
    ({ namespace_id }) => !namespaceToUserStack.has(namespace_id),
  );

  return [
    ...additionalPublishedStacksWithoutSharing,
    ...mergedStacksWithPublishData,
  ];
};

export const getIsPublicStack = (
  stack: stacks.Stack | null,
): boolean | undefined => {
  if (!stack) {
    return undefined;
  }
  return stack.sharing_data?.shared_link?.access_level?.['.tag'] === 'public';
};
