import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import {
  isUserIneligibleAtom,
  eligibleCheckDoneForPublicAccountIdAtom,
} from "@mirage/eligibility/useEligibilityCheck";
import { getLoginPathWithReturnRedirectURLParam } from "@mirage/service-auth";
import useDropboxAccount from "@mirage/service-auth/useDropboxAccount";
import { useIsLoggedIn } from "@mirage/service-auth/useDropboxAuthentication";
import { tagged } from "@mirage/service-logging";
import {
  isUsersFirstLogin,
  markDashUserFirstUseTimestamp,
} from "@mirage/service-onboarding";
import { hasUserCompletedOnboarding } from "@mirage/service-onboarding/hasUserCompletedOnboarding";
import { AppRoute, PageType } from "@mirage/webapp/routeTypes";
import { useAtomValue } from "jotai";

import { ROUTES } from "../Routes";

const logger = tagged("useAuthCheck");

export function useAuthCheck(route: AppRoute) {
  const navigate = useNavigate();
  const loggedIn = useIsLoggedIn();
  const account = useDropboxAccount();
  const eligibleCheckDoneForPublicAccountId = useAtomValue(
    eligibleCheckDoneForPublicAccountIdAtom,
  );
  const isUserIneligible = useAtomValue(isUserIneligibleAtom);
  const isUserCheckedAndEligible =
    eligibleCheckDoneForPublicAccountId !== undefined &&
    eligibleCheckDoneForPublicAccountId === account?.public_account_id &&
    !isUserIneligible;
  const [pendingEligibility, setPendingEligibility] = useState(true);

  // Extract properties from route because the `route` object changes on every
  // render, and should not be used as a useEffect dependency.
  const { requiresAuthentication, pageType } = route;

  useEffect(() => {
    const doPostLoginLogout = async () => {
      // If the user is not allowed to use the app, navigate to not found.
      if (loggedIn) {
        const pending = await onLoggedIn(
          pageType,
          navigate,
          isUserCheckedAndEligible,
          eligibleCheckDoneForPublicAccountId,
        );
        setPendingEligibility(pending);
      } else {
        // No need to await for completion.
        void onLoggedOut(pageType, navigate, requiresAuthentication);
        setPendingEligibility(false);
      }
    };
    doPostLoginLogout();
  }, [
    loggedIn,
    navigate,
    requiresAuthentication,
    pageType,
    isUserCheckedAndEligible,
    eligibleCheckDoneForPublicAccountId,
  ]);

  return { loggedIn, pendingEligibility };
}

// exported for testing
export async function onLoggedIn(
  pageType: PageType,
  navigate: NavigateFunction,
  isUserCheckedAndEligible: boolean,
  eligibleCheckDoneForPublicAccountId: string | undefined,
) {
  logger.debug("onLoggedIn() - pageType", pageType);
  // If the user is logged in and lands on the login page, navigate to main page.
  if (pageType === PageType.LOGIN) {
    logger.debug("onLoggedIn() - navigate to main page (pageType is LOGIN)");
    navigate(`${ROUTES.HOME.path}${window.location.search}`);
    return false;
  }

  if (pageType === PageType.LOGOUT) {
    return false;
  }

  // Eligible users on non-special Page Types
  const firstTimeUser = await isUsersFirstLogin(); // cached value

  logger.debug(
    "onLoggedIn() - is first time user =",
    firstTimeUser ? "true" : "false",
  );

  if (firstTimeUser && !eligibleCheckDoneForPublicAccountId) {
    return true;
  }

  if (isUserCheckedAndEligible && firstTimeUser) {
    if (pageType !== PageType.SETUP) {
      if (await hasUserCompletedOnboarding()) {
        await markDashUserFirstUseTimestamp();
      }
    }
  }
  return false;
}

async function onLoggedOut(
  pageType: PageType,
  navigate: NavigateFunction,
  requiresAuthentication: boolean,
) {
  logger.debug("onLoggedOut() - pageType", pageType);
  // User is not logged in.
  if (!requiresAuthentication) {
    logger.debug("onLoggedOut() - authentication not required");
    // Allow page to render.
    return;
  }

  if (pageType !== PageType.LOGIN) {
    const loginPath = await getLoginPathWithReturnRedirectURLParam();
    logger.debug("onLoggedOut() - navigating to new path", loginPath);
    navigate(loginPath);
  }
}
