// CSS imports will not affect JS tree shaking.
// eslint-disable-next-line no-restricted-imports
import '@dropbox/dig-components/dist/index.web.css';
import '@dropbox/dig-fonts/dist/index.min.css';
import '@dropbox/dig-fonts/dist/sharp_grotesk_23.min.css';
import '@dropbox/dig-foundations/dist/index.css';

import { MirageAnalyticsProvider } from '@mirage/analytics/AnalyticsProvider';
import { isAnalyticsViewerOpenAtom } from '@mirage/analytics/components/EventLog';
import {
  PAPEvent,
  PAPEventDefaultProperties,
} from '@mirage/analytics/events/base/event';
import { MirageComponentConfig } from '@mirage/component-config';
import { FullPageSpinner } from '@mirage/mosaics/FullPageSpinner';
import { AuthenticationStatus } from '@mirage/service-auth';
import useDropboxAuthentication from '@mirage/service-auth/useDropboxAuthentication';
import { tagged } from '@mirage/service-logging';
import { useInitPageLatencyWithinRouter } from '@mirage/service-operational-metrics/module/module';
import { DashThemeProvider } from '@mirage/service-settings/theming/DashThemeProvider';
import useSettings from '@mirage/service-settings/useSettings';
import { useDashFavicon } from '@mirage/shared/hooks/DashFavicon';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import { usePreviousPath } from '@mirage/shared/hooks/usePreviousPath';
import { ModalPortal } from '@mirage/shared/util/showModal';
import { reportEvent } from '@mirage/webapp/analytics/logging';
import {
  explicitAnswerDwellManager,
  explicitAnswersSessionManager,
  implicitAnswerDwellManager,
  searchAttemptManager,
  searchSessionManager,
} from '@mirage/webapp/analytics/sessionManagers/sessionManagers';
import { useAtom } from 'jotai';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AppShell.module.css';

const logger = tagged('AppShell');

/** Note: This component must be placed inside a react router. */
export const AppShell: React.FC = ({ children }) => {
  useTranslation(); // Re-render on language change
  useDashFavicon();
  useDashTitle();
  const { settings } = useSettings(['annotationMode']);
  const annotationMode = useRef(false);
  annotationMode.current = !!settings?.annotationMode;

  // In test mode, the app is started by beforeAll. See setupTests.ts
  const [isReady, setReady] = useState(false);

  const { status } = useDropboxAuthentication();
  const [isAnalyticsViewerOpen, setIsAnalyticsViewerOpen] = useAtom(
    isAnalyticsViewerOpenAtom,
  );

  useInitPageLatencyWithinRouter();

  // Empty hook to start tracking the previous path for logging purposes
  usePreviousPath();

  useEffect(() => {
    logger.debug('AppShell start');
  }, []);

  // Wait for anything that needs to be prepared
  // or loaded asynchronous here.
  useEffect(() => {
    if (
      status === AuthenticationStatus.None ||
      status === AuthenticationStatus.Complete
    ) {
      logger.debug(`setReady(true): status=${status}`);
      setReady(true);
    } else {
      logger.debug(`Not ready: status=${status}`);
    }
  }, [status]);

  // Because this callback is depended on via hooks in so many places, it is
  // crucial that it is wrapped in useCallback or otherwise has a stable
  // identity. Some places in the code assume it will _never_ change.
  const handleReportEvent = useCallback(async (event: PAPEvent) => {
    // Setting default properties that a majority of our PAP Events have defined
    const defaultProperties: PAPEventDefaultProperties = {
      dashAnnotationMode: annotationMode.current
        ? 'query_manual_labeling_v1'
        : undefined,
    };
    reportEvent(event, false, defaultProperties);
  }, []);

  return (
    <MirageAnalyticsProvider
      reportPapEvent={handleReportEvent}
      explicitAnswersSessionManager={explicitAnswersSessionManager}
      explicitAnswerDwellManager={explicitAnswerDwellManager}
      implicitAnswerDwellManager={implicitAnswerDwellManager}
      searchSessionManager={searchSessionManager}
      searchAttemptSessionManager={searchAttemptManager}
    >
      <MirageComponentConfig
        config={{
          includeDragbar: false,
          includeAIPanel: false,
          analyticsLogsOpen: isAnalyticsViewerOpen,
          toggleAnalyticsLogs: () => setIsAnalyticsViewerOpen((prev) => !prev),
        }}
      />
      <DashThemeProvider>
        <ModalPortal />
        {/* React.Suspense is required when using React.lazy(). */}
        <Suspense fallback={<FullPageSpinner spinnerId="suspense" />}>
          {isReady ? (
            <div className={styles.container}>{children}</div>
          ) : (
            <FullPageSpinner spinnerId="App_notReady" />
          )}
        </Suspense>
      </DashThemeProvider>
    </MirageAnalyticsProvider>
  );
};
