import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronDownLine } from '@dropbox/dig-icons/dist/mjs/assets';
import styled from '@emotion/styled';
import { useVariableLineHeight } from '@mirage/shared/hooks/useVariableLineHeight';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './Markdown.module.css';

interface MarkdownProps {
  body: string;
  truncateLine?: number;
  onToggleExpand?: () => void;
}

// don't allow images in markdown.
const disallowedElements = ['img'];

export const Markdown: React.FC<MarkdownProps> = ({
  body,
  truncateLine = 0,
  onToggleExpand,
}) => {
  const [isExpanded, setIsExpanded] = useState(!truncateLine);
  const { ref, overflowing } = useVariableLineHeight(truncateLine, body);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
    onToggleExpand?.();
  };

  const showInCollapsedState = !!truncateLine && overflowing && !isExpanded;

  return (
    <div className={styles.markdownRoot}>
      <TruncatedMarkdownContainer
        lineHeight="1.5"
        truncateLine={showInCollapsedState ? truncateLine : undefined}
        className={classnames(styles.markdownContainer, {
          [styles.markdownContainerCollapsed]: showInCollapsedState,
        })}
      >
        <Text size="medium" ref={ref}>
          <ReactMarkdown disallowedElements={disallowedElements}>
            {body}
          </ReactMarkdown>
        </Text>
      </TruncatedMarkdownContainer>
      {showInCollapsedState && (
        <div className={styles.showMoreContainer}>
          <Button
            variant="filled"
            onClick={toggleExpand}
            withIconStart={<UIIcon src={ChevronDownLine} />}
            size="small"
          >
            {i18n.t('show_more_answer')}
          </Button>
        </div>
      )}
    </div>
  );
};

const TruncatedMarkdownContainer = styled.div<{
  truncateLine?: number;
  lineHeight: string;
}>`
  line-height: ${(props) => props.lineHeight};
  max-height: ${(props) =>
    props.truncateLine
      ? `calc(${props.lineHeight}em * ${props.truncateLine})`
      : undefined};
  line-clamp: ${(props) => props.truncateLine};
  -webkit-line-clamp: ${(props) => props.truncateLine};

  &::after {
    height: ${(props) =>
      props.truncateLine
        ? `calc(${props.lineHeight}em * ${props.truncateLine - 3})`
        : undefined};
  }
`;
