import { dash_connectors } from '@dropbox/api-v2-client';
import { ReactNode } from 'react';
import { DashConnectAccountFailureReason } from '../analytics/events/enums/dash_connect_account_failure_reason';

export interface UIConnection extends dash_connectors.Connection {
  loading: boolean;
  syncing: boolean;
}

export interface UIConnector extends dash_connectors.Connector {
  loading: boolean;
  verifiedConnection?: boolean;
}

export type UIConnectionOrUIConnector = UIConnection | UIConnector;

export enum GroupConnectorType {
  DROPBOX = 'dropbox',
  GOOGLE_SUITE = 'google_suite',
  MICROSOFT_SUITE = 'microsoft_suite',
}

export type GroupConnector = {
  connectorTypes: string[];
  connectors?: UIConnector[];
  description: string;
  groupId: GroupConnectorType;
  icon: ReactNode;
  platform: string;
  title: string;
};

export type DashConnectedAccountError = Error & {
  reason?: DashConnectAccountFailureReason;
};

export const dashConnectedAccountError = (
  reason: DashConnectAccountFailureReason,
  message: string,
) => {
  const error = new Error(message) as DashConnectedAccountError;
  error.reason = reason;
  return error;
};

export type CompleteJanusOauthConnectionError = Error & {
  reason?: CompleteJanusOauthConnectionFailureReason;
};

export const completeJanusOauthConnectionError = (
  reason: CompleteJanusOauthConnectionFailureReason,
  message: string,
) => {
  const error = new Error(message) as CompleteJanusOauthConnectionError;
  error.reason = reason;
  return error;
};

type CompleteJanusOauthConnectionFailureReason =
  | 'invalid_email_domain'
  | 'missing_connection_id'
  | 'other';

export type BrowserNameTypes = 'chrome' | 'firefox' | 'safari' | 'edge';

type AppBaseProps = {
  id: string;
  label: string;
  description: string;
  connected: boolean;
  authenticated: boolean;
  isWebExtension: boolean;
};

export interface WebExtensionProps extends AppBaseProps {
  id: BrowserNameTypes;
  isWebExtension: true;
  authenticated: true;
  icon: JSX.Element;
  installUrl: string;
}

export interface UIWebExtension extends WebExtensionProps {
  connected: boolean;
  loading: boolean;
}

export const enum FeedbackOptions {
  Negative = 'negative',
  Positive = 'positive',
}
