import {
  authenticate,
  checkAndRefreshAccessToken,
  listen,
} from '@mirage/service-auth';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { isErrorWithMessage } from '@mirage/shared/util/error';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
// TODO (Matt): cleanup, these should not be importing from service
import { AuthenticationStatus } from './service';

const logger = tagged('useDropboxAuthentication');

// Lightweight hook to check current login state.
const isLoggedInAtom = atom<boolean | undefined>(undefined);

/**
 * This hook will also init the access token, so should only be called once.
 * Please use useIsLoggedIn() if you only need the login status.
 */
export default function useDropboxAuthentication() {
  const [status, setStatus] = useState<AuthenticationStatus>(
    AuthenticationStatus.Initializing,
  );

  // Minimize re-renders when the value has not changed.
  const lastIsLoggedIn = useRef<boolean>();
  const setLoggedIn = useSetAtom(isLoggedInAtom);
  const statusChangeCount = useRef(0);
  const lastStatusChangeTime = useRef<number>();
  const statusRef = useRef(status);
  statusRef.current = status;

  // listen for changes to our auth state
  useEffect(() => {
    const subscription = listen().subscribe(async (result) => {
      setStatus(result.status);

      const complete = result.status === AuthenticationStatus.Complete;

      // Track rapid status changes
      const now = Date.now();
      if (lastStatusChangeTime.current) {
        const timeSinceLastChange = now - lastStatusChangeTime.current;
        statusChangeCount.current++;

        if (timeSinceLastChange < 5000 && statusChangeCount.current > 1) {
          Sentry.captureMessage(
            `[useDropboxAuthentication] Rapid auth status changes: ${statusChangeCount.current} changes in ${timeSinceLastChange}ms. Current: ${result.status}, Previous: ${statusRef.current}`,
          );
        }
      }
      lastStatusChangeTime.current = now;

      logger.debug(
        `auth status change detected: status=${result.status}, complete=${complete}`,
      );

      if (lastIsLoggedIn.current !== complete) {
        logger.debug('detected status change, setting atom =', complete);
        if (lastIsLoggedIn.current !== undefined) {
          Sentry.captureMessage(
            `[useDropboxAuthentication] Login state changed: ${lastIsLoggedIn.current} -> ${complete}`,
          );
        }

        lastIsLoggedIn.current = complete;
        setLoggedIn(complete);
      }
    });

    const startTime = Date.now();
    checkAndRefreshAccessToken()
      .catch((e) => {
        const duration = Date.now() - startTime;
        logger.error('check and refresh rejected', e);

        Sentry.withScope((scope) => {
          scope.setTag(
            'errorMessage',
            isErrorWithMessage(e) ? e.message : 'unknown',
          );
          scope.setTag('duration', duration.toString());
          scope.setTag('currentStatus', statusRef.current.toString());
          scope.setTag(
            'lastKnownLoginState',
            lastIsLoggedIn.current?.toString() ?? 'undefined',
          );

          if (duration > 5000) {
            Sentry.captureMessage(
              `[useDropboxAuthentication] Slow token refresh: ${duration}ms`,
              'warning',
              {},
              scope,
            );
          }

          Sentry.captureException(e);
          Sentry.captureMessage(
            '[useDropboxAuthentication] Token refresh failed',
            'error',
            {},
            scope,
          );
        });
        return false;
      })
      .then(async (isLoggedIn) => {
        const duration = Date.now() - startTime;
        logger.debug('check and refresh completed, logged in =', isLoggedIn);

        if (lastIsLoggedIn.current !== isLoggedIn) {
          logger.debug('detected status change, setting atom =', isLoggedIn);
          Sentry.captureMessage(
            `[useDropboxAuthentication] Login state changed after token refresh: ${lastIsLoggedIn.current} -> ${isLoggedIn}, took ${duration}ms`,
          );

          lastIsLoggedIn.current = isLoggedIn;
          setLoggedIn(isLoggedIn);
        }

        if (isLoggedIn) {
          logger.debug('setting status to logged in');
          setStatus(AuthenticationStatus.Complete);
        } else {
          // Log unexpected logouts
          if (statusRef.current === AuthenticationStatus.Complete) {
            Sentry.captureMessage(
              `[useDropboxAuthentication] Unexpected logout: previous status was Complete`,
            );
          }
          logger.debug('setting status to none');
          setStatus(AuthenticationStatus.None);
        }

        // Log slow token refreshes even on success
        if (duration > 5000) {
          Sentry.captureMessage(
            `[useDropboxAuthentication] Slow successful token refresh: ${duration}ms`,
          );
        }
      });

    return () => {
      if (statusRef.current === AuthenticationStatus.Complete) {
        Sentry.captureMessage(
          `[useDropboxAuthentication] Cleanup while Complete - possible unintended unmount`,
        );
      }
      subscription.unsubscribe();
    };
  }, [setLoggedIn]);

  return {
    status,
    authenticate,
  };
}

/** Lightweight, auto-updating hook to listen for login status. */
export function useIsLoggedIn(): boolean | undefined {
  return useAtomValue(isLoggedInAtom);
}
