import { stacks, users } from '@dropbox/api-v2-client';
import { AvatarSizes } from '@dropbox/dig-components/avatar';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAPActionSurface } from '@mirage/analytics/events/base/event';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import { PAP_View_DashStartPage } from '@mirage/analytics/events/types/view_dash_start_page';
import { DashFacepile } from '@mirage/dash-component-library/components/DashFacepile';
import { getDefaultColorIndex } from '@mirage/dash-component-library/themes/Stacks';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useActivationForConnectors } from '@mirage/service-connectors/useActivationForConnectors';
import useConnectors from '@mirage/service-connectors/useConnectors';
import {
  HomePageModule,
  MetricPageName,
} from '@mirage/service-operational-metrics/module/constants';
import { useAutoRecordAggregatedPageLatency } from '@mirage/service-operational-metrics/module/module';
import {
  getFreshLoginTags,
  useFreshLoginTags,
} from '@mirage/service-operational-metrics/module/tags';
import { logPageLoadMilestoneOnce } from '@mirage/service-operational-metrics/page-load';
import { useTopContentByConnector } from '@mirage/service-recent-content/hooks';
import { useLastViewedStackInfo } from '@mirage/service-recent-content/hooks/useLastViewedStackInfo';
import { DefaultCustomizableModuleId } from '@mirage/service-settings/service/customize';
import {
  useCustomizableModuleIsHidden,
  useCustomizableModulesSortOrder,
} from '@mirage/service-settings/useCustomizableModuleSettings';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import { getStacks } from '@mirage/service-stacks';
import {
  asShortcut,
  stackDerivePAPProps,
  stackGetShareId,
  stackItemGetName,
} from '@mirage/service-stacks/service/utils';
import { DashSpinnerAutofitContainer } from '@mirage/shared/dash-spinner/DashSpinnerAutofitContainer';
import { EMOJI_CLEARED_SENTINEL_VALUE } from '@mirage/shared/util/constants';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import { copyStackItemUrlToClipboardFromStack } from '@mirage/stacks/CopyStackUrl';
import Actions from '@mirage/stacks/FullScreenStack/Actions';
import { useIsArchiveStackEnabled } from '@mirage/stacks/FullScreenStack/hooks';
import { StackItemActionMenu } from '@mirage/stacks/FullScreenStack/Sections/StackItemActionMenu';
import {
  membersFromStack,
  stackHasWritePermissions,
  stackIsNotArchived,
} from '@mirage/stacks/Helpers/Utils';
import {
  useEditStackItemModal,
  useLastViewedStacksWithItems,
  useSortedStacks,
} from '@mirage/stacks/hooks';
import {
  accessLevelIconSrcForMemberCount,
  accessLevelTitleForAccessLevel,
} from '@mirage/stacks/ShareModal/Utils';
import { WorkingSetCardData, WorkingSetGridV2 } from '@mirage/working-set';
import { CalendarModuleV2 } from '@mirage/working-set/CalendarEventWorkingSetCard/CalendarModuleV2';
import { getRecentsByConnectorCardList } from '@mirage/working-set/WorkingSetCard/RecentsByConnectorCard';
import { RecentsCard } from '@mirage/working-set/WorkingSetCard/RecentsCard';
import { SuggestedStacksCard } from '@mirage/working-set/WorkingSetCard/SuggestedStacksCard';
import { resetImpressionLogging } from '@mirage/working-set/WorkingSetCard/useImpressionLogging';
import orderBy from 'lodash/orderBy';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StacksLoadingContainer } from '../StacksLoading/StacksLoadingContainer';
import styles from './WorkingSet.module.css';

const useWorkingSetData = (
  stacks: stacks.Stack[] | undefined,
  stackItemsMap: { [nsid: string]: stacks.StackItem[] } | undefined,
  menuOpenForStackId: string | undefined,
  setMenuOpenForStackId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >,
  currentAccount: users.FullAccount | undefined,
  onSelectStack: (stack: stacks.Stack, numberOfLinks: number) => void,
) => {
  const isPublicSharingAllowed = useIsPublicSharingAllowed();
  const allStacks = useSortedStacks();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [actionMenuOpenForItemId, setActionMenuOpenForItemId] = useState<
    string | null
  >(null);

  const onCopyLink = useCallback(
    (url: string, stack: stacks.Stack) => {
      copyStackItemUrlToClipboardFromStack(url, stack, reportPapEvent);
    },
    [reportPapEvent],
  );

  const { onEditItemAndStack } = useEditStackItemModal();
  const workingSetCardData: WorkingSetCardData[] = useMemo(() => {
    return (stacks ?? []).map((stack) => {
      const members = membersFromStack(stack);

      const stackItems = stackItemsMap?.[stack.namespace_id ?? ''] ?? [];
      const hasWritePermissions = stackHasWritePermissions(stack);
      const stackItemPerStackMap = new Map<string, stacks.StackItemShortcut>();
      for (const stackItem of stackItems) {
        if (stackItem['.tag'] === 'shortcut' && stackItem.api_file_id) {
          stackItemPerStackMap.set(stackItem.api_file_id, stackItem);
        }
      }

      return {
        namespaceId: stack.namespace_id ?? '',
        id: stackGetShareId(stack) ?? '',
        title: stack.stack_data?.name ?? '',
        colorIndex: stack.stack_data?.color_index ?? getDefaultColorIndex(),
        emoji: stack.stack_data?.emoji ?? EMOJI_CLEARED_SENTINEL_VALUE,
        isPinned: stack.user_data?.is_pinned ?? false,
        menuIsOpen: menuOpenForStackId === stack.namespace_id,
        memberCount: members.length,
        accessLevelIcon: accessLevelIconSrcForMemberCount(
          stack.sharing_data?.members?.length ?? 0,
          stack.sharing_data?.shared_link?.access_level,
          stack.publish_data?.is_published,
          isPublicSharingAllowed,
        ),
        accessLevel: accessLevelTitleForAccessLevel(
          stack.publish_data,
          stack?.sharing_data?.shared_link?.access_level,
          stack?.sharing_data?.members?.length ?? 0,
          isPublicSharingAllowed,
        ),
        links: orderBy(
          stackItems.map(asShortcut).filter(isDefined),
          [(item) => item.last_modified_time_utc_sec],
          ['desc'],
        ).map((shortcut) => {
          return {
            url: shortcut?.url ?? '',
            id: shortcut.api_file_id ?? '',
            name: stackItemGetName(shortcut, allStacks),
            shouldShowHoverState:
              actionMenuOpenForItemId === shortcut.api_file_id,
            last_modified_time_utc_sec: shortcut.last_modified_time_utc_sec,
          };
        }),
        getMembersComponent: (size: AvatarSizes = 'medium') => (
          <DashFacepile
            members={members}
            size={size}
            itemClassName={styles.facePileItem}
          />
        ),

        stackDerivedPAPProps: stackDerivePAPProps(stack),
        actionComponentForStackItemId: (itemId: string) => {
          const item = stackItemPerStackMap.get(itemId);
          if (!item) {
            return <></>;
          }

          return (
            <StackItemActionMenu
              key={item.api_file_id}
              stack={stack}
              item={item}
              setActionMenuOpen={(isOpen) =>
                setActionMenuOpenForItemId(
                  isOpen ? (item.api_file_id ?? null) : null,
                )
              }
              onCopyLink={() => onCopyLink(item.url ?? '', stack)}
              onEditItem={(item) => {
                onEditItemAndStack(item, stack);
              }}
              hasWritePermissions={hasWritePermissions || false}
            />
          );
        },
        actionMenuComponent: currentAccount && (
          <Actions
            stack={stack}
            currentAccount={currentAccount}
            setMenuOpenForStackId={setMenuOpenForStackId}
            iconColor="var(--dig-color__text__subtle)"
            showPinnedIcon={false}
          />
        ),
        onClickCard: () => onSelectStack(stack, stackItems.length),
        isCompanyPublishedStack: !!stack.publish_data?.is_published,
        isPublicStack:
          !!isPublicSharingAllowed &&
          stack.sharing_data?.shared_link?.access_level?.['.tag'] === 'public',
      };
    });
  }, [
    stacks,
    stackItemsMap,
    menuOpenForStackId,
    isPublicSharingAllowed,
    currentAccount,
    setMenuOpenForStackId,
    allStacks,
    actionMenuOpenForItemId,
    onCopyLink,
    onEditItemAndStack,
    onSelectStack,
  ]);
  return workingSetCardData;
};

function useHomePagePerformanceTracking(showConnectApps2: boolean) {
  const freshLoginTags = useFreshLoginTags();

  const calendarIsHidden = useCustomizableModuleIsHidden(
    DefaultCustomizableModuleId.CALENDAR,
  );
  const recentContentIsHidden = useCustomizableModuleIsHidden(
    DefaultCustomizableModuleId.RECENT_CONTENT,
  );
  const suggestedStacksIsHidden = useCustomizableModuleIsHidden(
    DefaultCustomizableModuleId.NEW_STACK_SUGGESTIONS,
  );

  const modules = useMemo(() => {
    return [
      ...(calendarIsHidden ? [] : [HomePageModule.CALENDAR_V2]),
      ...(recentContentIsHidden ? [] : [HomePageModule.RECENTS_V2]),
      ...(suggestedStacksIsHidden ? [] : [HomePageModule.SUGGESTED_STACKS_V2]),
      ...(showConnectApps2 ? [HomePageModule.CONNECT_APPS] : []),
    ];
  }, [
    calendarIsHidden,
    recentContentIsHidden,
    showConnectApps2,
    suggestedStacksIsHidden,
  ]);

  useAutoRecordAggregatedPageLatency(
    MetricPageName.HOME_V2,
    modules,
    freshLoginTags || getFreshLoginTags,
  );
}

function useReportViewDashStartPage() {
  const { reportPapEvent } = useMirageAnalyticsContext();

  return useCallback(async () => {
    const stacks = await getStacks();
    const pinnedStackIds =
      stacks
        ?.filter((s) => s.user_data?.is_pinned)
        .map((s) => s.namespace_id) || [];

    reportPapEvent(
      PAP_View_DashStartPage({
        actionSurface: PAPActionSurface.START_PAGE,
        hasPinnedStacks: stacks?.some((s) => s.user_data?.is_pinned),
        pinnedStackIds: String(pinnedStackIds),
        numberOfStacks: stacks?.length || 0,
        numPinnedStacks: pinnedStackIds.length,
        featureLine: 'session_tracking',
      }),
      true,
    );
  }, [reportPapEvent]);
}

function useHomeDisplayModules() {
  logPageLoadMilestoneOnce('WorkingSetGridV2 start');

  const moduleIdToIndex = useCustomizableModulesSortOrder();
  const hasDonePerLoadActions = useRef(false);
  const { connectors } = useConnectors();
  const { isLoading, topContentByConnector } =
    useTopContentByConnector(connectors);
  const reportViewDashStartPage = useReportViewDashStartPage();

  if (!hasDonePerLoadActions.current) {
    resetImpressionLogging();
    reportViewDashStartPage();
    hasDonePerLoadActions.current = true;
  }

  const sortedModules = useMemo(() => {
    const modules = [
      { id: DefaultCustomizableModuleId.RECENT_CONTENT, jsx: <RecentsCard /> },
      { id: DefaultCustomizableModuleId.CALENDAR, jsx: <CalendarModuleV2 /> },
      {
        id: DefaultCustomizableModuleId.NEW_STACK_SUGGESTIONS,
        jsx: <SuggestedStacksCard />,
      },
      ...getRecentsByConnectorCardList({
        isLoading,
        topContentByConnector,
      }),
    ];

    modules.sort((a, b) => {
      const aIndex = moduleIdToIndex[a.id];
      const bIndex = moduleIdToIndex[b.id];

      if (aIndex === undefined) return 1;
      if (bIndex === undefined) return -1;

      return aIndex - bIndex;
    });

    return modules;
  }, [isLoading, moduleIdToIndex, topContentByConnector]);

  return sortedModules;
}

export const WorkingSet: React.FC = () => {
  logPageLoadMilestoneOnce('WorkingSet start');

  const archiveStackIsEnabled = useIsArchiveStackEnabled();

  const lastViewedStackInfo = useLastViewedStackInfo();
  const {
    stacks: recommendedStacksV2,
    stackItemsMap: recommendedStackItemsMapV2,
  } = useLastViewedStacksWithItems(
    lastViewedStackInfo,
    archiveStackIsEnabled ? stackIsNotArchived : () => true,
  );

  const stacks = useMemo(() => {
    return recommendedStacksV2?.slice(0, 5);
  }, [recommendedStacksV2]);

  const stackItemsMap = recommendedStackItemsMapV2;

  const homeDisplayModules = useHomeDisplayModules();

  const { noConnectors, someConnectors } = useActivationForConnectors();
  const showConnectApps = noConnectors || someConnectors;

  useHomePagePerformanceTracking(showConnectApps);

  // If we haven't loaded stacks or if we have loaded stacks but haven't
  // loaded stack items, then render a spinner.
  return (
    // StacksLoadingContainer only checks for stacks, but not stack items.
    <StacksLoadingContainer>
      {/* This checks that stack items are also loaded as well */}
      {stacks && (stacks.length === 0 || stackItemsMap !== undefined) ? (
        <WorkingSetWithStacksData
          stacks={stacks}
          stackItemsMap={stackItemsMap}
          homeDisplayModules={homeDisplayModules}
        />
      ) : (
        <DashSpinnerAutofitContainer />
      )}
    </StacksLoadingContainer>
  );
};

// Separate component to prevent recomputing data based on stack data changes.
const WorkingSetWithStacksData: React.FC<{
  stacks: stacks.Stack[];
  stackItemsMap: { [nsid: string]: stacks.StackItem[] } | undefined;
  homeDisplayModules: { id: string; jsx: JSX.Element }[];
}> = ({ stacks, stackItemsMap, homeDisplayModules }) => {
  logPageLoadMilestoneOnce('WorkingSetWithStacksData start');

  const navigate = useNavigate();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const currentAccount = useDropboxAccount();

  const [menuOpenForStackId, setMenuOpenForStackId] = useState<string>();

  const { noConnectors, someConnectors } = useActivationForConnectors();
  const showConnectApps = noConnectors || someConnectors;

  if (showConnectApps) {
    logPageLoadMilestoneOnce(`showConnectApps=true`);
  }

  useHomePagePerformanceTracking(showConnectApps);

  const onSelectStack = useCallback(
    (stack: stacks.Stack, numberOfLinks: number) => {
      reportPapEvent(
        PAP_View_DashStack({
          ...stackDerivePAPProps(stack),
          actionSurfaceComponent: 'stacks',
          featureLine: 'stacks',
          numberOfLinks: numberOfLinks,
        }),
      );
      navigate(`/stacks/${stackGetShareId(stack) ?? ''}`);
    },
    [navigate, reportPapEvent],
  );

  const workingSetCardData = useWorkingSetData(
    stacks,
    stackItemsMap,
    menuOpenForStackId,
    setMenuOpenForStackId,
    currentAccount,
    onSelectStack,
  );

  return (
    <WorkingSetGridV2
      workingSetCardData={workingSetCardData}
      showConnectApps={showConnectApps}
      homeDisplayModules={homeDisplayModules}
    />
  );
};
