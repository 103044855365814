import { UIIcon } from '@dropbox/dig-icons';
import { MagicWandLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import {
  ComposeAssistantConversationMessage,
  ComposeSource,
} from '@mirage/shared/compose/compose-session';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo } from 'react';
import { ConversationInput } from '../chat/ConversationInput';
import { ConversationMessageLoadingText } from '../chat/ConversationMessageLoadingText';
import { SourcesFromSettingsConversationMessages } from '../chat/SourcesFromSettingsConversationMessages';
import styles from './SourcesFromSettingsConversationPane.module.css';

interface SourcesFromSettingsConversationPaneProps {
  messages: ComposeAssistantConversationMessage[] | undefined;
  onSubmitMessage: (text: string) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  sources: ComposeSource[];
  loading: boolean;
}

export const SourcesFromSettingsConversationPane = memo(
  ({
    messages,
    onSubmitMessage,
    logComposeEvent,
    sources,
    loading,
  }: SourcesFromSettingsConversationPaneProps) => {
    const isMobile = useIsMobileSize();
    return (
      <div
        className={classnames(styles.SourcesFromSettingsConversationPane, {
          [styles.SourcesFromSettingsConversationPaneMobile]: isMobile,
        })}
      >
        <SourcesFromSettingsConversationMessages
          messages={messages || []}
          sources={sources}
          onRemoveSource={() => {}}
          logComposeEvent={logComposeEvent}
          loadingMessage={
            loading ? (
              <div
                className={classnames(
                  styles.LoadingMessageRow,
                  styles.ConversationMessageRowMessageAnimation,
                )}
              >
                <div
                  className={
                    styles.ConversationMessageRowMessageActionIconContainer
                  }
                >
                  <UIIcon
                    src={MagicWandLine}
                    size="small"
                    className={styles.ConversationMessageRowMessageActionIcon}
                  />
                </div>
                <ConversationMessageLoadingText
                  text={i18n.t('compose_settings_loading_message')}
                />
              </div>
            ) : null
          }
        />

        <ConversationInput
          variant="minimal"
          onSubmit={onSubmitMessage}
          showAddSourcesButton={false}
          sources={sources}
          logComposeEvent={logComposeEvent}
          messages={messages || []}
        />
      </div>
    );
  },
);

SourcesFromSettingsConversationPane.displayName =
  'SourcesFromSettingsConversationPane';
