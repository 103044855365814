export interface ExtensionInfo {
  id: string;
  name: string;
  browser: string;
}

export type Platform =
  | 'unknown'
  // hornet and webapp platforms
  | 'win32'
  | 'darwin'
  // scout platforms
  | 'chrome'
  | 'firefox'
  | 'edge'
  | 'safari';

export type BuildChannel =
  | 'production'
  | 'staging'
  | 'development'
  | 'canary'
  | 'test';

export type Environment = 'production' | 'development' | 'test';

export type Surface = 'desktop' | 'web' | 'extension';

// the URL param name for the search initiator PAP property.
// For exmaple, if the search initiator is "omnibox", the URL will look like:
// https://dash.ai/search_results?query=foo&{SEARCH_INITIATOR_PARAM_NAME}=omnibox
export const SEARCH_INITIATOR_PARAM_NAME = 'initiator';
