import styled from '@emotion/styled';
import React from 'react';
import KeyCap from './KeyCap';

type Props = {
  keys: Array<string>;
};

export default function KeyCapSequence({ keys = [] }: Props) {
  return (
    <SequenceContainer>
      {keys.map((key, index) => (
        <KeyCap key={`key-${index}`}>{key}</KeyCap>
      ))}
    </SequenceContainer>
  );
}

const SequenceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;
