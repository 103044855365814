/* eslint-disable no-restricted-syntax */

// This is a convenience method for grabbing a global object which has some
// playwright specific properties on it while the app is being tested. In many
// places we need to be able to check these values very early in the startup
// process, so our normal environment checking methods don't work.

const windowOrEnv =
  typeof window !== 'undefined'
    ? window
    : typeof process !== 'undefined'
      ? process.env
      : undefined;

export default windowOrEnv as {
  PLAYWRIGHT_TEST_MODE?: '1' | '0' | undefined;
  PLAYWRIGHT_DISABLE_INTERPOLATION?: 'true' | undefined;
};
