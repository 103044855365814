import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_DashFilter } from '@mirage/analytics/events/types/click_dash_filter';
import { PAP_Select_DashFilter } from '@mirage/analytics/events/types/select_dash_filter';
import { FilterBar } from '@mirage/search';
import { getContentTypeDropdownVariant } from '@mirage/search/Header/ContentTypeFilter/ContentTypeFilterDropdown';
import { getCurrentAccount } from '@mirage/service-auth';
import { useActivationForConnectors } from '@mirage/service-connectors/useActivationForConnectors';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import useSettings from '@mirage/service-settings/useSettings';
import {
  DESKTOP_LOCAL_FILE_CONNECTOR_ID,
  LOCAL_FILE_CONNECTOR,
} from '@mirage/shared/connectors';
import { ConnectorIcon, DesktopFilterIcon } from '@mirage/shared/icons';
import {
  CONNECTOR_TYPES,
  ConnectorFilter,
  connectorToSearchFilter,
  ContentTypeFilter,
  dedupeConnectors,
  filterOutLegacyPaperConnector,
  getConnectorFilters,
  getContentTypeFilters,
  getLastUpdatedFilter,
  getPersonFilter,
  LastUpdatedFilter,
  merge,
  PersonFilter,
  PersonObject,
  SearchFilter,
  SearchFilterType,
  sortDisplayFilters,
} from '@mirage/shared/search/search-filters';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { DashFilterName } from '@mirage/analytics/events/enums/dash_filter_name';
import type { DashFilterType } from '@mirage/analytics/events/enums/dash_filter_type';
import type { Connector } from '@mirage/service-dbx-api/service';

const logger = tagged('SearchFilters');

type Props = {
  connectors: Connector[];
  browserHistoryConnectors: Connector[];
  activeFilters?: SearchFilter[];
  onFilterChange?: (filters: SearchFilter[]) => void;
};

interface Filter {
  key: string;
  label: string;
  icon: JSX.Element;
  selected: boolean;
  type: CONNECTOR_TYPES;
}

export default function SearchFilters({
  connectors = [],
  browserHistoryConnectors,
  activeFilters = [],
  onFilterChange,
}: Props) {
  const contentTypeFilterExperimentValue = useFeatureFlagValue(
    'dash_2024_07_08_search_content_type_filter',
  );
  const settings = useSettings(['localFiles']);
  const isLocalFilesEnabled = Boolean(
    EnvCtx.surface === 'desktop' && settings.settings?.localFiles?.enabled,
  );

  // active connector filters
  const [activeConnectorFilters, setActiveConnectorFilters] = useState<
    ConnectorFilter[]
  >(getConnectorFilters(activeFilters));

  // active last updated filter, one or none selected
  const [activeUpdatedAtFilter, setActiveUpdatedAtFilter] = useState<
    LastUpdatedFilter | undefined
  >(getLastUpdatedFilter(activeFilters));

  // active person filter, one or none selected
  const [activePersonFilter, setActivePersonFilter] = useState<
    PersonFilter | undefined
  >(getPersonFilter(activeFilters));

  const [currentAccount, setCurrentAccount] = useState<
    PersonObject | undefined
  >();

  const [activeContentTypeFilters, setActiveContentTypeFilters] = useState<
    ContentTypeFilter[]
  >(getContentTypeFilters(activeFilters));

  useEffect(() => {
    setActiveConnectorFilters(getConnectorFilters(activeFilters));
    setActiveContentTypeFilters(getContentTypeFilters(activeFilters));
    setActiveUpdatedAtFilter(getLastUpdatedFilter(activeFilters));
    setActivePersonFilter(getPersonFilter(activeFilters));
  }, [activeFilters]);

  useEffect(() => {
    getCurrentAccount()
      .then((account) => {
        if (!account?.email) return; // Email used as filter key
        setCurrentAccount({
          displayName: account?.name.display_name,
          email: account?.email,
          profilePhotoUrl: account?.profile_photo_url,
        });
        return account;
      })
      .catch((err) => {
        logger.error('Error fetching current account for filters', err);
      });
  }, []);

  const { noConnectors, someConnectors } = useActivationForConnectors();

  function clearConnectorFilters() {
    setActiveConnectorFilters([]);
    if (typeof onFilterChange !== 'function') return;
    onFilterChange(
      merge(
        [],
        activeUpdatedAtFilter,
        activePersonFilter,
        activeContentTypeFilters,
      ),
    );
  }

  function clearContentTypeFilters() {
    setActiveContentTypeFilters([]);
    if (typeof onFilterChange !== 'function') return;
    onFilterChange(
      merge(
        activeConnectorFilters,
        activeUpdatedAtFilter,
        activePersonFilter,
        [],
      ),
    );
  }

  function clearAllFilters() {
    setActiveConnectorFilters([]);
    setActiveUpdatedAtFilter(undefined);
    setActivePersonFilter(undefined);
    if (typeof onFilterChange !== 'function') return;
    onFilterChange(merge([], undefined, undefined, []));
  }

  const isActiveConnectorFilter = useCallback(
    (filter: ConnectorFilter) => {
      return activeConnectorFilters.some(
        (afilter) => afilter.id === filter.id && afilter.type === filter.type,
      );
    },
    [activeConnectorFilters],
  );

  const isActiveContentTypeFilter = (filter: ContentTypeFilter) =>
    activeContentTypeFilters.some((afilter) => afilter.id === filter.id);

  const toggleActiveConnectorFilters = useCallback(
    (targetFilter: ConnectorFilter) => {
      let updatedFilters;

      // toggle off an already selected filter
      if (isActiveConnectorFilter(targetFilter)) {
        updatedFilters = activeConnectorFilters.filter(
          (afilter) => afilter.id !== targetFilter.id,
        );
      }
      // toggle on filter
      else {
        updatedFilters = [...activeConnectorFilters];
        updatedFilters.push(targetFilter);
      }

      setActiveConnectorFilters(updatedFilters);

      if (typeof onFilterChange !== 'function') return;
      onFilterChange(
        merge(
          updatedFilters,
          activeUpdatedAtFilter,
          activePersonFilter,
          activeContentTypeFilters,
        ),
      );
    },
    [
      activeConnectorFilters,
      activeContentTypeFilters,
      activePersonFilter,
      activeUpdatedAtFilter,
      isActiveConnectorFilter,
      onFilterChange,
    ],
  );

  function toggleActiveContentTypeFilters(
    targetFilter: ContentTypeFilter | undefined,
  ) {
    const contentTypeDropdownVariant = getContentTypeDropdownVariant(
      contentTypeFilterExperimentValue,
    );
    let updatedFilters: ContentTypeFilter[] = [];
    const dashFilterType = 'file_type';

    if (!targetFilter) {
      // Clear the selected filter
      logFilterSelected({
        dashFilterName: 'file_type_any',
        dashFilterType,
      });
    } else if (contentTypeDropdownVariant === 'multi_select') {
      // toggle off already selected filter
      if (isActiveContentTypeFilter(targetFilter)) {
        updatedFilters = activeContentTypeFilters.filter((afilter) => {
          return !isEqual(afilter, targetFilter);
        });
      }
      //toggle on filter
      else {
        logFilterSelected({
          dashFilterName: targetFilter.id as DashFilterName,
          dashFilterType,
        });
        updatedFilters = [...activeContentTypeFilters];
        updatedFilters.push(targetFilter);
      }
    } else if (!isActiveContentTypeFilter(targetFilter)) {
      // For single-select
      logFilterSelected({
        dashFilterName: targetFilter.id as DashFilterName,
        dashFilterType,
      });
      updatedFilters = [targetFilter];
    }

    setActiveContentTypeFilters(updatedFilters);
    if (onFilterChange) {
      onFilterChange(
        merge(
          activeConnectorFilters,
          activeUpdatedAtFilter,
          activePersonFilter,
          updatedFilters,
        ),
      );
    }
  }

  function handleLastUpdatedFilterSelection(
    updatedAtFiler: LastUpdatedFilter | undefined,
  ) {
    setActiveUpdatedAtFilter(updatedAtFiler);

    if (onFilterChange) {
      onFilterChange(
        merge(
          activeConnectorFilters,
          updatedAtFiler,
          activePersonFilter,
          activeContentTypeFilters,
        ),
      );
    }
  }

  function handlePersonFilterSelection(
    _person: PersonFilter | undefined,
    peopleSearchQuery: string,
  ) {
    let personFilter = _person;

    // Clear the filter if parameters are undefined (person is deselected)
    if (!personFilter?.parameters) {
      personFilter = undefined;
    }

    if (personFilter) {
      logFilterSelected({
        dashFilterName: 'people',
        dashFilterType: 'people',
        peopleSearchQuery,
        peopleSelected: personFilter?.parameters?.email,
      });
    }

    setActivePersonFilter(personFilter);
    if (onFilterChange) {
      onFilterChange(
        merge(
          activeConnectorFilters,
          activeUpdatedAtFilter,
          personFilter,
          activeContentTypeFilters,
        ),
      );
    }
  }

  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();

  const getHasQueryAndUpdateSession = useCallback(() => {
    let hasQuery = false;
    const searchAttemptSession = searchAttemptSessionManager.getSession();
    if (searchAttemptSession) {
      const query = searchAttemptSession?.properties.query;
      hasQuery = query?.length > 0;

      if (hasQuery) {
        searchSessionManager.updateProperties({
          hasQuery: true,
        });
      }
    }
    return hasQuery;
  }, [searchAttemptSessionManager, searchSessionManager]);

  const logFilterSelected = useCallback(
    ({
      dashFilterName,
      dashFilterType,
      peopleSearchQuery,
      peopleSelected,
    }: {
      dashFilterName: DashFilterName;
      dashFilterType: DashFilterType;
      peopleSearchQuery?: string;
      peopleSelected?: string;
    }) => {
      searchSessionManager.extendOrCreateSession('select_filter');
      const hasQuery = getHasQueryAndUpdateSession();
      reportPapEvent(
        PAP_Select_DashFilter({
          dashFilterName,
          dashFilterType,
          hasQuery,
          isTypeahead: false,
          featureLine: 'search',
          actionSurface: 'serp',
          actionSurfaceComponent: 'search_bar',
          peopleSearchQuery,
          peopleSelected,
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        }),
      );
    },
    [reportPapEvent, searchSessionManager, getHasQueryAndUpdateSession],
  );

  const logOpenFilterDropdown = (dashFilterType: DashFilterType) => {
    searchSessionManager.extendOrCreateSession('select_filter');
    const hasQuery = getHasQueryAndUpdateSession();
    reportPapEvent(
      PAP_Click_DashFilter({
        dashFilterType,
        isTypeahead: false,
        hasQuery,
        featureLine: 'search',
        actionSurface: 'serp',
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
  };

  const onSelectConnectorFilter = (connectorName: string) => {
    const filterDefinition = {
      id: connectorName,
      type: SearchFilterType.Connector,
      parameters: {
        connectorId: connectorName,
      },
    } as ConnectorFilter;

    toggleActiveConnectorFilters(filterDefinition);

    if (!isActiveConnectorFilter(filterDefinition)) {
      logFilterSelected({
        dashFilterName: connectorName as DashFilterName,
        dashFilterType: 'connector',
      });
    }
  };

  const orderedConnectorFilters = useMemo(() => {
    const connectorsToFilter: Connector[] = [
      ...connectors,
      ...browserHistoryConnectors,
    ];

    if (isLocalFilesEnabled) {
      connectorsToFilter.push(LOCAL_FILE_CONNECTOR);
    }

    const result = dedupeConnectors(connectorsToFilter)
      .filter(filterOutLegacyPaperConnector)
      .map((connector: Connector) => {
        const filterDefinition = connectorToSearchFilter(connector);

        if (!filterDefinition) return;

        const isSelected = isActiveConnectorFilter(filterDefinition);

        const icon =
          filterDefinition.id === DESKTOP_LOCAL_FILE_CONNECTOR_ID ? (
            <DesktopFilterIcon />
          ) : (
            <ConnectorIcon
              connectorName={filterDefinition.id}
              iconUrl={connector.branding?.icon_src}
              size={16}
            />
          );

        return {
          key: filterDefinition.id,
          label: filterDefinition.parameters.displayName,
          icon,
          selected: isSelected,
          type: connector.type,
        };
      })
      .filter(Boolean) as Filter[];

    const sorted = sortDisplayFilters(result);

    return {
      connectorFilters: sorted.filter(
        (filter) => filter.type != CONNECTOR_TYPES.BROWSER_HISTORY,
      ),
      browserHistoryFilters: sorted.filter(
        (filter) => filter.type === CONNECTOR_TYPES.BROWSER_HISTORY,
      ),
    };
  }, [
    connectors,
    browserHistoryConnectors,
    isActiveConnectorFilter,
    isLocalFilesEnabled,
  ]);

  return (
    <FilterBar
      connectorFilters={orderedConnectorFilters.connectorFilters}
      browserHistoryFilters={orderedConnectorFilters.browserHistoryFilters}
      activeFilters={activeFilters}
      displayConnectMoreApps={noConnectors || someConnectors}
      onLastUpdatedFilterSelect={handleLastUpdatedFilterSelection}
      onContentTypeFilterSelect={toggleActiveContentTypeFilters}
      onPersonFilterSelect={handlePersonFilterSelection}
      onConnectorFilterSelect={onSelectConnectorFilter}
      activePersonFilter={activePersonFilter}
      activeUpdatedAtFilter={activeUpdatedAtFilter}
      activeContentTypeFilters={activeContentTypeFilters}
      clearFilters={clearAllFilters}
      clearConnectorFilters={clearConnectorFilters}
      clearContentTypeFilters={clearContentTypeFilters}
      contentTypeFilterExperimentValue={contentTypeFilterExperimentValue}
      onOpenFilterDropdown={logOpenFilterDropdown}
      currentAccount={currentAccount}
    />
  );
}
