import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { useCurrentBackgroundTheme } from '@mirage/dash-component-library/themes/Stacks';
import ConnectorIssuesNotificationBanner from '@mirage/mosaics/GlobalBanners/ConnectorIssuesNotificationBanner';
import { activeStackAtom } from '@mirage/stacks/ActiveStack/atoms';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import ColdStartNotificationBanner from './ColdStartNotificationBanner';
import styles from './GlobalBanners.module.css';

export default function GlobalBanners() {
  const activeStack = useAtomValue(activeStackAtom);
  const backgroundTheme = useCurrentBackgroundTheme(activeStack);
  const isAugustStackPageEnabled = useStackPageAugustRevisionEnabled();

  return (
    <ThemeProvider overrides={backgroundTheme}>
      <ThemeContainer>
        <div
          className={classNames(styles.banners, {
            [styles.showBackground]:
              isAugustStackPageEnabled && backgroundTheme !== undefined,
          })}
        >
          <ConnectorIssuesNotificationBanner bannerClass={styles.banner} />
          <ColdStartNotificationBanner bannerClass={styles.banner} />
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
}
