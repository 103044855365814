import { tagged } from '@mirage/service-logging';
import {
  AuthServiceContract,
  LoginSyncMessage,
} from '@mirage/service-login-sync-v2/service/types';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import { fetchCatapultAuthCode } from './catapult';
import { generatePKCE } from './pkce';

const logger = tagged('handleLoginSyncMessage');

export async function handleLoginSyncMessage(
  message: LoginSyncMessage,
  authService: AuthServiceContract,
  canGenerateAuthCode: boolean,
  loginUsingAuthCode: (
    authCode: string,
    codeVerifier: string,
  ) => void | Promise<void>,
  publishLoginState: (
    LoginSyncMessage: LoginSyncMessage,
  ) => Promise<LoginSyncMessage | undefined>,
  logout: () => Promise<void>,
): Promise<LoginSyncMessage | undefined> {
  logger.info(`Handling incoming message.type=${message.type}`);

  // Handle received message.
  switch (message.type) {
    case 'login':
    case 'loggedIn':
      {
        const authData = await authService.getAuthenticationData();

        if (authData?.accessToken) {
          const account = nonNil(
            await authService.getCurrentAccount(false),
            'account',
          );

          if (account.email === message.email) {
            logger.info(`Already logged in to same account (do nothing)`);
          } else {
            logger.info(
              `Peer logged in with different email => request to login to same account`,
            );

            return await publishLoginState({ type: 'requestLogin' });
          }
        } else {
          logger.info(`Auto-login user from received credentials!`);
          return await publishLoginState({ type: 'requestLogin' });
        }
      }
      break;

    case 'logout':
    case 'loggedOut': {
      const authData = await authService.getAuthenticationData();

      if (authData?.accessToken) {
        logger.info(`Auto-logout user as peer logged out`);
        await logout();
      } else {
        logger.info(`User is already logged out (do nothing)`);
      }
      break;
    }

    case 'requestLogin': {
      const authData = await authService.getAuthenticationData();

      if (authData) {
        if (!canGenerateAuthCode) {
          logger.info(
            `Cannot generate auth code, so sending 'cannotGenerateLoginCode'`,
          );
          return await publishLoginState({ type: 'cannotGenerateLoginCode' });
        }

        logger.info(`Generate auth code to send back to requestor`);
        const { verifier, challenge } = await generatePKCE();

        const authCode = await fetchCatapultAuthCode(
          authData.accessToken,
          challenge,
        );

        if (!authCode) {
          logger.warn(`Failed to fetch auth code from server`);
          return;
        }

        // Note that we will both return the message and publish it to all
        // subscribers, so the sender has the option on deciding how to
        // handle the response (either using inline await or using a
        // listener callback).
        return await publishLoginState({
          type: 'loginCode',
          authCode,
          codeVerifier: verifier,
        });
      }

      logger.info(`Receiver is logged out, can't return login code`);
      return await publishLoginState({ type: 'loggedOut' });
    }

    case 'loginCode':
      {
        const authData = await authService.getAuthenticationData();

        if (authData?.accessToken) {
          logger.info(`Auto-logout then login after receiving login code`);

          await logout(); // clear existing data
        } else {
          logger.info(`Auto-login after receiving login code`);
        }

        await loginUsingAuthCode(message.authCode, message.codeVerifier);
      }
      break;

    case 'cannotGenerateLoginCode':
      logger.info(`Received cannotGenerateLoginCode --> no-op`);
      break;

    default:
      message satisfies never;
      throw new Error(`Unknown message type: ${JSON.stringify(message)}`);
  }
}
