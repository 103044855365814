import { Card, CardContentsProps } from '@dropbox/dash-component-library';
import classnames from 'classnames';
import { KeyboardEvent, MouseEvent } from 'react';
import styles from './ModuleCard.module.css';

export interface ModuleCardProps {
  children: React.ReactNode;
  breakout?: CardContentsProps['breakout'];
  className?: string;
  style?: React.CSSProperties;
  withHeader?: React.ReactNode;

  /**
   * If provided, the card will be clickable. If you have accessories
   * or other action buttons within the card, you can use the
   * `data-accessory-action` attribute to prevent the card from
   * being clicked when those elements are clicked.
   */
  onClick?: (e: MouseEvent | KeyboardEvent) => void;
  isLink?: boolean;

  /**
   * The URL to show in the browser when hovering over the card
   * Will not be used for actual navigation
   */
  visibleUrl?: string;

  /**
   * Explicit height we should set the card to be; this is useful for ensuring that different content cards
   * render to the same height for UI consistency
   * Ideally: we should match the content to ensure consistencies but can be used as an escape hatch to ensure consistent
   * heights (ex. SeeAllCard in storybook)
   */
  explicitHeight?: number;
}

/**
 * Don't import ModuleCard directly! Use `DashCard` instead
 *
 * `import {DashCard} from '@mirage/dash-component-library/components/DashCard'`
 */
export const ModuleCard = ({
  children,
  breakout = 'standard',
  className,
  style,
  withHeader,
  isLink,
  explicitHeight,
}: ModuleCardProps) => {
  // CSS override specifically for height; we need to set both content and card to be the same height
  const cardStyle =
    explicitHeight !== undefined ? { ...style, height: explicitHeight } : style;
  const contentStyle =
    explicitHeight !== undefined ? { height: explicitHeight } : null;

  return (
    <Card
      variant="outline"
      isLink={isLink}
      className={classnames(
        styles.card,
        {
          [styles.hasHeader]: !!withHeader,
          [styles.isLink]: isLink,
        },
        className,
      )}
      style={cardStyle}
    >
      {withHeader}
      <Card.Contents
        breakout={breakout}
        className={classnames(styles.cardContents, {
          [styles.cardBreakoutSmall]: breakout === 'small',
          [styles.cardBreakoutXSmall]: breakout === 'xsmall',
          [styles.cardBreakoutStandard]: breakout === 'standard',
          [styles.cardBreakoutLarge]: breakout === 'large',
          [styles.cardBreakoutXLarge]: breakout === 'xlarge',
          [styles.cardLink]: isLink,
        })}
        style={contentStyle ?? {}}
      >
        {children}
      </Card.Contents>
    </Card>
  );
};

export const Link = ({
  children,
  href,
  onClick,
  className,
}: {
  children: React.ReactNode;
  href: string;
  onClick?: (e: MouseEvent | KeyboardEvent) => void;
  className?: string;
}) => {
  const handleClick = (e: MouseEvent | KeyboardEvent) => {
    // Avoid the default behavior of the link
    e.preventDefault();

    const target = e.target as HTMLElement;

    // if clicked element (target) is not a child of the card (currentTarget)
    // don't do anything. this means we're likely doing a synthetic trigger
    // as a result of a portaled menu
    if (!(e.currentTarget as HTMLElement).contains(target)) {
      return;
    }

    onClick?.(e);
  };

  return (
    <Card.Link
      onClick={handleClick}
      href={href ?? '#'}
      className={classnames(styles.cardLink, className)}
      isOverlay
    >
      {children}
    </Card.Link>
  );
};
