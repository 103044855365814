import { Text, Title } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import { OnboardingHeader } from '@mirage/growth/components/OnboardingHeader';
import classnames from 'classnames';
import { useOnboardingStyles } from '../onboarding/hooks/useOnboardingStyles';
import styles from './TeamOnboardingLayout.module.css';

type Props = {
  title: string;
  description: string;
  descriptionFooter?: React.ReactNode;
  actionButtons?: React.ReactNode;
  children?: React.ReactNode;
};

export const TeamOnboardingLayout = ({
  title,
  description,
  descriptionFooter,
  actionButtons,
  children,
}: Props) => {
  const { marginBottom, buttonHeight } = useOnboardingStyles();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      style={{ marginBottom: `${marginBottom + buttonHeight}px` }}
    >
      <Stack
        className={styles.onboardingHeaderContainer}
        gap="Macro XSmall"
        justify="center"
        align="center"
        display="flex"
      >
        <OnboardingHeader justifyCenter={true} displayGlyph={false} />
        <Title
          size="medium"
          weightVariant="emphasized"
          className={styles.textCentered}
        >
          {title}
        </Title>
        <Stack display="flex" justify="center" align="center">
          <Text
            tagName="div"
            color="subtle"
            className={classnames(styles.atlasGrotesk, styles.textCentered)}
          >
            {description}
          </Text>
          {descriptionFooter}
        </Stack>
      </Stack>

      {children}

      <div className={styles.bottomContainer}>
        <Box
          display="flex"
          flexGrow={1}
          width={'100%'}
          justifyContent={'center'}
          padding="Micro XLarge"
          borderColor="Border Subtle"
          borderTop="Solid"
        >
          {actionButtons}
        </Box>
      </div>
    </Box>
  );
};
