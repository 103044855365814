import { IconButton } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import {
  CheckmarkLine,
  FilterLine,
  LockLine,
  PersonMultipleLine,
  TargetLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import { UIIcon } from '@dropbox/dig-icons/dist/mjs/ui_icon';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DashFilter } from '@mirage/analytics/events/types/select_dash_filter';
import { useStacksTabFilterPreference } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/useStacksTabFilterPreference';
import {
  StackFilterOption,
  StackFilterOptionToPapName,
} from '@mirage/service-settings/service/types';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import { useIsCompanyStacksEnabled } from '@mirage/stacks/useIsCompanyStacksEnabled';
import i18n from '@mirage/translations';
import { useCallback } from 'react';

// Improvement: if we refactor the StackFilterChips, we could use the menu component and move into dash component library
export const StacksTabFilterButton = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isCompanyStacksEnabled = useIsCompanyStacksEnabled();
  const stacksFilterMenuOptions = [
    {
      key: StackFilterOption.ALL,
      label: i18n.t('for_you_label'),
      icon: TargetLine,
    },
    {
      key: StackFilterOption.MINE,
      label: i18n.t('created_by_me'),
      icon: LockLine,
    },
    {
      key: StackFilterOption.SHARED,
      label: i18n.t('shared_with_me'),
      icon: PersonMultipleLine,
    },
    isCompanyStacksEnabled
      ? {
          key: StackFilterOption.COMPANY,
          label: 'From my company',
          icon: TeamLine,
        }
      : undefined,
  ].filter(isDefined);

  const { startPageStackFilterPreference, setStartPageStackFilterPreference } =
    useStacksTabFilterPreference();
  const selectedFilter = startPageStackFilterPreference;
  const handleOnSelection = useCallback(
    (key: StackFilterOption) => {
      reportPapEvent(
        PAP_Select_DashFilter({
          featureLine: 'stacks',
          actionSurfaceComponent: 'tab_panel',
          dashStackFilterName: StackFilterOptionToPapName[key],
        }),
      );
      setStartPageStackFilterPreference(key);
    },
    [reportPapEvent, setStartPageStackFilterPreference],
  );

  return (
    <Menu.Wrapper onSelection={handleOnSelection}>
      {({ getContentProps, getTriggerProps }) => (
        <>
          <IconButton
            variant="transparent"
            withDropdownIcon
            aria-label={i18n.t('filter_option_label')}
            {...getTriggerProps()}
          >
            <UIIcon src={FilterLine} />
          </IconButton>
          <Menu.Content {...getContentProps()} minWidth={236}>
            <Menu.Segment withLabel={i18n.t('filter_option_label')}>
              {stacksFilterMenuOptions.map(({ key, label, icon }) => (
                <Menu.ActionItem
                  key={key}
                  value={key}
                  withLeftAccessory={<UIIcon src={icon} />}
                  withRightAccessory={
                    selectedFilter === key && <UIIcon src={CheckmarkLine} />
                  }
                  style={{ alignItems: 'center' }}
                >
                  {label}
                </Menu.ActionItem>
              ))}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
