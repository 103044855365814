import {
  clearCachedFeatureValues,
  ExperimentationAttributes,
  getCachedFlags,
  listenForFeatures,
  refreshAllFeatureValues,
  setExperimentationAttributes,
  startSyncFeatureFlags,
} from '@mirage/service-experimentation';
import { startSyncFeatureRingSettings } from '@mirage/service-feature-ring-settings';
import { useFeatureRingSettings } from '@mirage/service-feature-ring-settings/hooks/useFeatureRingSettings';
import { tagged } from '@mirage/service-logging';
import {
  logPageLoadMilestone,
  logPageLoadMilestoneOnce,
} from '@mirage/service-operational-metrics/page-load';
import { runWithTimeLimit, sleepMs } from '@mirage/shared/util/tiny-utils';
import * as Sentry from '@sentry/react';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FeatureFlag, FeatureFlags, features } from './features';

const logger = tagged('useInitFeatureFlags');

/**
 * The spinner auto-logout is currently set at 30s, so using 25s here to give
 * a 5s buffer. This probably shouldn't be set too low in case some user has
 * very slow internet, and we don't want them to get logged out.
 */
const REFRESH_TIME_LIMIT_MS = 25_000;

export const enum FeatureFlagsStatus {
  NOT_READY,
  LOGGED_OUT_READY,
  LOGGED_IN_READY,
}

const enum ProcessingStatus {
  FREE,
  PROCESSING_LOGGED_OUT,
  PROCESSING_LOGGED_IN,
}

const featureFlagsAtom = atom<FeatureFlags>(features);

// Avoid redundant updates across multiple hooks of `useUpdateFeatureFlags`.
let currentFlags = features;

function useUpdateFeatureFlags() {
  const setFeatureFlags = useSetAtom(featureFlagsAtom);

  const updateFeatureFlags = useCallback(
    (flags: FeatureFlag[]) => {
      const newFlags = { ...currentFlags };
      flags.forEach((flag) => (newFlags[flag.featureName] = flag));

      if (!isEqual(currentFlags, newFlags)) {
        currentFlags = newFlags;
        setFeatureFlags(newFlags);
        logPageLoadMilestoneOnce('useUpdateFeatureFlags updateFeatureFlags');
        logger.debug(`updateFeatureFlags`);
      }
    },
    [setFeatureFlags],
  );

  return updateFeatureFlags;
}

// Export for testing only.
export async function refreshFlagsWithLoggingAndTimeout() {
  const startTime = Date.now();
  try {
    return await runWithTimeLimit<FeatureFlag[]>(
      refreshAllFeatureValues(),
      REFRESH_TIME_LIMIT_MS,
    );
  } finally {
    const fetchTime = Date.now() - startTime;

    if (fetchTime > 3000) {
      const msg = `Slow refreshAllFeatureValues: ${fetchTime}ms`;
      logger.warn(msg);
      Sentry.captureMessage(msg, 'warning');
    } else {
      logger.info(
        `[useInitFeatureFlags] Logout refreshAllFeatureValues took ${fetchTime}ms`,
      );
    }
  }
}

export function useUpdateExperimentationAttributes() {
  const updateFeatureFlags = useUpdateFeatureFlags();

  return useCallback(
    async (attributes: ExperimentationAttributes) => {
      setExperimentationAttributes(attributes);
      const flags = await refreshFlagsWithLoggingAndTimeout();
      updateFeatureFlags(flags);
    },
    [updateFeatureFlags],
  );
}

export type InitFeatureFlagsOptions = {
  onLoggedInAndSuccess?: () => void;
  onLoggedInAndError?: (error: unknown) => void;
};

/**
 * This only needs to be called once, at the top-level app (before login).
 * To get the feature flag values, just use `useFeatureFlags()`.
 */
export function useInitFeatureFlags(
  loggedIn: boolean | undefined,
  options?: InitFeatureFlagsOptions,
) {
  logPageLoadMilestoneOnce('useInitFeatureFlags start');

  const updateFeatureFlags = useUpdateFeatureFlags();
  const { getCurrentFeatureRing } = useFeatureRingSettings();

  const [featureFlagsStatus, setFeatureFlagsStatus] = useState(
    FeatureFlagsStatus.NOT_READY,
  );

  const previousLoggedIn = useRef<boolean>();
  const processingStatus = useRef(ProcessingStatus.FREE);
  const lastStateChangeTime = useRef<number>();
  const stateChangeCount = useRef(0);

  const { onLoggedInAndSuccess, onLoggedInAndError } = options ?? {};

  useEffect(() => {
    async function setLoggedOutFeatureFlags() {
      logPageLoadMilestone('setLoggedOutFeatureFlags start');
      logger.info('setLoggedOutFeatureFlags start');

      processingStatus.current = ProcessingStatus.PROCESSING_LOGGED_OUT;
      try {
        const flags = await refreshFlagsWithLoggingAndTimeout();

        logger.info('setLoggedOutFeatureFlags after refreshAllFeatureValues');
        updateFeatureFlags(flags);
      } catch (e) {
        Sentry.captureMessage(
          `[useInitFeatureFlags] Error fetching logged-out flags: ${e}`,
        );
      } finally {
        // Don't let an error block the app from loading.
        setFeatureFlagsStatus(FeatureFlagsStatus.LOGGED_OUT_READY);
        logPageLoadMilestone('setLoggedOutFeatureFlags LOGGED_OUT_READY');
        logger.info('setLoggedOutFeatureFlags LOGGED_OUT_READY');

        processingStatus.current = ProcessingStatus.FREE;
      }
    }

    async function setLoggedInFeatureFlags() {
      logPageLoadMilestone('setLoggedInFeatureFlags start');
      logger.info('setLoggedInFeatureFlags start');

      processingStatus.current = ProcessingStatus.PROCESSING_LOGGED_IN;
      const startTime = Date.now();

      let cached: FeatureFlag[] | undefined;
      try {
        cached = await getCachedFlags();
        const cacheTime = Date.now() - startTime;

        if (cacheTime > 2000) {
          Sentry.captureMessage(
            `[useInitFeatureFlags] Slow cache fetch: ${cacheTime}ms`,
          );
        }

        if (cached.length) {
          updateFeatureFlags(cached);
          setFeatureFlagsStatus(FeatureFlagsStatus.LOGGED_IN_READY);
          logPageLoadMilestone(
            'setLoggedInFeatureFlags LOGGED_IN_READY cached',
          );
          logger.info('setLoggedInFeatureFlags LOGGED_IN_READY cached');
        }

        if (!cached.length) {
          logPageLoadMilestone(
            `setLoggedInFeatureFlags before getAllCachedOrFetchFeatureValues`,
          );
          logger.info('setLoggedInFeatureFlags refreshAllFeatureValues');

          const flags = await refreshFlagsWithLoggingAndTimeout();

          if (
            processingStatus.current !== ProcessingStatus.PROCESSING_LOGGED_IN
          ) {
            Sentry.captureMessage(
              `[useInitFeatureFlags] Processing status changed during flag fetch`,
            );
          }

          updateFeatureFlags(flags);
        }

        onLoggedInAndSuccess?.();
      } catch (e) {
        Sentry.captureMessage(
          `[useInitFeatureFlags] Error in logged-in flow: ${e}`,
        );
        onLoggedInAndError?.(e);
      } finally {
        // Don't let an error block the app from loading.
        if (!cached?.length) {
          setFeatureFlagsStatus(FeatureFlagsStatus.LOGGED_IN_READY);
          logPageLoadMilestone('setLoggedInFeatureFlags LOGGED_IN_READY');
          logger.info('setLoggedInFeatureFlags LOGGED_IN_READY');
        }

        processingStatus.current = ProcessingStatus.FREE;

        logPageLoadMilestone('setLoggedInFeatureFlags end');
      }
    }

    // Clear cached flags when login status changes.
    if (loggedIn !== undefined) {
      if (previousLoggedIn.current === undefined) {
        previousLoggedIn.current = loggedIn;
      } else if (previousLoggedIn.current !== loggedIn) {
        const now = Date.now();
        if (lastStateChangeTime.current) {
          const timeSinceLastChange = now - lastStateChangeTime.current;
          stateChangeCount.current++;

          if (timeSinceLastChange < 5000 && stateChangeCount.current > 1) {
            Sentry.captureMessage(
              `[useInitFeatureFlags] Rapid login state changes: ${stateChangeCount.current} changes in ${timeSinceLastChange}ms`,
            );
          }
        }

        lastStateChangeTime.current = now;
        previousLoggedIn.current = loggedIn;

        logPageLoadMilestone('useInitFeatureFlags clearCachedFeatureValues');
        logger.info('clearCachedFeatureValues');
        clearCachedFeatureValues();
      }
    }

    async function startSyncJobs() {
      // Wait for page load to complete before starting.
      await sleepMs(2000);

      startSyncFeatureRingSettings();
      startSyncFeatureFlags();
    }

    async function init() {
      switch (loggedIn) {
        case false:
          if (
            featureFlagsStatus !== FeatureFlagsStatus.LOGGED_OUT_READY &&
            processingStatus.current !== ProcessingStatus.PROCESSING_LOGGED_OUT
          ) {
            await setLoggedOutFeatureFlags();
            await startSyncJobs();
          }
          break;
        case true:
          if (
            featureFlagsStatus !== FeatureFlagsStatus.LOGGED_IN_READY &&
            processingStatus.current !== ProcessingStatus.PROCESSING_LOGGED_IN
          ) {
            await setLoggedInFeatureFlags();
            await startSyncJobs();
          }
          break;
        case undefined:
          // Login state unknown, so do nothing.
          break;
        default:
          loggedIn satisfies never;
      }
    }

    init();
  }, [
    featureFlagsStatus,
    getCurrentFeatureRing,
    loggedIn,
    onLoggedInAndError,
    onLoggedInAndSuccess,
    setFeatureFlagsStatus,
    updateFeatureFlags,
  ]);

  useEffect(() => {
    // As we get new feature values from various adapters (stormcrow,
    // growthbook) we should update our store. This ensures we're not waiting
    // on the slowest adapter to return before updating the UI.
    const subscription = listenForFeatures().subscribe((flags) => {
      updateFeatureFlags(flags);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [updateFeatureFlags]);

  return featureFlagsStatus;
}

export function useFeatureFlags() {
  return useAtomValue(featureFlagsAtom);
}
