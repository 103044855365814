import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashConnectAppPageContinue } from '@mirage/analytics/events/types/open_dash_connect_app_page_continue';
import { PAP_Open_DashConnectAppPageSkip } from '@mirage/analytics/events/types/open_dash_connect_app_page_skip';
import { PAP_Select_DashConnectAppPageAddLater } from '@mirage/analytics/events/types/select_dash_connect_app_page_add_later';
import { PAP_Shown_DashConnectAppPage } from '@mirage/analytics/events/types/shown_dash_connect_app_page';
import {
  OnboardingHeader,
  OnboardingHeaderWithStepper,
} from '@mirage/growth/components/OnboardingHeader';
import { listConnections } from '@mirage/service-connectors';
import { filterBrowserExtensionConnections } from '@mirage/settings/hooks/connectors/useConnectConnector';
import { fetchConnectorsWithVisibilityFilters } from '@mirage/settings/utils/connectorMetadataService';
import {
  useConnections,
  useSetConnections,
} from '@mirage/shared/atoms/connections';
import { useSetConnectors } from '@mirage/shared/atoms/connectors';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { ConfirmSkipAddingAppsModal } from './ConfirmSkipAddingAppsModal';
import { ONBOARDING_VERSION } from './constants';
import { EcosystemConnectorsList } from './EcosystemConnectorsList';
import styles from './Onboarding.module.css';
import { PageContainer, PrivacyFooter } from './OnboardingComponents';
import { PopularConnectorsList } from './PopularConnectorsList';
import { useFinishOnboarding } from './utils';

const onboardingVariants = {
  [ONBOARDING_VERSION.SINGLE_STEP]: 'SINGLE_STEP_V1',
  [ONBOARDING_VERSION.SINGLE_STEP_MOBILE]: 'SINGLE_STEP_V1',
  [ONBOARDING_VERSION.SINGLE_STEP_B]: 'SINGLE_STEP_V2_B',
  [ONBOARDING_VERSION.SINGLE_STEP_C]: 'SINGLE_STEP_V2_C',
  [ONBOARDING_VERSION.ECOSYSTEM_CONNECTORS]:
    'SINGLE_STEP_V3_ECOSYSTEM_CONNECTORS',
  [ONBOARDING_VERSION.SURVEY_JOBS_TO_BE_DONE]: 'SURVEY_JOBS_TO_BE_DONE',
};

const getOnboardingVariant = (
  version: SingleStepOnboardingVariants,
): string => {
  return onboardingVariants[version];
};

type SingleStepOnboardingVariants = Extract<
  ONBOARDING_VERSION,
  | ONBOARDING_VERSION.SINGLE_STEP
  | ONBOARDING_VERSION.SINGLE_STEP_MOBILE
  | ONBOARDING_VERSION.SINGLE_STEP_B
  | ONBOARDING_VERSION.SINGLE_STEP_C
  | ONBOARDING_VERSION.ECOSYSTEM_CONNECTORS
  | ONBOARDING_VERSION.SURVEY_JOBS_TO_BE_DONE
>;

// SingleStepOnboarding renders:
// - the ON variant for [single_step_v1](https://app.dropboxexperiment.com/features/dash_onboarding_single_step_v1);
// - or the B or C variant for [single_step_v2](https://app.dropboxexperiment.com/features/dash_onboarding_single_step_v2)
export const SingleStepOnboarding = ({
  version,
}: {
  version: SingleStepOnboardingVariants;
}) => {
  const connections = useConnections();
  const setConnections = useSetConnections();
  const setConnectors = useSetConnectors();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const haveNoConnectors = connections.data.length === 0;

  useEffect(() => {
    reportPapEvent(PAP_Shown_DashConnectAppPage({ inOnboardingFlow: true }));
  }, [reportPapEvent]);

  useEffect(() => {
    async function doFetchConnectors() {
      setConnectors((atom) => ({ ...atom, loading: true }));
      const connectors = await fetchConnectorsWithVisibilityFilters();
      setConnectors({
        data: connectors.map((c) => ({ ...c, loading: false })),
        loading: false,
        loaded: true,
      });
    }
    doFetchConnectors();

    async function doFetchConnections() {
      setConnections((atom) => ({ ...atom, loading: true }));
      const connections = await listConnections();
      setConnections({
        data: connections
          .map((c) => ({
            ...c,
            loading: false,
            syncing: false,
          }))
          .filter(filterBrowserExtensionConnections),
        loading: false,
        loaded: true,
      });
    }
    doFetchConnections();
  }, [setConnectors, setConnections]);

  const finishOnboarding = useFinishOnboarding(
    reportPapEvent,
    getOnboardingVariant(version),
  );

  const handleFinishOnboarding = async () => {
    if (haveNoConnectors) {
      reportPapEvent(PAP_Open_DashConnectAppPageSkip());
    } else {
      reportPapEvent(PAP_Open_DashConnectAppPageContinue());
    }

    finishOnboarding();
  };

  const LeftPanelVariant = getLeftPanelVariant(version);
  const RightPanelListVariant =
    version === ONBOARDING_VERSION.ECOSYSTEM_CONNECTORS
      ? EcosystemConnectorsList
      : PopularConnectorsList;

  return (
    <div>
      <PageContainer>
        <div className={styles.leftPanel}>
          {version === ONBOARDING_VERSION.SURVEY_JOBS_TO_BE_DONE ? (
            // Show stepper for JTBD survey
            <OnboardingHeaderWithStepper currentStep={4} totalSteps={4} />
          ) : (
            <OnboardingHeader />
          )}
          <Box>
            <Title weightVariant="emphasized" className={styles.title}>
              {i18n.t('single_step_onboarding_title')}
            </Title>
            <Text color="faint">
              {version === ONBOARDING_VERSION.SINGLE_STEP_B ||
              version === ONBOARDING_VERSION.SINGLE_STEP_C
                ? i18n.t('single_step_onboarding_v2_subtitle')
                : i18n.t('single_step_onboarding_subtitle')}
            </Text>
          </Box>
          <LeftPanelVariant
            haveNoConnectors={haveNoConnectors}
            finishOnboarding={handleFinishOnboarding}
          />
          <Box flexGrow={1}></Box>
          <PrivacyFooter />
        </div>
        <div className={styles.rightPanel}>
          <RightPanelListVariant />
        </div>
      </PageContainer>
    </div>
  );
};

type LeftPanelVariantProps = {
  haveNoConnectors: boolean;
  finishOnboarding: () => Promise<void>;
};

const getLeftPanelVariant = (
  version: string,
): React.FC<LeftPanelVariantProps> => {
  const variant = {
    [ONBOARDING_VERSION.ECOSYSTEM_CONNECTORS]:
      SingleStepOnboardingWithSkipModal,
    [ONBOARDING_VERSION.SURVEY_JOBS_TO_BE_DONE]:
      SingleStepOnboardingWithSkipModal,
    [ONBOARDING_VERSION.SINGLE_STEP]: SingleStepOnboardingSkippable,
    [ONBOARDING_VERSION.SINGLE_STEP_MOBILE]: SingleStepOnboardingSkippable,
    [ONBOARDING_VERSION.SINGLE_STEP_B]: SingleStepOnboardingWithSkipModal,
    [ONBOARDING_VERSION.SINGLE_STEP_C]:
      SingleStepOnboardingWithMandatoryConnect,
  }[version];

  if (!variant) throw new Error(`Invalid onboarding version: ${version}`);

  return variant;
};

// Allows the user to skip adding apps.
// Used with version="single-step".
const SingleStepOnboardingSkippable = ({
  haveNoConnectors,
  finishOnboarding,
}: LeftPanelVariantProps) => {
  const isMobile = useIsMobileSize();

  return (
    <div className={styles.buttonsContainer}>
      {isMobile ? (
        <Box
          display="flex"
          flexGrow={1}
          backgroundColor={'Background Base'}
          padding={haveNoConnectors ? 'Micro Medium' : 'Micro XLarge'}
        >
          {haveNoConnectors ? (
            <Button
              variant="borderless"
              size="large"
              fullWidth
              className={classnames(['skipCta'])}
              onClick={finishOnboarding}
            >
              {i18n.t('skip_button')}
            </Button>
          ) : (
            <Button
              variant="primary"
              size="large"
              fullWidth
              className={classnames(['roundedCorners', 'continueCta'])}
              disabled={haveNoConnectors}
              onClick={finishOnboarding}
            >
              {i18n.t('continue_button')}
            </Button>
          )}
        </Box>
      ) : (
        <>
          <Box display="flex" flexGrow={1}>
            <Button
              variant="primary"
              size="xlarge"
              fullWidth
              className={classnames(['roundedCorners', 'continueCta'])}
              disabled={haveNoConnectors}
              onClick={finishOnboarding}
            >
              {i18n.t('continue_button')}
            </Button>
          </Box>
          {haveNoConnectors && (
            <Box display="flex" justifyContent="center" marginTop="8">
              <Button
                variant="transparent"
                size="medium"
                className={classnames(['roundedCorners', 'skipCta'])}
                onClick={finishOnboarding}
              >
                {i18n.t('skip_button')}
              </Button>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

// Allows the user to skip adding apps but shows a modal to nudge them against doing so.
// Used with version="single-step-b".
const SingleStepOnboardingWithSkipModal = ({
  haveNoConnectors,
  finishOnboarding,
}: LeftPanelVariantProps) => {
  const isMobile = useIsMobileSize();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleSkipButtonClick = () => {
    setConfirmModalOpen(true);
    reportPapEvent(PAP_Select_DashConnectAppPageAddLater());
  };

  return (
    <>
      <ConfirmSkipAddingAppsModal
        finishOnboarding={finishOnboarding}
        onRequestClose={() => setConfirmModalOpen(false)}
        open={confirmModalOpen}
      />
      <div className={styles.buttonsContainer}>
        {isMobile ? (
          <Box
            display="flex"
            flexGrow={1}
            backgroundColor={'Background Base'}
            padding={haveNoConnectors ? 'Micro Medium' : 'Micro XLarge'}
          >
            {haveNoConnectors ? (
              <Button
                className={classnames(['skipCta'])}
                fullWidth
                onClick={handleSkipButtonClick}
                size="large"
                variant="borderless"
              >
                {i18n.t('skip_button')}
              </Button>
            ) : (
              <Button
                className={classnames(['roundedCorners', 'continueCta'])}
                disabled={haveNoConnectors}
                fullWidth
                onClick={finishOnboarding}
                size="large"
                variant="primary"
              >
                {i18n.t('continue_button')}
              </Button>
            )}
          </Box>
        ) : (
          <>
            <Box display="flex" flexGrow={1}>
              <Button
                className={classnames(['roundedCorners', 'continueCta'])}
                disabled={haveNoConnectors}
                fullWidth
                onClick={finishOnboarding}
                size="xlarge"
                variant="primary"
              >
                {i18n.t('continue_button')}
              </Button>
            </Box>
            {haveNoConnectors && (
              <Box display="flex" justifyContent="center" marginTop="8">
                <Button
                  className={classnames(['roundedCorners', 'skipCta'])}
                  onClick={handleSkipButtonClick}
                  size="medium"
                  variant="transparent"
                >
                  {i18n.t('skip_button')}
                </Button>
              </Box>
            )}
          </>
        )}
      </div>
    </>
  );
};

// Requires that the user connects at least one app.
// Used with version="single-step-c".
const SingleStepOnboardingWithMandatoryConnect = ({
  haveNoConnectors,
  finishOnboarding,
}: LeftPanelVariantProps) => {
  const isMobile = useIsMobileSize();

  return (
    <div
      className={styles.buttonsContainer}
      style={{ alignItems: isMobile ? 'initial' : 'center' }}
    >
      {isMobile ? (
        <Box
          display="flex"
          flexGrow={1}
          backgroundColor={'Background Base'}
          padding="Micro XLarge"
        >
          <Button
            variant="primary"
            size="large"
            fullWidth
            className={classnames(['roundedCorners', 'continueCta'])}
            disabled={haveNoConnectors}
            onClick={finishOnboarding}
          >
            {i18n.t('continue_button')}
          </Button>
        </Box>
      ) : (
        <>
          <Button
            variant="primary"
            size="xlarge"
            fullWidth
            className={classnames(['roundedCorners', 'continueCta'])}
            disabled={haveNoConnectors}
            onClick={finishOnboarding}
          >
            {i18n.t('continue_button')}
          </Button>
          {haveNoConnectors && (
            <Text color="faint" size="small">
              {i18n.t('continue_button_disabled_instructions')}
            </Text>
          )}
        </>
      )}
    </div>
  );
};
