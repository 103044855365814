import { Text } from '@dropbox/dig-components/typography';
import styled from '@emotion/styled';
import React from 'react';

type Props = {
  children: string;
};

export default function KeyCap({ children }: Props) {
  return (
    <KeyCapContainer>
      <Text monospace={true} color="faint">
        {children}
      </Text>
    </KeyCapContainer>
  );
}

const KeyCapContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dig-color__background__subtle);
  border-radius: 4px;
  border: 0.5px solid var(--dig-color__border__subtle);

  height: 24px;
  min-width: 24px;
  box-sizing: border-box;
  padding: 2px 8px;
`;
