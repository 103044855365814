import { Card } from '@dropbox/dash-component-library';
import { Text } from '@dropbox/dig-components/typography';
import { Cluster } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import { PAP_Click_DashAnswersFeedback } from '@mirage/analytics/events/types/click_dash_answers_feedback';
import { PAP_Click_DashAnswersHide } from '@mirage/analytics/events/types/click_dash_answers_hide';
import { PAP_Click_DashAnswersShow } from '@mirage/analytics/events/types/click_dash_answers_show';
import { PAP_Click_DashAnswersSource } from '@mirage/analytics/events/types/click_dash_answers_source';
import { PAP_Click_DashAnswersTaggedFeedback } from '@mirage/analytics/events/types/click_dash_answers_tagged_feedback';
import { PAP_Copy_DashAnswers } from '@mirage/analytics/events/types/copy_dash_answers';
import { PAP_Expand_DashAnswers } from '@mirage/analytics/events/types/expand_dash_answers';
import { PAP_Shown_DashSearchResult } from '@mirage/analytics/events/types/shown_dash_search_result';
import { ANSWER_DWELL_END_REASONS } from '@mirage/analytics/session/session-utils';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { ChatEntryPoint } from '@mirage/conversations/types';
import { Link } from '@mirage/link/Link';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { openResult } from '@mirage/service-result-actions';
import {
  MultiAnswerResponse,
  QuestionAndAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import { usePageVisibility } from '@mirage/shared/hooks/usePageVisibility';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { FeedbackOptions } from '@mirage/shared/types';
import { AI_LEARN_MORE_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import { useResizeObserver } from '@react-hookz/web';
import classNames from 'classnames';
import { Fragment, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Answer } from './Answer';
import styles from './MultiAnswerCard.module.css';
import { NoAnswer } from './NoAnswer';

const ESTIMATED_BASE_CARD_HEIGHT = 80;

const featureLine: FeatureLine = 'multi_answers';

type MultiAnswerCardProps = {
  multiAnswerResponse: MultiAnswerResponse | undefined;
  loading?: boolean;
  query: string;
  onShowFeedbackSnackbar: () => void;
  onShowCopyMessageSnackbar: () => void;
  annotationMode?: boolean;
  onOpenAnnotationModal: (
    feedback: FeedbackOptions,
    answer: QuestionAndAnswerResponse,
  ) => void;
};

export const MultiAnswerCard: React.FC<MultiAnswerCardProps> = ({
  multiAnswerResponse,
  loading,
  query,
  onShowFeedbackSnackbar,
  onShowCopyMessageSnackbar,
  annotationMode = false,
  onOpenAnnotationModal,
}) => {
  const [cardHeight, setCardHeight] = useState(0);
  const isPageVisible = usePageVisibility();
  const isCardVisible = useRef(false);
  const isMobile = useIsMobileSize();
  const isDesktop = EnvCtx.surface === 'desktop';
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const { searchQueryUuid } = useSearchQueryId();
  const { reportPapEvent, implicitAnswerDwellManager, searchSessionManager } =
    useMirageAnalyticsContext();

  const isEmpty =
    !multiAnswerResponse?.answers || multiAnswerResponse?.answers.length == 0;

  // Ensure we don't log end session events when component re-renders due to loading or change in answers
  const canToggleSession = !isEmpty && !loading;

  // Create answer ID for logging purposes
  const answerLoggingId = useRef(uuid());

  const handleResize = () => {
    if (ref?.current) {
      setCardHeight(ref.current.clientHeight + ESTIMATED_BASE_CARD_HEIGHT);
    }
  };
  useResizeObserver(ref, handleResize);

  useEffect(() => {
    if (canToggleSession) {
      // Create dwell session
      implicitAnswerDwellManager.createSession();
      implicitAnswerDwellManager.updateProperties({
        queryString: query,
        entryPoint: ChatEntryPoint.search_bar_inline,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchRequestId: multiAnswerResponse?.requestUuid,
        featureLine,
      });

      // Log shown event for each answer
      multiAnswerResponse?.answers?.map((answer) => {
        reportPapEvent(
          PAP_Shown_DashSearchResult({
            queryString: query,
            answerString: answer.answer,
            generatedQueryString: answer.question,
            answerId: answerLoggingId.current,
            numAnswerSources: answer.sources.length,
            entryPoint: ChatEntryPoint.search_bar_inline,
            searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
            searchRequestId: multiAnswerResponse?.requestUuid,
            searchQueryUuid,
            featureLine,
          }),
        );
      });
    }

    return () => {
      if (canToggleSession) {
        implicitAnswerDwellManager.endSession(ANSWER_DWELL_END_REASONS.EXITED);
      }
    };
    // Disabling because we do not want to log whenever `query` or `searchQueryId` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    multiAnswerResponse,
    reportPapEvent,
    canToggleSession,
    searchSessionManager,
    implicitAnswerDwellManager,
  ]);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Answer card is visible in viewport
          isCardVisible.current = true;
          implicitAnswerDwellManager.extendOrCreateSession();
          implicitAnswerDwellManager.updateProperties({
            queryString: query,
            entryPoint: ChatEntryPoint.search_bar_inline,
            searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
            searchQueryUuid: searchQueryUuid,
            searchRequestId: multiAnswerResponse?.requestUuid,
            featureLine,
          });
        } else {
          // User has scrolled the answer card out of view. We should end the session
          isCardVisible.current = false;
          implicitAnswerDwellManager.endSession(
            ANSWER_DWELL_END_REASONS.SCROLLED,
          );
        }
      });
    });

    observer.observe(ref.current);
    const refCurrent = ref.current;
    return () => {
      if (refCurrent) {
        observer.unobserve(refCurrent);
      }
    };
  }, [
    ref,
    multiAnswerResponse?.requestUuid,
    searchQueryUuid,
    query,
    implicitAnswerDwellManager,
    searchSessionManager,
  ]);

  useEffect(() => {
    if (isPageVisible && isCardVisible.current) {
      // User switched back to the page and answer card is in view
      implicitAnswerDwellManager.extendOrCreateSession();
      implicitAnswerDwellManager.updateProperties({
        queryString: query,
        entryPoint: ChatEntryPoint.search_bar_inline,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchQueryUuid: searchQueryUuid,
        searchRequestId: multiAnswerResponse?.requestUuid,
        featureLine,
      });
    } else if (!isPageVisible) {
      // User has switched tabs / minimized window / minimized app, we should end the sessions
      implicitAnswerDwellManager.endSession(
        ANSWER_DWELL_END_REASONS.LOST_FOCUS,
      );
    }
  }, [
    implicitAnswerDwellManager,
    isPageVisible,
    multiAnswerResponse?.requestUuid,
    searchQueryUuid,
    query,
    searchSessionManager,
  ]);

  const getCommonPapFields = () => {
    return {
      queryString: query,
      answerId: answerLoggingId.current,
      entryPoint: ChatEntryPoint.search_bar_inline,
      featureLine: 'multi_answers' as FeatureLine,
      searchQueryUuid,
      searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      searchRequestId: multiAnswerResponse?.requestUuid,
    };
  };

  const handleCopyMessage = (item: QuestionAndAnswerResponse) => {
    onShowCopyMessageSnackbar();
    copyToClipboard(item.answer || '');

    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Copy_DashAnswers({
        answerPositionIndex: item.position,
        answerString: item.answer,
        generatedQueryString: item.question,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleSourceClick = async (
    item: QuestionAndAnswerResponse,
    source: QuestionAndAnswerSource,
  ) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersSource({
        answerPositionIndex: item.position,
        answerString: item.answer,
        ...getCommonPapFields(),
      }),
    );

    await openResult(source);
  };

  const handleClickFeedback = (
    item: QuestionAndAnswerResponse,
    feedback: FeedbackOptions,
  ) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersFeedback({
        answerPositionIndex: item.position,
        answerString: item.answer,
        dashAnswerFeedback: feedback,
        generatedQueryString: item.question,
        ...getCommonPapFields(),
      }),
    );
    if (annotationMode && feedback === FeedbackOptions.Negative) {
      onOpenAnnotationModal(feedback, item);
    }
  };

  const handleClickSecondaryFeedback = (
    item: QuestionAndAnswerResponse,
    feedback: DashAnswersFeedbackTag,
  ) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersTaggedFeedback({
        answerPositionIndex: item.position,
        answerString: item.answer,
        dashAnswersFeedbackTag: feedback,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleExpand = (item: QuestionAndAnswerResponse) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersShow({
        answerPositionIndex: item.position,
        generatedQueryString: item.question,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleCollapse = (item: QuestionAndAnswerResponse) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersHide({
        answerPositionIndex: item.position,
        generatedQueryString: item.question,
        ...getCommonPapFields(),
      }),
    );
  };

  return (
    <>
      <Card
        variant="outline"
        loading={loading}
        className={classNames(styles.container, {
          [styles.isMobile]: isMobile,
          [styles.isDesktop]: isDesktop,
        })}
        style={{
          maxHeight: loading
            ? `${ESTIMATED_BASE_CARD_HEIGHT}px`
            : `${cardHeight}px`,
          transition: 'max-height 750ms cubic-bezier(0.17, 0, 0.09, 1)',
        }}
      >
        <Card.Header
          withMargin={!loading}
          title={
            <Cluster>
              <Cluster.Item>
                <Text tagName="h2" variant="label" isBold>
                  {i18n.t('answers_header_title')}
                </Text>
              </Cluster.Item>
              <Cluster.Item>
                <BetaBadge className={styles.badge} />
              </Cluster.Item>
            </Cluster>
          }
        />
        <div
          ref={ref}
          className={classNames({ [styles.cardContent]: !loading })}
        >
          {/* Looading */}
          {loading && <Fragment></Fragment>}

          {/* Loaded, no anaswer available */}
          {!loading && isEmpty && (
            <Fragment>
              <NoAnswer />
              <div className={styles.divider} />
            </Fragment>
          )}

          {/* Loaded, anaswer available! */}
          {!loading &&
            !isEmpty &&
            multiAnswerResponse?.answers?.map((answer, index) => {
              return (
                <Fragment key={answer.conversationId}>
                  <Answer
                    answer={answer}
                    answerId={answer.conversationId}
                    expandedByDefault={index == 0}
                    onShowFeedbackSnackbar={onShowFeedbackSnackbar}
                    onCopyMessage={handleCopyMessage}
                    onSourceClick={handleSourceClick}
                    onClickFeedback={handleClickFeedback}
                    onClickSecondaryFeedback={handleClickSecondaryFeedback}
                    onCollapse={() => {
                      handleCollapse(answer);
                    }}
                    onExpand={() => {
                      handleExpand(answer);
                    }}
                    annotationMode={annotationMode}
                    onClickMore={() => {
                      reportPapEvent(
                        PAP_Expand_DashAnswers({
                          answerString: answer.answer,
                          answerPositionIndex: answer.position,
                          ...getCommonPapFields(),
                        }),
                      );
                    }}
                  />
                  <div className={styles.divider} />
                </Fragment>
              );
            })}

          {/* "Experimental feature" disclaimer whenever we're not loading */}
          {!loading && (
            <Text
              size="small"
              className={styles.experimentalNoticeText}
              color="faint"
            >
              {i18n.t('answers_disclaimer_text')}
              &nbsp;
              <Link
                variant="monochromatic"
                href={AI_LEARN_MORE_URL}
                data-uxa-log={createUxaElementId('learn_more_link', {
                  actionSurfaceComponent: 'inline_answer',
                  featureLine: 'multi_answers',
                })}
              >
                {i18n.t('learn_more')}
              </Link>
            </Text>
          )}
        </div>
      </Card>
      {!loading && !isEmpty && annotationMode && (
        <Card className={styles.container}>
          <Text size="small" className={styles.experimentalNoticeText}>
            {i18n.t('dash_answers_annotations_info')}
            <br />
            <br />
            <em>{i18n.t('dash_answers_annotations_info_disclaimer')}</em>
          </Text>
        </Card>
      )}
    </>
  );
};
