import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { SourcesFromSettingsModalStepper } from '@mirage/mosaics/ComposeAssistant/components/settings/SourcesFromSettingsModalStepper';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import i18n from '@mirage/translations';
import { memo, useCallback } from 'react';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { SourcesFromSettingsConversationPane } from './SourcesFromSettingsConversationPane';
import { SourcesFromSettingsOne } from './SourcesFromSettingsOne';
import { SourcesFromSettingsPreviewPane } from './SourcesFromSettingsPreviewPane';
import { SourcesFromSettingsThree } from './SourcesFromSettingsThree';
import { SourcesFromSettingsTwo } from './SourcesFromSettingsTwo';
import { useVoiceSettings } from './useVoiceSettings';

interface VoiceSettingsModalProps {
  voices: ComposeVoice[];
  saveVoice: (voice: ComposeVoice) => Promise<void>;
  onDeleteVoice: (voiceId: string) => void;
  initialVoiceID?: string;
  onRequestClose: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const VoiceSettingsModal = memo(
  ({
    voices,
    saveVoice,
    onDeleteVoice,
    initialVoiceID,
    onRequestClose,
    logComposeEvent,
  }: VoiceSettingsModalProps) => {
    const {
      currentVoiceState,
      isDeleteModalOpen,
      loading,
      sourcesContents,
      setIsDeleteModalOpen,
      handleAddSource,
      handleRemoveSource,
      handleClickDelete,
      handleChangeVoiceNameInput,
      handleChangeVoiceDescriptionInput,
      handleModifyVoicePostUserMessage,
      handleClickSave,
      postInitialMessage,
    } = useVoiceSettings({
      voices,
      saveVoice,
      initialVoiceID,
      logComposeEvent,
      onDone: onRequestClose,
    });
    const onMount = useCallback(() => {
      if (
        currentVoiceState.messagesHistory?.length === 0 &&
        currentVoiceState.redraftedSamples?.length === 0
      ) {
        postInitialMessage();
        handleModifyVoicePostUserMessage();
      }
    }, [
      handleModifyVoicePostUserMessage,
      postInitialMessage,
      currentVoiceState.messagesHistory,
      currentVoiceState.redraftedSamples,
    ]);
    return currentVoiceState ? (
      <SourcesFromSettingsModalStepper
        steps={[
          <SourcesFromSettingsOne
            key={0}
            title={i18n.t('compose_tones_pane_title')}
            description={i18n.t('compose_tones_pane_description')}
            sources={currentVoiceState?.sources}
            handleAddSource={handleAddSource}
            handleRemoveSource={handleRemoveSource}
            sourcesContents={sourcesContents}
            logComposeEvent={logComposeEvent}
          />,
          <SourcesFromSettingsTwo
            key={1}
            loading={loading}
            onMount={onMount}
            leftPaneContent={
              <SourcesFromSettingsConversationPane
                messages={currentVoiceState.messagesHistory}
                logComposeEvent={logComposeEvent}
                sources={currentVoiceState.sources}
                onSubmitMessage={handleModifyVoicePostUserMessage}
                loading={loading}
              />
            }
            rightPaneContent={
              <SourcesFromSettingsPreviewPane
                currentVoiceState={currentVoiceState}
              />
            }
          />,
          <SourcesFromSettingsThree
            key={2}
            title={i18n.t('compose_tones_modal_stepper_save_tone_title')}
            namePlaceholder={i18n.t(
              'compose_tones_modal_stepper_name_placeholder',
            )}
            descriptionPlaceholder={i18n.t(
              'compose_tones_modal_stepper_description_placeholder',
            )}
            onNameInputChange={handleChangeVoiceNameInput}
            onDescriptionInputChange={handleChangeVoiceDescriptionInput}
            nameValue={currentVoiceState.name}
            descriptionValue={currentVoiceState.description}
            deleteModal={
              <DeleteConfirmationModal
                title={i18n.t('compose_delete_tones_confirm_title')}
                description={i18n.t('compose_delete_tones_confirm_description')}
                onCancel={() => setIsDeleteModalOpen(false)}
                onSubmit={() => {
                  onDeleteVoice(currentVoiceState.id);
                }}
                isOpen={isDeleteModalOpen}
              />
            }
          />,
        ]}
        widths={[693, 1083, 693]}
        isOpen
        onRequestClose={onRequestClose}
        onDone={handleClickSave}
        onDelete={handleClickDelete}
      />
    ) : null;
  },
);
VoiceSettingsModal.displayName = 'VoiceSettingsModal';
