import { Text, Title } from '@dropbox/dig-components/typography';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { ComposeSources } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/ComposeSources';
import { SourcesContentCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { ComposeSource } from '@mirage/shared/compose/compose-session';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo } from 'react';
import { RenderFooterSettings } from './SourcesFromSettingsModalStepper';
import styles from './SourcesFromSettingsOne.module.css';

interface SourcesFromSettingsOneProps {
  title: string;
  description: string;
  sources: ComposeSource[];
  handleAddSource: (source: ComposeSource) => void;
  handleRemoveSource: (source: ComposeSource) => void;
  sourcesContents: SourcesContentCache;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  renderFooter?: (footerSettings: RenderFooterSettings) => JSX.Element; // renderFooter is derived from SourcesFromSettingsModalStepper
}
export const SourcesFromSettingsOne = memo(
  ({
    title,
    description,
    sources,
    handleAddSource,
    handleRemoveSource,
    sourcesContents,
    logComposeEvent,
    renderFooter,
  }: SourcesFromSettingsOneProps) => {
    const isMobile = useIsMobileSize();
    return (
      <>
        <div
          className={classnames(
            styles.SourcesFromSettingsOneContent,
            isMobile && styles.SourcesFromSettingsOneMobile,
          )}
        >
          <Title
            size="medium"
            className={styles.SourcesFromSettingsOneTitle}
            weightVariant="emphasized"
          >
            {title}
          </Title>
          <Text
            size="medium"
            tagName="div"
            color="subtle"
            className={styles.SourcesFromSettingsOneDescription}
          >
            {description}
          </Text>
          <div className={styles.SourcesFromSettingsOneControls}>
            <ComposeSources
              logComposeEvent={logComposeEvent}
              sources={sources || []}
              sourcesContentCache={sourcesContents}
              onAddSource={handleAddSource}
              onRemoveSource={handleRemoveSource}
              variant="sources-before-search-input"
              hasBorder
              hideSearchResultsLabel
              sourcesTitleLabel={
                <Text
                  size="small"
                  tagName="div"
                  color="standard"
                  isBold
                  className={styles.SourcesFromSettingsOneSourcesLabel}
                >
                  {i18n.t('compose_settings_reference_label')}
                </Text>
              }
              searchTitleLabel={
                <Text
                  size="small"
                  tagName="div"
                  color="standard"
                  isBold
                  className={styles.SourcesFromSettingsOneSourcesInputLabel}
                >
                  {i18n.t('compose_settings_reference_description')}
                </Text>
              }
            />
          </div>
        </div>
        {renderFooter?.({ sourceCount: sources.length })}
      </>
    );
  },
);
SourcesFromSettingsOne.displayName = 'SourcesFromSettingsOne';
