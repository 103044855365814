import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';

import type { dash } from '@dropbox/api-v2-client';

const logger = tagged('service-curations');

type CurationResponse = {
  success: boolean;
  curation?: dash.Curation;
};

/*
 * Conceptually, curations should be it's own service. However, the current logic for
 * curations does not extend beyond simple passthrough CRUD methods. Set up the
 * necessary boilerplating when the curations use case expands
 */

export async function createCuration(
  entityId: string,
  type: dash.CurationType,
  queries: Array<string> = [],
): Promise<CurationResponse> {
  try {
    const result = await callApiV2('dcsCreateCuration', {
      entity_uuid: entityId,
      curation_type: type,
      exact_queries: queries,
    });
    return { success: true, curation: result.curation };
  } catch (e) {
    logger.error('Failed to create curation', e);
    return { success: false };
  }
}

export async function getCuration(
  entityId: string,
  type: dash.CurationType,
): Promise<CurationResponse> {
  try {
    const result = await callApiV2('dcsGetCuration', {
      entity_uuid: entityId,
      curation_type: type,
    });
    return { success: true, curation: result.curation };
  } catch (e) {
    logger.error('Failed to retrieve curation', e);
    return { success: false };
  }
}

export async function updateCuration(
  entityId: string,
  type: dash.CurationType,
  queries: Array<string> = [],
): Promise<CurationResponse> {
  try {
    // Essentially the same as `createCuration`. No need to overengineer
    // at this point however.
    const result = await callApiV2('dcsUpdateCuration', {
      entity_uuid: entityId,
      curation_type: type,
      exact_queries: queries,
    });
    return { success: true, curation: result.curation };
  } catch (e) {
    logger.error('Failed to update curation', e);
    return { success: false };
  }
}

export async function deleteCuration(
  entityId: string,
  type: dash.CurationType,
): Promise<CurationResponse> {
  try {
    await callApiV2('dcsDeleteCuration', {
      entity_uuid: entityId,
      curation_type: type,
    });
    return { success: true };
  } catch (e) {
    logger.error('Failed to delete curation', e);
    return { success: false };
  }
}
