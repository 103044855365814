import { IconButton } from '@dropbox/dig-components/buttons';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronLeftLine } from '@dropbox/dig-icons/assets';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { ChangeEvent, memo } from 'react';
import { RenderFooterSettings } from './SourcesFromSettingsModalStepper';
import styles from './SourcesFromSettingsThree.module.css';

interface SourcesFromSettingsThreeProps {
  title: string;
  onNameInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDescriptionInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  nameValue: string;
  descriptionValue: string;
  namePlaceholder: string;
  descriptionPlaceholder: string;
  handleBack?: () => void; // handleBack is derived from SourcesFromSettingsModalStepper
  renderFooter?: (footerSettings: RenderFooterSettings) => JSX.Element; // renderFooter is derived from SourcesFromSettingsModalStepper
  deleteModal: JSX.Element;
}
export const SourcesFromSettingsThree = memo(
  ({
    title,
    nameValue,
    descriptionValue,
    onNameInputChange,
    onDescriptionInputChange,
    handleBack,
    renderFooter,
    namePlaceholder,
    descriptionPlaceholder,
    deleteModal,
  }: SourcesFromSettingsThreeProps) => {
    const isMobile = useIsMobileSize();
    return (
      <>
        <div
          className={classnames(
            styles.SourcesFromSettingsThreeContent,
            isMobile && styles.SourcesFromSettingsThreeMobile,
          )}
        >
          <div className={styles.SourcesFromSettingsThreeHeader}>
            <IconButton
              variant="borderless"
              shape="standard"
              size="medium"
              tone="neutral"
              onClick={handleBack}
              aria-label="Back"
            >
              <UIIcon src={ChevronLeftLine} />
            </IconButton>
            <Title
              size="medium"
              className={styles.SourcesFromSettingsThreeTitle}
              weightVariant="emphasized"
            >
              {title}
            </Title>
          </div>
          <Text
            size="small"
            tagName="div"
            color="standard"
            isBold
            className={styles.SourcesFromSettingsThreeInputLabel}
          >
            {i18n.t('compose_settings_from_sources_name_input_label')}
          </Text>
          <TextInput
            value={nameValue}
            placeholder={namePlaceholder}
            size="medium"
            onChange={onNameInputChange}
          />
          <Text
            size="small"
            tagName="div"
            color="standard"
            isBold
            className={styles.SourcesFromSettingsThreeInputLabel}
          >
            {i18n.t('compose_settings_from_sources_description_input_label')}
          </Text>
          <TextInput
            value={descriptionValue}
            placeholder={descriptionPlaceholder}
            size="medium"
            onChange={onDescriptionInputChange}
          />
        </div>
        {renderFooter?.({ secondaryCta: 'delete' })}
        {deleteModal}
      </>
    );
  },
);
SourcesFromSettingsThree.displayName = 'SourcesFromSettingsThree';
