import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_SaveSources } from '@mirage/analytics/events/types/click_save_sources';
import { ComposeSources } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/ComposeSources';
import { SourcesContentCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { DashThemeProvider } from '@mirage/service-settings/theming/DashThemeProvider';
import { ComposeSource } from '@mirage/shared/compose/compose-session';
import KeyCap from '@mirage/shared/keyboard-shortcuts/KeyCap';
import i18n from '@mirage/translations';
import { memo, useEffect, useRef } from 'react';
import styles from './AddSourcesModal.module.css';

interface AddSourcesModalProps {
  sources: ComposeSource[];
  sourcesContentCache: SourcesContentCache;
  addSource: (source: ComposeSource) => void;
  removeSource: (source: ComposeSource) => void;
  onRequestClose: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const AddSourcesModal = memo(
  ({
    sources,
    sourcesContentCache,
    addSource,
    removeSource,
    onRequestClose,
    logComposeEvent,
  }: AddSourcesModalProps) => {
    // Add fallback listeners to re-take the focus for when a user Adds or Removes a source
    // which takes the focus away and disables the Esc key and outside click to close the modal.
    const modalContentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onRequestClose();
        }
      };
      const handleClickOutside = (event: MouseEvent) => {
        if (
          modalContentRef.current &&
          !modalContentRef.current.contains(event.target as Node)
        ) {
          onRequestClose();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onRequestClose]);
    return (
      <Modal open width={694} isCentered onRequestClose={onRequestClose}>
        <DashThemeProvider>
          <Modal.Body
            ref={modalContentRef}
            tabIndex={-1}
            className={styles.ModalBody}
          >
            <div className={styles.ModalBodyContent}>
              <ComposeSources
                sources={sources}
                sourcesContentCache={sourcesContentCache}
                onAddSource={addSource}
                onRemoveSource={removeSource}
                logComposeEvent={logComposeEvent}
                offsetEmptyMargin
              />
            </div>
            <Modal.Footer className={styles.ModalFooter}>
              <div className={styles.sourceEscapeLabel}>
                <KeyCap casing="capitalize">Esc</KeyCap>
                <Text
                  monospace
                  className={styles.keyCapLabel}
                  color="subtle"
                  size="small"
                >
                  {i18n.t('close')}
                </Text>
              </div>
              <div>
                <Text
                  size="small"
                  color="subtle"
                  monospace
                  className={styles.sourceCountLabel}
                >
                  {i18n.t('compose_add_sources_count', {
                    count: sources.length,
                    plural: sources.length === 1 ? '' : 's',
                  })}
                </Text>
                <Button
                  size="medium"
                  variant="primary"
                  disabled={sources.length === 0}
                  onClick={() => {
                    logComposeEvent(
                      PAP_Click_SaveSources({
                        numberOfSelectedItems: sources.length,
                      }),
                      { actionSurfaceComponent: 'compose_source_modal' },
                    );
                    onRequestClose();
                  }}
                  className={styles.submitButton}
                >
                  {i18n.t('save')}
                </Button>
              </div>
            </Modal.Footer>
          </Modal.Body>
        </DashThemeProvider>
      </Modal>
    );
  },
);
AddSourcesModal.displayName = 'AddSourcesModal';
