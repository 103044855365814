import * as ConnectorIcons from '@mirage/shared/icons/connector-icons';
import i18n from '@mirage/translations';

import type { Connector } from '@mirage/service-dbx-api';
import type { ActivityResult } from '@mirage/shared/feed/activity-result';
import type { IconProps } from '@mirage/shared/icons/connector-icons';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type {
  ConnectorInfo,
  SearchResult,
} from '@mirage/shared/search/search-result';

// These should stay in-tune with rServer
// go/src/dropbox/dash/connectors/paragon/connector_ids/connector_ids.go
export const connectorsNames = [
  'airtable',
  'angellist',
  'asana',
  'atrium',
  'basecamp',
  'box',
  'clickup',
  'confluence_cloud',
  'crunchbase',
  'custom.coda',
  'custom.figma',
  'custom.front',
  'custom.lucidchart',
  'custom.microsoftonenote',
  'custom.microsoftteams',
  'custom.ninetyNinePlus',
  'custom.teams',
  'custom.quip',
  'custom.shortcut',
  'custom.smartsheet',
  'custom.todoist',
  'custom.workday',
  'dropbox',
  'evernote',
  'github',
  'gmail',
  'googleCalendar',
  'googledrive',
  'greenhouse',
  'guru',
  'hellosign',
  'hubspot',
  'intercom',
  'jira',
  'lever',
  'linkedin',
  'mayven',
  'mixpanel',
  'notion',
  'onedrive',
  'onenote',
  'outlook',
  'outreach',
  'salesforce',
  'salesloft',
  'sentry',
  'segment',
  'sharepoint',
  'slack',
  'superhuman',
  'trello',
  'twitter',
  'zendesk',
  'zoom',
  'dash_education',
  'custom_ingest',
  'UNKNOWN_CONNECTOR_ID',
  'UNKNOWN_PARAGON_INTEGRATION',
] as const;
export type ConnectorName = (typeof connectorsNames)[number];

// Used by the UI to display connector UI based on connectorInfo.connectorName
// This should eventually be replaced by API data
export type ConnectorDisplayConfig = {
  connectorName: ConnectorName;
  displayName: string;
  icon: React.JSXElementConstructor<IconProps>;
  webSearchBaseUrl?: string;
};

const connectorDisplayConfigs: Record<ConnectorName, ConnectorDisplayConfig> = {
  airtable: {
    connectorName: 'airtable',
    displayName: 'Airtable',
    icon: ConnectorIcons.Airtable,
  },
  angellist: {
    connectorName: 'angellist',
    displayName: 'Angel List',
    icon: ConnectorIcons.Angellist,
  },
  asana: {
    connectorName: 'asana',
    displayName: 'Asana',
    icon: ConnectorIcons.Asana,
    webSearchBaseUrl: `https://app.asana.com/0/search?q=`,
  },
  atrium: {
    connectorName: 'atrium',
    displayName: 'Atrium',
    icon: ConnectorIcons.AtriumHq,
  },
  basecamp: {
    connectorName: 'basecamp',
    displayName: 'Basecamp',
    icon: ConnectorIcons.Basecamp,
  },
  box: {
    connectorName: 'box',
    displayName: 'Box',
    icon: ConnectorIcons.Box,
    webSearchBaseUrl: `https://app.box.com/folder/0/search?isTrashSearch=0&metadata=&owners=&query=`,
  },
  clickup: {
    connectorName: 'clickup',
    displayName: 'ClickUp',
    icon: ConnectorIcons.ClickUp,
  },
  confluence_cloud: {
    connectorName: 'confluence_cloud',
    displayName: 'Confluence',
    icon: ConnectorIcons.Confluence,
  },
  crunchbase: {
    connectorName: 'crunchbase',
    displayName: 'Crunchbase',
    icon: ConnectorIcons.Crunchbase,
  },
  'custom.coda': {
    connectorName: 'custom.coda',
    displayName: 'Coda',
    icon: ConnectorIcons.Coda,
  },
  'custom.figma': {
    connectorName: 'custom.figma',
    displayName: 'Figma',
    icon: ConnectorIcons.Figma,
  },
  'custom.front': {
    connectorName: 'custom.front',
    displayName: 'Front',
    icon: ConnectorIcons.Front,
  },
  'custom.lucidchart': {
    connectorName: 'custom.lucidchart',
    displayName: 'Lucid',
    icon: ConnectorIcons.Lucid,
  },
  'custom.microsoftonenote': {
    connectorName: 'custom.microsoftonenote',
    displayName: 'OneNote',
    icon: ConnectorIcons.MsOneNote,
  },
  'custom.microsoftteams': {
    connectorName: 'custom.microsoftteams',
    displayName: 'Teams',
    icon: ConnectorIcons.MsTeams,
  },
  'custom.ninetyNinePlus': {
    connectorName: 'custom.ninetyNinePlus',
    displayName: '99+',
    icon: ConnectorIcons.NinetyNinePlus,
  },
  'custom.teams': {
    connectorName: 'custom.teams',
    displayName: 'Teams',
    icon: ConnectorIcons.MsTeams,
  },
  'custom.quip': {
    connectorName: 'custom.quip',
    displayName: 'Quip',
    icon: ConnectorIcons.Quip,
  },
  'custom.shortcut': {
    connectorName: 'custom.shortcut',
    displayName: 'Shortcut',
    icon: ConnectorIcons.Shortcut,
  },
  'custom.smartsheet': {
    connectorName: 'custom.smartsheet',
    displayName: 'Smartsheet',
    icon: ConnectorIcons.Smartsheet,
  },
  'custom.todoist': {
    connectorName: 'custom.todoist',
    displayName: 'Todoist',
    icon: ConnectorIcons.Todoist,
  },
  'custom.workday': {
    connectorName: 'custom.workday',
    displayName: 'Workday',
    icon: ConnectorIcons.Workday,
  },
  dropbox: {
    connectorName: 'dropbox',
    displayName: 'Dropbox',
    icon: ConnectorIcons.Dropbox,
    webSearchBaseUrl: `https://www.dropbox.com/search?query=`,
  },
  evernote: {
    connectorName: 'evernote',
    displayName: 'Evernote',
    icon: ConnectorIcons.Evernote,
  },
  github: {
    connectorName: 'github',
    displayName: 'GitHub',
    icon: ConnectorIcons.Github,
    webSearchBaseUrl: `https://github.com/search?q=`,
  },
  gmail: {
    connectorName: 'gmail',
    displayName: 'Gmail',
    icon: ConnectorIcons.GoogleMail,
    webSearchBaseUrl: `https://mail.google.com/mail?#search/`,
  },
  googleCalendar: {
    connectorName: 'googleCalendar',
    displayName: 'Google Calendar',
    icon: ConnectorIcons.GoogleCalendar,
    webSearchBaseUrl: `https://calendar.google.com/calendar/u/0/r/search?q=`,
  },
  googledrive: {
    connectorName: 'googledrive',
    displayName: 'Google Drive',
    icon: ConnectorIcons.GoogleDrive,
    webSearchBaseUrl: `https://drive.google.com/drive/search?q=`,
  },
  greenhouse: {
    connectorName: 'greenhouse',
    displayName: 'Greenhouse',
    icon: ConnectorIcons.Greenhouse,
  },
  guru: {
    connectorName: 'guru',
    displayName: 'Guru',
    icon: ConnectorIcons.GetGuru,
  },
  hellosign: {
    connectorName: 'hellosign',
    displayName: 'HelloSign',
    icon: ConnectorIcons.HelloSign,
  },
  hubspot: {
    connectorName: 'hubspot',
    displayName: 'HubSpot',
    icon: ConnectorIcons.Hubspot,
  },
  intercom: {
    connectorName: 'intercom',
    displayName: 'Intercom',
    icon: ConnectorIcons.Intercom,
    webSearchBaseUrl: `https://app.intercom.com/a/inbox/calluts3/search?q=`,
  },
  jira: {
    connectorName: 'jira',
    displayName: 'Jira',
    icon: ConnectorIcons.Jira,
  },
  lever: {
    connectorName: 'lever',
    displayName: 'Lever',
    icon: ConnectorIcons.Lever,
  },
  linkedin: {
    connectorName: 'linkedin',
    displayName: 'LinkedIn',
    icon: ConnectorIcons.Linkedin,
  },
  mayven: {
    connectorName: 'mayven',
    displayName: 'Mayven',
    icon: ConnectorIcons.Mayven,
  },
  mixpanel: {
    connectorName: 'mixpanel',
    displayName: 'Mixpanel',
    icon: ConnectorIcons.Mixpanel,
  },
  notion: {
    connectorName: 'notion',
    displayName: 'Notion',
    icon: ConnectorIcons.Notion,
  },
  onedrive: {
    connectorName: 'onedrive',
    displayName: 'OneDrive',
    icon: ConnectorIcons.MsOneDrive,
    webSearchBaseUrl: `https://onedrive.live.com/?id=root&qt=search&scope=drive&q=`,
  },
  onenote: {
    connectorName: 'onenote',
    displayName: 'OneNote',
    icon: ConnectorIcons.MsOneNote,
  },
  outlook: {
    connectorName: 'outlook',
    displayName: 'Outlook',
    icon: ConnectorIcons.MsOutlook,
  },
  outreach: {
    connectorName: 'outreach',
    displayName: 'Outreach',
    icon: ConnectorIcons.Outreach,
  },
  salesforce: {
    connectorName: 'salesforce',
    displayName: 'Salesforce',
    icon: ConnectorIcons.Salesforce,
  },
  salesloft: {
    connectorName: 'salesloft',
    displayName: 'Salesloft',
    icon: ConnectorIcons.Salesloft,
  },
  sentry: {
    connectorName: 'sentry',
    displayName: 'Sentry',
    icon: ConnectorIcons.Sentry,
  },
  segment: {
    connectorName: 'segment',
    displayName: 'Segment',
    icon: ConnectorIcons.Segment,
  },
  sharepoint: {
    connectorName: 'sharepoint',
    displayName: 'Sharepoint',
    icon: ConnectorIcons.MsOneDrive,
  },
  slack: {
    connectorName: 'slack',
    displayName: 'Slack',
    icon: ConnectorIcons.Slack,
  },
  superhuman: {
    connectorName: 'superhuman',
    displayName: 'Superhuman',
    icon: ConnectorIcons.Superhuman,
  },
  trello: {
    connectorName: 'trello',
    displayName: 'Trello',
    icon: ConnectorIcons.Trello,
  },
  twitter: {
    connectorName: 'twitter',
    displayName: 'Twitter',
    icon: ConnectorIcons.Twitter,
  },
  zendesk: {
    connectorName: 'zendesk',
    displayName: 'Zendesk',
    icon: ConnectorIcons.Zendesk,
  },
  zoom: {
    connectorName: 'zoom',
    displayName: 'Zoom',
    icon: ConnectorIcons.Zoom,
  },
  dash_education: {
    connectorName: 'dash_education',
    displayName: 'Dash Education',
    icon: ConnectorIcons.DashIcon,
  },
  custom_ingest: {
    connectorName: 'custom_ingest',
    displayName: 'Custom Connector',
    icon: ConnectorIcons.DashIcon, // TODO Get a custom icon
  },
  UNKNOWN_PARAGON_INTEGRATION: {
    connectorName: 'UNKNOWN_PARAGON_INTEGRATION',
    displayName: 'Unknown',
    icon: ConnectorIcons.LinkIcon,
  },
  UNKNOWN_CONNECTOR_ID: {
    connectorName: 'UNKNOWN_CONNECTOR_ID',
    displayName: 'Unknown',
    icon: ConnectorIcons.LinkIcon,
  },
};

export function getConnectorDisplayConfig(connectorName: ConnectorName) {
  return (
    connectorDisplayConfigs[connectorName] ||
    connectorDisplayConfigs.UNKNOWN_CONNECTOR_ID
  );
}

function getConnectorDisplayNameFromConnectorInfo(
  connectorInfo: ConnectorInfo | undefined | null,
) {
  // If available, try to pull it first from the result
  if (connectorInfo?.displayName) {
    return connectorInfo.displayName;
  }

  const connectorName = connectorInfo?.connectorName as ConnectorName;
  const config = getConnectorDisplayConfig(connectorName);
  if (config.displayName === 'UNKNOWN_PROVIDER_ID') {
    return '';
  }
  return config.displayName;
}

export function getConnectorDisplayNameFromResult(
  result: ActivityResult | SearchResult | Recommendation,
) {
  if (
    result.recordType?.['.tag'] === 'browser_tab' &&
    result.brandedSiteInfo?.name
  ) {
    return i18n.t('browser_history_branded_connector_name', {
      brandedSiteName: result.brandedSiteInfo?.name,
    });
  } else {
    return getConnectorDisplayNameFromConnectorInfo(result.connectorInfo);
  }
}

export const DESKTOP_LOCAL_FILE_CONNECTOR_ID = 'dash_desktop_local_file';

export const LOCAL_FILE_CONNECTOR: Connector = {
  id_attrs: {
    platform: { '.tag': 'other' },
    id: DESKTOP_LOCAL_FILE_CONNECTOR_ID,
    type: 'dash_desktop_local_file',
  },
  branding: {
    display_name: i18n.t('local_file_filter_display_name'),
    icon_src: 'desktop_local_file', // not used but prevents from getting removed from filters
  },
  details: {
    supports_upstream: false,
  },
};

export const LOCAL_FILE_CONNECTOR_INFO: ConnectorInfo = {
  connectorId: DESKTOP_LOCAL_FILE_CONNECTOR_ID,
  connectorType: { '.tag': 'other' },
  connectorName: DESKTOP_LOCAL_FILE_CONNECTOR_ID,
  displayName: 'Local file',
  connectorIconUrl: '',
  platform: { '.tag': 'other' },
};

export const RECENT_FILES_CONNECTOR: Connector = {
  id_attrs: {
    platform: { '.tag': 'other' },
    id: 'dash_desktop_recent_file',
  },
  branding: {
    display_name: 'Recent File',
  },
};

export const RECENT_FILES_CONNECTOR_INFO: ConnectorInfo = {
  connectorId: 'dash_desktop_recent_files',
  connectorType: { '.tag': 'other' },
  connectorName: 'dash_desktop_recent_files',
  displayName: 'Recent file',
  connectorIconUrl: '',
  platform: { '.tag': 'other' },
};

export const APPLICATIONS_CONNECTOR: Connector = {
  id_attrs: {
    platform: { '.tag': 'other' },
    id: 'dash_desktop_applications',
  },
  branding: {
    display_name: 'Application',
  },
};

export const APPLICATIONS_CONNECTOR_INFO: ConnectorInfo = {
  connectorId: 'dash_desktop_applications',
  connectorType: { '.tag': 'other' },
  connectorName: 'dash_desktop_applications',
  displayName: 'Application',
  connectorIconUrl: '',
  platform: { '.tag': 'other' },
};
// Converts connector display names from "*Microsoft Sharepoint" format to "microsoft-sharepoint"
export function getConnectorNameForAttr(connectorName: string) {
  const attrFriendlyName = connectorName
    .replace(/ /g, '-')
    .replace(/\*/g, '')
    .replace('custom.', '')
    .toLowerCase();
  return attrFriendlyName;
}
