import { context_engine } from '@dropbox/api-v2-client/types/dropbox_types';
import { ComposeAssistantConversationMessage } from '@mirage/shared/compose/compose-session';

export function messageToPromptMessage(
  message: ComposeAssistantConversationMessage,
): context_engine.ChatMessage | undefined {
  switch (message.type) {
    case 'instruction':
      return {
        '.tag': 'system_message',
        content: {
          text:
            message.title + (message.subtitle ? '\n' + message.subtitle : ''),
        },
      };
    case 'message': {
      if (message.ignoreMessageForPrompt) {
        return undefined;
      }
      return {
        '.tag': getPromptMessageTagForRole(message.role),
        content: {
          text: message.rawPromptText || message.text,
        },
      };
    }
    default:
      message satisfies never;
      throw new Error(`Unknown message type: ${message}`);
  }
}

function getPromptMessageTagForRole(role: 'user' | 'assistant' | 'system') {
  switch (role) {
    case 'user':
      return 'user_message';
    case 'assistant':
      return 'assistant_message';
    case 'system':
      return 'system_message';
    default:
      role satisfies never;
      throw new Error(`Unknown role: ${role}`);
  }
}

export function getSelectionActionPromptString(
  actionType: 'rewrite' | 'make-longer' | 'make-shorter',
) {
  switch (actionType) {
    case 'make-longer':
      return 'Update the selected text in the draft below by making it longer';
    case 'make-shorter':
      return 'Update the selected text in the draft below by making it shorter';
    case 'rewrite':
      return 'Update the selected text in the draft below by rewriting it to improve its writing';
    default:
      actionType satisfies never;
      throw new Error(`Unknown selection action type: ${actionType}`);
  }
}

export function getSelectionPromptString(
  requestPrompt: string,
  selectedText: string,
) {
  return `${requestPrompt}

# START OF SELECTED TEXT
${selectedText}
# END OF SELECTED TEXT`;
}
