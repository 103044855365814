import { tagged } from '@mirage/service-logging';
import { getAll as getSettings } from '@mirage/service-settings';
import windowOrEnv from '@mirage/shared/util/multiSurfacePwEnv';
import i18next, { t } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import { initReactI18next, Trans as I18NextTrans } from 'react-i18next';
import en from './langs/en-US';

import type {
  GetValidI18nKeys,
  TFunction,
  TransComponentType,
  TransObjShape,
} from '@mirage/translations/types';

const logger = tagged('translations');

export type I18nKey = GetValidI18nKeys<typeof en>;

function convertTranslations(translationObject: TransObjShape) {
  return Object.fromEntries(
    Object.entries(translationObject).map(([key, value]) => [
      key as I18nKey,
      value.message,
    ]),
  );
}

function debugMissingStrings(translationObject: TransObjShape) {
  return Object.fromEntries(
    Object.entries(translationObject).map(([key, value]) => [
      (key.endsWith('_other') ? key.slice(0, key.length - 6) : key) as I18nKey,
      `✅[${value.message}]`,
    ]),
  );
}

export const i18nDebugLocale = 'debugMissingStrings';

const translation = convertTranslations(en);

const resources = {
  en: { translation },
  enGB: { translation },
  frCA: { translation },
  ja: { translation },
  [i18nDebugLocale]: { translation: debugMissingStrings(en) },
};

export type SupportedLocale = keyof typeof resources;

export const isSupportedLocale = (locale: string): locale is SupportedLocale =>
  locale in resources;

export const supportedLocaleConfig: Record<SupportedLocale, string> = {
  en: 'English (United States)',
  enGB: 'English (United Kingdom)',
  frCA: 'Français (Canada)',
  ja: '日本語',
  [i18nDebugLocale]: '[DEV] Debug i18n',
};

// eslint-disable-next-line import/no-named-as-default-member
i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    prefix:
      windowOrEnv.PLAYWRIGHT_DISABLE_INTERPOLATION === 'true'
        ? '[[[['
        : undefined,
    suffix:
      windowOrEnv.PLAYWRIGHT_DISABLE_INTERPOLATION === 'true'
        ? ']]]]'
        : undefined,
    escapeValue: false,
  },
  returnEmptyString: false,
  returnNull: false,
  keySeparator: false,
  resources,
});

const i18n = { ...i18next, t: t as TFunction<typeof en>, unchecked: t };

getSettings()
  .then(({ locale }) => i18n.changeLanguage(locale))
  .catch((e) => logger.error('Failed to load locale from settings', e));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ensureAllLocalesAreLoaded: Record<
  SupportedLocale,
  Promise<typeof i18next | void>
> = {
  // Bundled with the app
  en: Promise.resolve(),
  debugMissingStrings: Promise.resolve(),

  // Lazy-loaded
  enGB: import('./langs/en-GB.json')
    .then(({ default: enGB }) =>
      i18n.addResourceBundle('enGB', 'translation', convertTranslations(enGB)),
    )
    .catch((e) => logger.error('Failed to load en-GB.json', e)),
  frCA: import('./langs/fr-CA.json')
    .then(({ default: frCA }) =>
      i18n.addResourceBundle('frCA', 'translation', convertTranslations(frCA)),
    )
    .catch((e) => logger.error('Failed to load fr-CA.json', e)),
  ja: import('./langs/ja-JP.json')
    .then(({ default: ja }) =>
      i18n.addResourceBundle('ja', 'translation', convertTranslations(ja)),
    )
    .catch((e) => logger.error('Failed to load ja-JP.json', e)),
};

export default i18n;

export const Trans = I18NextTrans as TransComponentType<typeof en>;
