import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  BugLine,
  CloseLine,
  CopyLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useAccountIsDropboxer } from '@mirage/service-auth/useDropboxAccount';
import { tagged } from '@mirage/service-logging';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { set as setSetting } from '@mirage/service-settings';
import useSettings from '@mirage/service-settings/useSettings';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { showSnackbar } from '@mirage/shared/snackbar';
import { Fragment, useEffect, useMemo, useState } from 'react';
import styles from './DebugCard.module.css';

import type { ScoredResult } from '@mirage/service-search/service';

const logger = tagged('combined-result-debugger');

// TODO wish list items:
// ConnectionID - this doesn't exist yet on search obj
// Is result pinned due to being higher than the relevance score threshold?
// Number of upstream calls made
// All the config values used (threshold, pinned results, etc)
// Highest ranking upstreStandardize result
// Total number of results
// What batch the result was rendered in. For example the first batch is pinned server results, 2nd batch is quick upstream, 3rd batch is slow upstream, etc

/**
 * Should contain all of the information we want to know for any debug results.
 *
 * Because they come from various sources, they all don't have the same
 * information provided for each result, so this pulls out the common
 * info we want to display.
 */
type DebugResultCommon = {
  // Core
  uuid: string;
  title: string;
  source: string;
};
type DebugResultSerp = DebugResultCommon & {
  // Narrowed
  type: 'serp';
  originalResult: ScoredResult;
  // Additional
  serverScore: string | number;
  clientScore: string | number;
  connector: string;
  id3p: string;
  sourceIndexType: string;
};
type DebugResultTypeahead = DebugResultCommon & {
  // Narrowed
  type: 'typeahead';
  originalResult: typeahead.ScoredResult;
  // Additional
  typeaheadScore: number;
  typeaheadScoringNotes: typeahead.ScoringNotes;
};
type DebugResult = DebugResultTypeahead | DebugResultSerp;

// Types for rendering the debug rows in the UI
type DebugRow = {
  title: string;
  value: string;
  onCopy?: () => void;
  addBorder?: boolean;
  headerAlignTop?: boolean;
};

// this component should be passed either the searchResults OR the typeaheadResults, but not both.
export type Props =
  | {
      searchResults: ScoredResult[];
      typeaheadResults?: undefined;
    }
  | {
      searchResults?: undefined;
      typeaheadResults: typeahead.ScoredResult[];
    };

/**
 * A debug overlay that shows information about the search results you hover over.
 *
 * It checks if it should show or not internally, so you can just drop it in
 * your component tree with the correct results prop and it'll handle the rest.
 */
export const DebugCard = ({ searchResults, typeaheadResults }: Props) => {
  const { settings } = useSettings(['appDebug']);
  const { isDropboxer } = useAccountIsDropboxer();

  const isDebuggerEnabled = !!settings?.appDebug && !!isDropboxer;

  const results = useMemo(() => {
    if (typeaheadResults) {
      return typeaheadResults.map<DebugResult>((result) => {
        const common = {
          // DebugResultTypeahead
          type: 'typeahead',
          typeaheadScore: result.score,
          typeaheadScoringNotes: result.scoringNotes,
          // DebugResultCommon
          uuid: result.uuid,
          source: result.metadata?.source ?? 'Unknown',
          originalResult: result,
        };

        switch (result.type) {
          case typeahead.ResultType.DesktopFile:
          case typeahead.ResultType.DesktopApplication:
          case typeahead.ResultType.SearchResult:
          case typeahead.ResultType.Recommendation: {
            return {
              ...common,
              title: result.result.title,
            } as DebugResult;
          }
          case typeahead.ResultType.PreviousQuery:
          case typeahead.ResultType.SuggestedQuery: {
            return {
              ...common,
              title: result.result.query,
            } as DebugResult;
          }
          case typeahead.ResultType.Stack: {
            return {
              ...common,
              title: result.result.stack_data?.name ?? 'Unknown',
            } as DebugResult;
          }
          case typeahead.ResultType.StackItem: {
            return {
              ...common,
              title: result.result.name,
            } as DebugResult;
          }
          case typeahead.ResultType.URLShortcut: {
            return {
              ...common,
              title: result.result.parameters.title.template,
            } as DebugResult;
          }
          case typeahead.ResultType.MathCalculation: {
            return {
              ...common,
              title: result.result.answer,
            } as DebugResult;
          }
          case typeahead.ResultType.SearchFilter: {
            return {
              ...common,
              title: result.result.id,
            } as DebugResult;
          }
          default:
            result satisfies never;
            logger.warn('Unrecognized typeahead result type');
            return undefined as never;
        }
      });
    }

    if (searchResults) {
      return searchResults.map<DebugResult>((result) => ({
        type: 'serp',
        uuid: result.uuid,
        title: result.title,
        serverScore:
          result.searchResultSource === 'upstream'
            ? 'N/A - upstream result'
            : result.relevanceScore,
        clientScore: result.score,
        source: result.searchResultSource ?? 'Unknown',
        connector: result.connectorInfo.displayName,
        id3p: result.id3p || '-',
        originalResult: result,
        sourceIndexType: result.sourceIndexType?.['.tag'] || '-',
      }));
    }

    return [];
  }, [searchResults, typeaheadResults]);

  const [result, setResult] = useState<DebugResult | null>(null);

  useEffect(() => {
    const listenForHover = (event: MouseEvent) => {
      // Start with the event target
      let targetElement = event.target as HTMLElement | null;

      // Traverse up the DOM tree to find the closest ancestor with the data attribute
      while (
        targetElement !== null &&
        !targetElement.hasAttribute('data-x-uuid')
      ) {
        targetElement = targetElement.parentElement;
      }

      // If an element with the data attribute was found, grab the UUID
      if (targetElement !== null && targetElement.hasAttribute('data-x-uuid')) {
        const uuid = targetElement.getAttribute('data-x-uuid');

        // then find the result with that UUID and setting it in state. we want the null to store here if it's not found so the debugger hides
        setResult(results.find((r) => r.uuid === uuid) ?? null);
      }
    };

    if (isDebuggerEnabled) {
      document.addEventListener('mouseover', listenForHover);
    }
    return () => {
      document.removeEventListener('mouseover', listenForHover);
    };
  }, [isDebuggerEnabled, results]);

  if (!isDebuggerEnabled || !result) {
    return null;
  } else {
    const rowsCommon: DebugRow[] = [
      // DebugResultCommon
      {
        title: 'UUID',
        value: result.uuid,
        onCopy: () => {
          copyToClipboard(result.uuid);
        },
      },
      {
        title: 'Title',
        value: result.title,
        onCopy: () => {
          copyToClipboard(result.title);
        },
      },
      {
        title: 'Source',
        value: result.source,
        onCopy: () => {
          copyToClipboard(result.source);
        },
      },
      // Excluded: `type`, it's obvious in the UI whether you're looking at the
      // debug rows in typeahead or SERP
      // Excluded: `originalResult`, we don't seem to need anything from it
    ];

    const rowsSerp: DebugRow[] = [];
    if (result.type === 'serp') {
      const serverScoreString =
        typeof result.serverScore === 'number'
          ? result.serverScore.toString()
          : result.serverScore;
      rowsSerp.push({
        title: 'Server Score',
        value: serverScoreString,
        onCopy: () => {
          copyToClipboard(serverScoreString);
        },
      });

      const clientScoreString = result.clientScore.toString();
      rowsSerp.push({
        title: 'Client Score',
        value: clientScoreString,
        onCopy: () => {
          copyToClipboard(clientScoreString);
        },
      });

      rowsSerp.push({
        title: 'Connector',
        value: result.connector,
        onCopy: () => {
          copyToClipboard(result.connector);
        },
      });

      rowsSerp.push({
        title: 'ID3P',
        value: result.id3p,
        onCopy: () => {
          copyToClipboard(result.id3p);
        },
      });

      rowsSerp.push({
        title: 'Index source',
        value: result.sourceIndexType,
        onCopy: () => {
          copyToClipboard(result.sourceIndexType);
        },
      });
    }

    const rowsTypeahead: DebugRow[] = [];
    if (result.type === 'typeahead') {
      const typeaheadScoreString = result.typeaheadScore.toFixed(3);
      rowsTypeahead.push({
        type: 'normal',
        title: 'Score',
        value: typeaheadScoreString,
        onCopy: () => {
          copyToClipboard(typeaheadScoreString);
        },
      } as DebugRow);

      rowsTypeahead.push({
        title: 'Scoring Notes',
        value: 'Copy result for full scoring notes',
        onCopy: () => {
          copyToClipboard(JSON.stringify(result, undefined, 2));
        },
      });

      // These are part of scoring notes, but more readable when debugging if
      // broken out to separate rows

      const pinnedString = result.typeaheadScoringNotes.pinned.toString();
      rowsTypeahead.push({
        title: 'Pinned',
        value: pinnedString,
        onCopy: () => {
          copyToClipboard(pinnedString);
        },
      });

      const weightedScoreStrings: string[] = [];
      const appliedWeightStrings: string[] = [];
      for (const key of typeahead.SCORING_COMPONENT_KEYS) {
        const scoringComponent =
          result.typeaheadScoringNotes.scoringComponents[key];
        const { weightedScore, appliedWeight } = scoringComponent;
        weightedScoreStrings.push(
          `${key}: ${
            weightedScore === null ? 'null' : weightedScore.toFixed(3)
          }`,
        );
        appliedWeightStrings.push(
          `${key}: ${
            appliedWeight === null ? 'null' : appliedWeight.toFixed()
          }`,
        );
      }

      const weightedScoreString = weightedScoreStrings.join(`, `);
      rowsTypeahead.push({
        title: 'Weighted Scores',
        value: weightedScoreString,
        onCopy: () => {
          copyToClipboard(weightedScoreString);
        },
        addBorder: true,
        headerAlignTop: true,
      });

      const appliedWeightString = appliedWeightStrings.join(`, `);
      rowsTypeahead.push({
        title: 'Applied Weights',
        value: appliedWeightString,
        onCopy: () => {
          copyToClipboard(appliedWeightString);
        },
        addBorder: true,
        headerAlignTop: true,
      });
    }

    const rows: DebugRow[] = [...rowsCommon, ...rowsSerp, ...rowsTypeahead];

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <Title className={styles.title} size="small">
            <UIIcon src={BugLine} />
            Debug
          </Title>
          <div className={styles.buttonGroup}>
            <Button
              variant="outline"
              size="small"
              onClick={() => {
                copyToClipboard(JSON.stringify(result, undefined, 2));
                showSnackbar({ title: 'Copied result to clipboard' });
              }}
            >
              Copy Result
            </Button>
            <Button
              variant="outline"
              size="small"
              onClick={() => {
                copyToClipboard(JSON.stringify(results, undefined, 2));
                showSnackbar({ title: 'Copied all results to clipboard' });
              }}
            >
              Copy All Results
            </Button>
            <IconButton
              variant="outline"
              size="small"
              onClick={() => {
                setSetting('appDebug', 0);
              }}
            >
              <UIIcon src={CloseLine} />
            </IconButton>
          </div>
        </div>
        <div className={styles.gridContainer}>
          {rows.map((debugRow) => {
            const { title, onCopy, value, addBorder, headerAlignTop } =
              debugRow;
            return (
              <Fragment key={`title_${title}`}>
                <div
                  className={
                    headerAlignTop
                      ? `${styles.gridItemHeader} ${styles.gridItemHeaderAlignTop}`
                      : styles.gridItemHeader
                  }
                >
                  <Text size={'small'} isBold>
                    {title}
                  </Text>
                </div>
                <div
                  key={`values_${title}`}
                  className={
                    addBorder
                      ? `${styles.gridItem} ${styles.gridItemWithBorder}`
                      : styles.gridItem
                  }
                >
                  <Text size={'small'} monospace>
                    {value}
                  </Text>
                </div>
                <div key={`copy_${title}`} className={styles.gridItem}>
                  <IconButton
                    variant="borderless"
                    size="small"
                    onClick={onCopy}
                  >
                    <UIIcon src={CopyLine} />
                  </IconButton>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  }
};
