import { Text } from '@dropbox/dig-components/typography';
import { FolderLine } from '@dropbox/dig-icons/assets';
import { SearchResult } from '@mirage/service-dbx-api';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import classNames from 'classnames';
import { getCalendarSubtitle } from '../util/resultUtil';
import { DefaultMetadata } from './DefaultMetadata';
import { MetadataItem } from './MetadataItem';
import { TruncatedFilePath } from './TruncatedFilePath';
import styles from './TypeaheadResultSubtitle.module.css';

import type { typeahead } from '@mirage/service-typeahead-search/service/types';

const renderSearchResultContent = (result: SearchResult) => {
  if (result.recordType['.tag'] === 'event') {
    return <MetadataItem title={getCalendarSubtitle(result)} />;
  }
  return <DefaultMetadata result={result} />;
};

type TypeaheadResultSubtitleProps = {
  result: typeahead.ScoredResult;
};

export const TypeaheadResultSubtitle = ({
  result,
}: TypeaheadResultSubtitleProps) => {
  const isMobileSize = useIsMobileSize();
  const isVirtualPathEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_11_13_search_results_virtual_path'),
  );

  const renderContent = () => {
    if (
      isVirtualPathEnabled &&
      (result.type === 'search-result' || result.type === 'recommendation') &&
      result.result.virtualPath &&
      result.result.virtualPath.length > 0
    ) {
      return (
        <MetadataItem
          title={
            <TruncatedFilePath
              isOverflowing={false}
              pathSegments={result.result.virtualPath}
            />
          }
          iconSrc={FolderLine}
        />
      );
    }
    switch (result.type) {
      case 'search-result':
        return renderSearchResultContent(result.result);
      case 'recommendation':
        return <DefaultMetadata result={result.result} />;
      default:
        return null;
    }
  };

  return (
    <Text size="small" color="faint">
      <div
        className={classNames(styles.container, {
          [styles.isMobile]: isMobileSize,
        })}
      >
        {renderContent()}
      </div>
    </Text>
  );
};
