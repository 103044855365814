import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConnectorsOnboarding } from './ConnectorsOnboarding';
import { ExtensionUpsell } from './ExtensionUpsell';
import { useFinishOnboarding } from './utils';

enum TeamOnboardingStep {
  EXTENSION = 'browser-extension',
}

export const TeamOnboarding = ({
  routeParams: { step } = {},
}: {
  routeParams?: Record<string, string | undefined>;
}) => {
  const navigate = useNavigate();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const fromDesktop = params.get('from') === 'desktop';
  // secret url param to use to test against the dev desktop app
  const useDevDesktopApp = params.get('useDevDesktopApp') === 'true';
  const finishOnboarding = useFinishOnboarding(reportPapEvent, 'team_v1', true);

  const handleStepFinish = useCallback(
    (step: string) => {
      switch (step) {
        case TeamOnboardingStep.EXTENSION:
          navigate(getNavigatePathForStep(TeamOnboardingStep.EXTENSION));
          break;
        case 'complete':
          finishOnboarding();
          // Loading desktop app url scheme will trigger native popup for user to confirm opening external app, but it doesn't
          // navigate user away from current page.
          // Because of that, doing this does not interfere with `navigate()` call that happens inside `finishOnboarding()`.
          if (fromDesktop) {
            if (useDevDesktopApp) {
              window.location.href =
                'dropbox-dash-canary-dev://?from=onboardingFinished';
            } else {
              window.location.href = 'dropbox-dash://?from=onboardingFinished';
            }
          }
          break;
      }
    },
    [finishOnboarding, fromDesktop, navigate, useDevDesktopApp],
  );

  const getNavigatePathForStep = (step: TeamOnboardingStep): string => {
    return RoutePath.SETUP_TEAMS.replace(':step', step);
  };

  switch (step) {
    case TeamOnboardingStep.EXTENSION:
      return <ExtensionUpsell onStepFinish={handleStepFinish} />;
    default:
      return <ConnectorsOnboarding onStepFinish={handleStepFinish} />;
  }
};
