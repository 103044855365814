import { Text } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import { DashCard } from '@mirage/dash-component-library/components/DashCard';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import * as React from 'react';
import styles from './SeeAllStackTileCard.module.css';

export const SeeAllStackCard = ({
  selectorClassname,
  numTotalStacks,
  hidden,
  explicitHeight,
  handleOnClick,
}: {
  selectorClassname: string;
  numTotalStacks: number;
  hidden: boolean;
  explicitHeight?: number;
  handleOnClick: () => void;
}) => {
  return (
    <DashCard
      className={classnames(styles.card, selectorClassname, {
        [styles.hidden]: hidden,
      })}
      breakout={'xsmall'}
      explicitHeight={explicitHeight}
    >
      <DashCard.OverlayLink onClick={handleOnClick} href={'#'}>
        <Stack>
          <Box>
            <Text isBold variant="label" size="medium">
              {i18n.t('see_all')}
            </Text>
          </Box>
          <Box>
            <Text variant="paragraph" size="medium">
              {i18n.t('count_stacks', { count: numTotalStacks })}
            </Text>
          </Box>
        </Stack>
      </DashCard.OverlayLink>
    </DashCard>
  );
};
