import {
  ReportPapEventFn,
  useMirageAnalyticsContext,
} from '@mirage/analytics/AnalyticsProvider';
import {
  Add_DashConnectedAccount,
  PAP_Add_DashConnectedAccount,
} from '@mirage/analytics/events/types/add_dash_connected_account';
import { listConnections } from '@mirage/service-connectors';
import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { loadAndMonitorPopup, openPopup } from '@mirage/settings/utils/oauth';
import {
  useConnections,
  useSetConnections,
} from '@mirage/shared/atoms/connections';
import {
  useSetConnector,
  useSetConnectors,
} from '@mirage/shared/atoms/connectors';
import { FetchedAtom } from '@mirage/shared/atoms/types';
import {
  DashConnectedAccountError,
  dashConnectedAccountError,
  GroupConnector,
  UIConnection,
  UIConnector,
} from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { SetStateAction } from 'react';
import {
  connectionEnabled,
  fetchConnectorsWithVisibilityFilters,
} from '../../utils/connectorMetadataService';
import { filterBrowserExtensionConnections } from './useConnectConnector';

type Options = {
  eventProps?: Add_DashConnectedAccount['properties'];
};

export const useConnectGroupConnector = (options: Options = {}) => {
  const connections = useConnections();
  const setConnections = useSetConnections();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const setConnector = useSetConnector();
  const setConnectors = useSetConnectors();

  return (groupConnector: GroupConnector) => {
    return connectGroupConnector(
      groupConnector,
      connections,
      setConnections,
      reportPapEvent,
      setConnector,
      setConnectors,
      options,
    );
  };
};

const logger = tagged('connectGroupConnector');

export async function connectGroupConnector(
  groupConnector: GroupConnector,
  connections: FetchedAtom<UIConnection[]>,
  setConnections: React.Dispatch<SetStateAction<FetchedAtom<UIConnection[]>>>,
  reportPapEvent: ReportPapEventFn,
  setConnector: (type: string, connector: UIConnector) => void,
  setConnectors: React.Dispatch<SetStateAction<FetchedAtom<UIConnector[]>>>,
  options: Options = {},
) {
  if (!groupConnector.connectors || groupConnector.connectors.length === 0)
    throw new Error('connectors missing');
  if (!connections.loaded) throw new Error('connections not loaded');

  const { groupId, title: groupTitle } = groupConnector;

  reportPapEvent(
    PAP_Add_DashConnectedAccount({
      dashConnectorId: groupId,
      eventState: 'start',
      ...options.eventProps,
    }),
  );

  try {
    const error = validateGroupConnector(groupConnector);
    if (error) {
      logger.error(error);
      throw dashConnectedAccountError(
        'invalid_connector_properties',
        'Invalid connector properties',
      );
    }

    // Use all of the group's connectors, even if some are already connected, in case
    // the user is connecting a different account.
    const connectors = groupConnector.connectors;

    // Mark connectors as loading, get oauth URL, and open popup.
    connectors.forEach((c) => {
      const type = c.id_attrs?.type;
      if (type) setConnector(type, { ...c, loading: true });
    });

    // Open popup, fetch oauth URL, and load the URL in the popup.
    const popup = openPopup(`popup-${groupId}`);
    const janusUrl = await getOAuthUrlForGroupConnector(connectors);
    await loadAndMonitorPopup(popup, janusUrl);

    // Popup has closed; fetch updated connections and connectors.
    setConnections((atom) => ({ ...atom, loading: true }));
    setConnectors((atom) => ({ ...atom, loading: true }));

    // Fetch new connections and connectors.
    const [connectionsAfter, connectorsAfter] = await Promise.all([
      listConnections(),
      fetchConnectorsWithVisibilityFilters(),
    ]);

    // Check for a new connection belonging to the group.
    const newConnectionsForGroup = connectionsAfter
      .filter((newConnection) => {
        // Filter to connections in the group.
        return groupConnector.connectorTypes.includes(
          newConnection.id_attributes?.connector?.type as string,
        );
      })
      .filter((newConnection) => {
        if (connections.data.length === 0) return true;
        // Filter out existing connections.
        return connections.data.some((oldConnection) => {
          return (
            oldConnection.id_attributes?.id !== newConnection.id_attributes?.id
          );
        });
      });
    if (newConnectionsForGroup.length === 0) {
      throw dashConnectedAccountError(
        'janus_account_auth',
        i18n.t('error_detecting_new_connection', {
          appName: groupTitle,
        }),
      );
    }

    // Send connection enabled calls for each connection.
    // TODO: (only possible in Janus) use EventBus in the backend to handle this based on server side events and remove the need for this call.
    newConnectionsForGroup.map((connection) =>
      connectionEnabled(
        connection.id_attributes?.id,
        connection.id_attributes?.connector?.platform,
        connection.id_attributes?.connector?.type,
      ).catch((e) => {
        // If these calls fail the user will not immediately see data.
        // But it will backfill eventually, so just log the error.
        logger.error(e);
      }),
    );

    // Update atoms.
    setConnections({
      data: connectionsAfter
        .map((c) => {
          return { ...c, loading: false, syncing: false };
        })
        .filter(filterBrowserExtensionConnections),
      loaded: true,
      loading: false,
    });
    setConnectors({
      data: connectorsAfter.map((c) => ({
        ...c,
        loading: false,
        syncing: false,
      })),
      loaded: true,
      loading: false,
    });
  } catch (e) {
    logger.error(e);
    let reason;
    if ((e as DashConnectedAccountError).reason) {
      reason = (e as DashConnectedAccountError).reason;
    }
    reportPapEvent(
      PAP_Add_DashConnectedAccount({
        dashConnectorId: groupId,
        eventState: 'failed',
        dashConnectAccountFailureReason: reason,
        ...options.eventProps,
      }),
    );
    return {
      success: false,
      message: (e as Error).message,
    };
  } finally {
    setConnections((atom) => ({ ...atom, loading: false }));
    setConnectors((atom) => ({ ...atom, loading: false }));
    groupConnector.connectors.forEach((c) => {
      const type = c.id_attrs?.type;
      if (type) setConnector(type, { ...c, loading: false });
    });
  }

  reportPapEvent(
    PAP_Add_DashConnectedAccount({
      dashConnectorId: groupId,
      eventState: 'success',
      ...options.eventProps,
    }),
  );
  return {
    success: true,
    message: i18n.t('connectors_settings_connected_to_service', {
      appName: groupTitle,
    }),
  };
}

const getOAuthUrlForGroupConnector = async (connectors: UIConnector[]) => {
  const connectorIds = connectors.map((c) => c.id_attrs?.id) as string[];
  const response = await callApiV2('janusOauthInitiateOauthConnection', {
    grouped_connector_ids: connectorIds,
  });
  if (!response.oauth_url) throw new Error(i18n.t('error_getting_oauth_url'));
  return response.oauth_url;
};

type GroupConnectorValidationError = Error & {
  groupConnector?: GroupConnector;
  connector?: UIConnector;
};

const validateGroupConnector = (
  groupConnector: GroupConnector,
): GroupConnectorValidationError | undefined => {
  const { connectors, groupId } = groupConnector;
  if (!groupId) return new Error('Invalid group connector: missing groupId');
  if (!connectors)
    return new Error('Invalid group connector: missing connectors');

  for (const connector of connectors) {
    const type = connector.id_attrs?.type;
    const platform = connector.id_attrs?.platform?.['.tag'];
    const connectorName = connector.branding?.display_name;

    if (!type || platform !== 'janus' || !connectorName) {
      const e = new Error(
        'Invalid group connector properties: ' +
          [type, platform, connectorName].join(', '),
      ) as GroupConnectorValidationError;
      e.connector = connector;
      return e;
    }
  }
};
