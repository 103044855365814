// Hard-coding these into the client as they will infrequently change.
// Taken from server protos https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/server/-/blob/go/src/dropbox/dash/api/metadata/file_type/file_type.go?L18
// NOTE: Please update the PAP property when you add new values here
// NOTE: Please update local file os queries when adding new content types
// https://pap.pp.dropbox.com/#/event_property/1625

// import { I18nKey } from '@mirage/translations';
import i18n from '@mirage/translations';

export type ContentType = {
  readonly key: string;
  readonly label: () => string;
};

export type FilterableContentTypes = Readonly<ContentType[]>;

export const filterableContentTypes: FilterableContentTypes = [
  {
    key: 'folder',
    label: () => i18n.t('folder_content_type'),
  },
  {
    key: 'document',
    label: () => i18n.t('document_content_type'),
  },
  {
    key: 'presentation',
    label: () => i18n.t('presentation_content_type'),
  },
  {
    key: 'spreadsheet',
    label: () => i18n.t('spreadsheet_content_type'),
  },
  {
    key: 'pdf',
    label: () => i18n.t('pdf_content_type'),
  },
  {
    key: 'video',
    label: () => i18n.t('video_content_type'),
  },
  {
    key: 'image',
    label: () => i18n.t('image_content_type'),
  },
  {
    key: 'audio',
    label: () => i18n.t('audio_content_type'),
  },
  {
    key: 'email',
    label: () => i18n.t('email_content_type'),
  },
  {
    key: 'event',
    label: () => i18n.t('event_content_type'),
  },
  {
    key: 'chat',
    label: () => i18n.t('chat_content_type'),
  },
] as const;

/**
 * These are other possible content types that aren't currently used for
 * filtering, but could be used for other things like detecting which
 * documents to attempt to open locally
 */
export const otherContentTypes = [
  {
    key: 'google_sheet',
    label: 'Google Sheet',
  },
  {
    key: 'google_slide',
    label: 'Google Slide',
  },
  {
    key: 'google_doc',
    label: 'Google Doc',
  },
  {
    key: 'microsoft_word',
    label: 'Microsoft Word',
  },
  {
    key: 'microsoft_excel',
    label: 'Microsoft Excel',
  },
  {
    key: 'microsoft_powerpoint',
    label: 'Microsoft PowerPoint',
  },
  {
    key: 'text',
    label: 'Text',
  },
] as const;

export type PossibleContentTypes = (
  | typeof filterableContentTypes
  | typeof otherContentTypes
)[number]['key'];
