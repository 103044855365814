import { useInitUxa } from '@mirage/analytics/uxa/useInitUxa';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import {
  cancelRefreshConnectionsJob,
  startRefreshConnectionsJob,
} from '@mirage/service-connectors';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import {
  cancelSyncFeatureMemCache,
  startSyncFeatureMemCache,
} from '@mirage/service-experimentation';
import {
  cancelReportSprigAttributesJob,
  startReportSprigAttributesJob,
} from '@mirage/service-feedback';
import { logPageLoadMilestone } from '@mirage/service-operational-metrics/page-load';
import {
  cancelSyncRecentEntities,
  startSyncRecentEntities,
} from '@mirage/service-recent-content';
import {
  cancelUpdateStackAdminSettings,
  startUpdateStackAdminSettings,
} from '@mirage/service-stack-admin-settings';
import { previewStack } from '@mirage/service-stacks';
import {
  cancelSyncRecommendations,
  cancelSyncSuggestedQueryScore,
  cancelSyncWeights,
  startSyncRecommendations,
  startSyncSuggestedQueryScore,
  startSyncWeights,
} from '@mirage/service-typeahead-search';
import { useInitDetectMouseActivity } from '@mirage/shared/hooks/useInitDetectMouseActivity';
import Sentry from '@mirage/shared/sentry';
import { getRoutePathname } from '@mirage/shared/util/route-pathname';
import {
  isProdBuildChannel,
  isStagingBuildChannel,
} from '@mirage/shared/util/tiny-utils';
import { useInitStacksData } from '@mirage/stacks/hooks';
import { useInitPublishedContentsData } from '@mirage/stacks/useInitPublishContentsData';
import { usePrivacyModule } from '@mirage/webapp/hooks/usePrivacyModule';
import { useTtdcReporter } from '@mirage/webapp/performance/TtdcReporter';
import { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';
import {
  cancelReportMemoryUsageJob,
  startReportMemoryUsageJob,
} from '../performance/reportMemoryUsage';
import { RoutePath } from '../routeTypes';
import { useInitAvatarMenu } from './useInitAvatarMenu';

/** Init data needed for the initial render. */
export function useInitPriorityAuthedJobs() {
  // Init stack preview.
  useEffect(() => {
    const pathname = getRoutePathname();
    const match = matchPath(RoutePath.STACKS + '/:shareId', pathname);
    if (match) {
      const shareId = match.params['shareId'] ?? '';
      if (!shareId) {
        Sentry.captureMessage(
          `[useInitPriorityAuthedJobs] Stack preview attempted with empty shareId`,
        );
      }
      logPageLoadMilestone(`Early previewStack ${shareId}`);
      previewStack(shareId);
    }
  }, []);

  useEffect(() => {
    const startTime = Date.now();
    return () => {
      const duration = Date.now() - startTime;
      if (duration > 5000) {
        Sentry.captureMessage(
          `[useInitPriorityAuthedJobs] Slow job initialization: ${duration}ms`,
        );
      }
    };
  }, []);

  useInitAvatarMenu();

  useInitPublishedContentsData();
  useInitStacksData();
  useConnectors();

  // The Recent Content module will not render without this data.
  useEffect(() => {
    startSyncRecentEntities();
    startSyncWeights();
    startSyncSuggestedQueryScore();

    return () => {
      cancelSyncRecentEntities();
      cancelSyncWeights();
      cancelSyncSuggestedQueryScore();
    };
  }, []);
}

export function useInitUxaPublisher() {
  const account = useDropboxAccount();
  const shouldEnableUxa =
    isProdBuildChannel(EnvCtx.buildChannel) ||
    isStagingBuildChannel(EnvCtx.buildChannel);

  useInitUxa({ enable: shouldEnableUxa, account });
}

export function useInitAuthedJobs(routePattern: string, devbox?: string) {
  const [startDelayedJobs, setStartDelayedJobs] = useState(false);

  useTtdcReporter(routePattern);

  useInitDetectMouseActivity();

  usePrivacyModule(devbox);

  // Delay a bit before starting sync jobs. The sync jobs compete with more
  // urgent tasks for CPU, IPC, and indexed db resources, so if we delay a
  // bit here, it will likely make the page load a bit faster.
  useEffect(() => {
    let canceled = false;

    const timer = setTimeout(() => {
      if (canceled) return;
      clearInterval(timer);
      setStartDelayedJobs(true);
    }, 6000);

    return () => {
      canceled = true;
      clearTimeout(timer);
    };
  }, []);

  // Register interval-based syncing of various content.
  useEffect(() => {
    if (!startDelayedJobs) return;

    startSyncRecommendations();
    startSyncFeatureMemCache(['dash_2024_07_29_link_enrichment']);
    startUpdateStackAdminSettings();
    startRefreshConnectionsJob();
    startReportMemoryUsageJob();
    startReportSprigAttributesJob();

    return () => {
      cancelSyncRecommendations();
      cancelSyncFeatureMemCache();
      cancelUpdateStackAdminSettings();
      cancelRefreshConnectionsJob();
      cancelReportMemoryUsageJob();
      cancelReportSprigAttributesJob();
    };
  }, [startDelayedJobs]);
}
