import { Avatar } from '@dropbox/dig-components/avatar';
import { UIIcon } from '@dropbox/dig-icons';
import { ClockLine, FileLine, FilterLine } from '@dropbox/dig-icons/assets';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import { getInitialsFromPerson } from '@mirage/shared/account';
import {
  type SearchFilter,
  SearchFilterType,
} from '@mirage/shared/search/search-filters';
import React, { useMemo } from 'react';
import { BaseIcon, getIconFromIconResource } from '.';

type Props = {
  filter: SearchFilter;
  size?: number;
};

export function SearchFilterIcon({ filter, size = 24 }: Props) {
  const isDarkMode = useTheme();

  return useMemo(() => {
    const DefaultIcon = <UIIcon src={FilterLine} />;

    switch (filter.type) {
      case SearchFilterType.Connector: {
        const { displayName, iconLightSrc, iconDarkSrc } = filter.parameters;
        const Icon = getIconFromIconResource(
          {
            lightUrl: iconLightSrc,
            darkUrl: iconDarkSrc ? iconDarkSrc : iconLightSrc,
          },
          displayName,
          isDarkMode,
        );
        return Icon ? <BaseIcon size={size} Icon={Icon} /> : DefaultIcon;
      }
      case SearchFilterType.ContentType:
        return <UIIcon src={FileLine} />;
      case SearchFilterType.Person:
        return (
          <Avatar
            size="small"
            src={filter?.parameters.profilePhotoUrl}
            alt={filter.parameters.displayName}
            hasNoOutline
          >
            {getInitialsFromPerson({
              displayName: filter.parameters.displayName,
              email: filter.parameters.email || '',
            })}
          </Avatar>
        );
      case SearchFilterType.LastUpdated:
        return <UIIcon src={ClockLine} />;
      default:
        return DefaultIcon;
    }
  }, [filter, isDarkMode, size]);
}
