import { Badge } from '@dropbox/dig-components/badge';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AiChatLine,
  EditLine,
  HelpLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useIsMobileSizeForSidebar } from '@mirage/shared/responsive/mobile';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import styles from './EmptyChatInstructions.module.css';

export type ClickableEmptyChatInstructionsOption =
  | 'write'
  | 'request_capabilities'
  | 'chat_with_dash';
type EmptyChatInstructionsOption =
  | 'write'
  | 'request_capabilities'
  | 'chat_with_dash';

interface EmptyChatInstructionsProps {
  chatWithDashEnabled: boolean;
  onClickOption: (option: ClickableEmptyChatInstructionsOption) => void;
}
export const EmptyChatInstructions = memo(
  ({ chatWithDashEnabled, onClickOption }: EmptyChatInstructionsProps) => {
    const isMobileSize = useIsMobileSizeForSidebar();
    return (
      <div
        className={classNames(styles.EmptyChatInstructions, {
          [styles.Mobile]: isMobileSize,
        })}
      >
        <div
          className={classNames(styles.TitleContainer, {
            [styles.TitleContainerMobile]: isMobileSize,
          })}
        >
          <Title
            size="medium"
            weightVariant="emphasized"
            className={styles.Title}
          >
            {i18n.t('assistant_empty_chat_title')}
          </Title>
        </div>
        <div
          className={classNames(styles.EmptyChatInstructionsCards, {
            [styles.MobileCards]: isMobileSize,
          })}
        >
          {getCardOptions(chatWithDashEnabled).map((option) => (
            <EmptyChatInstructionCard
              key={option}
              option={option}
              isComingSoon={!chatWithDashEnabled && option === 'chat_with_dash'}
              onClickOption={onClickOption}
              isMobileSize={isMobileSize}
            />
          ))}
        </div>
      </div>
    );
  },
);
EmptyChatInstructions.displayName = 'EmptyChatInstructions';

interface EmptyChatInstructionCardProps {
  option: EmptyChatInstructionsOption;
  isComingSoon?: boolean;
  onClickOption: (option: ClickableEmptyChatInstructionsOption) => void;
  isMobileSize: boolean; // New prop
}
export const EmptyChatInstructionCard = memo(
  ({
    option,
    isComingSoon,
    onClickOption,
    isMobileSize,
  }: EmptyChatInstructionCardProps) => {
    const {
      title,
      description,
      icon,
      color,
      borderVariant: defaultBorderVariant,
    } = getCardRenderInfo(option);
    const handleClick = useCallback(() => {
      if (isComingSoon) {
        // no-op if disabled
        return;
      }
      onClickOption(option);
    }, [isComingSoon, onClickOption, option]);
    const borderVariant = isComingSoon ? 'coming_soon' : defaultBorderVariant;
    return (
      <div
        className={classNames(styles.EmptyChatInstructionCard, {
          [styles.BorderVariantNormal]: borderVariant === 'normal',
          [styles.BorderVariantDotted]: borderVariant === 'dotted',
          [styles.BorderVariantComingSoon]: borderVariant === 'coming_soon',
          [styles.Clickable]: borderVariant !== 'coming_soon',
          [styles.MobileCard]: isMobileSize,
        })}
        onClick={handleClick}
        onKeyDown={onKeyDownCommitFn(handleClick)}
        tabIndex={0}
        role="button"
      >
        {borderVariant === 'coming_soon' && (
          <Badge size="xsmall" over="base" className={styles.ComingSoonBadge}>
            {i18n.t('coming_soon_label')}
          </Badge>
        )}
        <UIIcon
          className={classNames({
            [styles.MobileCardIcon]: isMobileSize,
          })}
          src={icon}
          size="large"
          color={color}
        />
        <Title
          size="small"
          weightVariant="emphasized"
          className={styles.CardTitle}
          tagName="div"
        >
          {title}
        </Title>
        <Text
          size="small"
          color="subtle"
          className={classNames(styles.CardDescription, {
            [styles.MobileCardDescription]: isMobileSize,
          })}
          tagName="div"
        >
          {description}
        </Text>
      </div>
    );
  },
);
EmptyChatInstructionCard.displayName = 'EmptyChatInstructionCard';

function getCardOptions(
  chatWithDashEnabled: boolean,
): EmptyChatInstructionsOption[] {
  if (chatWithDashEnabled) {
    return ['write', 'chat_with_dash', 'request_capabilities'];
  } else {
    return ['write', 'request_capabilities', 'chat_with_dash'];
  }
}

function getCardRenderInfo(option: EmptyChatInstructionsOption) {
  switch (option) {
    case 'chat_with_dash':
      return {
        title: i18n.t('assistant_card_title_chat_with_dash'),
        description: i18n.t('assistant_card_description_chat_with_dash'),
        icon: AiChatLine,
        color: 'var(--dig-color__accent__cyan)',
        borderVariant: 'normal',
      };
    case 'request_capabilities':
      return {
        title: i18n.t('assistant_card_title_request_capabilities'),
        description: i18n.t('assistant_card_description_request_capabilities'),
        icon: HelpLine,
        color: 'var(--dig-color__accent__purple)',
        borderVariant: 'dotted',
      };
    case 'write':
      return {
        title: i18n.t('assistant_card_title_write'),
        description: i18n.t('assistant_card_description_write'),
        icon: EditLine,
        color: 'var(--dig-color__accent__red)',
        borderVariant: 'normal',
      };
    default:
      option satisfies never;
      throw new Error(`Unknown option: ${option}`);
  }
}
