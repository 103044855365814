// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1996
import type { PAPEventGeneric } from "../base/event";
import type { EndReason } from "../enums/end_reason";
import type { DashSurface } from "../enums/dash_surface";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { FeatureLine } from "../enums/feature_line";
import type { DashSurfaceBuild } from "../enums/dash_surface_build";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { SessionStartReason } from "../enums/session_start_reason";
import type { DashAnnotationMode } from "../enums/dash_annotation_mode";
import type { DashSearchInitiator } from "../enums/dash_search_initiator";

// Event_name: 'finish.search_session'
// Description: A period of time where the user was using the search functionality. The period ends after 60 seconds of not using search functionality.
// Owner: otc
export type Finish_SearchSession = PAPEventGeneric<
  'dash',
  'finish',
  'search_session',
  {
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // the end time of the event in ms
    endTimeMs?: number;
    // The reason the session ended
    endReason?: EndReason;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // indicating if the search result page is opened during a search session
    hasSerpOpened?: boolean;
    // Set to true when the search field has a value upon search
    hasQuery?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The reason why a session was started
    sessionStartReason?: SessionStartReason;
    // The annotation mode (if user is annotating).
    dashAnnotationMode?: DashAnnotationMode;
    // The initiator of a dash search (chrome omnibox, dash typeahead, etc)
    dashSearchInitiator?: DashSearchInitiator;
  }
>;

export function PAP_Finish_SearchSession(properties?: Finish_SearchSession['properties']): Finish_SearchSession {
  return <Finish_SearchSession>{
    class: 'dash',
    action: 'finish',
    object: 'search_session',
    properties,
  };
}